import './style.scss'
import { PictureList } from 'components/front/Wedding/Picture/List/PictureList'
import { useQuery } from '@apollo/client'
import pictureListGql from 'graphql/WEDDING/Picture/query/pictureList.gql'
import { Cursor, Picture } from 'model'
import { GuestWrapper } from 'components/front/Wedding/Guest/Wrapper/GuestWrapper'
import { Modal } from "@zipou/front_tools"

import { useState } from 'react'
import { PictureForm } from 'components/front/Wedding/Picture/Form/PictureForm'
import { Galery } from 'components/front/Wedding/Galery/Galery'
import { Route, useNavigate } from 'react-router-dom'
import { URL_WEDDING, URL_WEDDING_GALERY } from 'static/constant/front/url'
import { Footer } from 'components/front/Wedding/Footer/Footer'
import { Header } from 'components/front/Wedding/Header/Header'

export const WeddingMainRoute = (props: any) => {

  const navigate = useNavigate()
  const [focusImport, updateFocusImport] = useState<boolean>(false)
  const [focusGalery, updateFocusGalery] = useState<boolean>(false)

  const page = props?.match?.params?.page || 1
  const limit = 10

  const { data, refetch } = useQuery<{ list: { nodes: Picture[], cursor: Cursor, input: any } }>(pictureListGql, {
    variables: {
      input: {
        category: null,
      },
      cursor: {
        page: Number(page),
        limit,
      }
    }
  })

  const onPictureAdded = () => {
    updateFocusImport(false)
    refetch({
      input: data?.list?.input,
      cursor: {
        page: data?.list?.cursor?.page,
        limit: data?.list?.cursor?.limit,
      }
    })
  }


  return <div className="wedding-main-route-container container">
    <img src={"https://wedding-cam-raf.s3.fr-par.scw.cloud/pictures/logo.png"} className='img-fluid' alt="logo" />
    <GuestWrapper>
      <Header />
      <Modal display={focusImport} onClose={() => updateFocusImport(false)} title='Importer une photo'>
        <PictureForm page={page} onDone={() => onPictureAdded()} />
      </Modal>
      <Route path={URL_WEDDING_GALERY}>
        <Modal display={true} onClose={() => navigate(URL_WEDDING)} title='Galerie'>
          <Galery />
        </Modal>
      </Route>
      <div className='row'>
        <div className='col-12 col-sm-6'>
          <button className='p-2' style={{ backgroundColor: "white", border: "none" }} onClick={() => updateFocusImport(true)}>
            <img src={"https://wedding-cam-raf.s3.fr-par.scw.cloud/pictures/import.png"} width={190} className='' alt="logo" />
          </button>
        </div>
        <div className='col-12 col-sm-6'>
          <button className='p-2' style={{ backgroundColor: "white", border: "none" }} onClick={() => navigate(URL_WEDDING_GALERY)}>
            <img src={"https://wedding-cam-raf.s3.fr-par.scw.cloud/pictures/galerie.png"} width={180} className='' alt="logo" />
          </button>
        </div>

      </div>
      <h2 className='mt-2'>Les dernières photos: </h2>
      <PictureList page={page} cursor={data?.list?.cursor} pictureList={data?.list?.nodes} />

      <div className='thanks d-flex align-items-center justify-content-center mt-3'>
        <div style={{ textAlign: 'center' }} className='p-2'>
          <span style={{ fontWeight: 400 }}>Merci de votre collaboration !</span><br />
          Le meilleur photographe du défi photo remportera un joli cadeau.<br />
          Résultats du vote le <span style={{ fontWeight: 400 }}>20 juin</span> !<br />
        </div>
      </div>
      <Footer />
    </GuestWrapper>

  </div>

}
import {gql} from '@apollo/client'
import PaymentPrefFragment from '../../PaymentPref/fragment/PaymentPrefFragment'

export default gql`

  ${PaymentPrefFragment}

  fragment OrderPublicFragment on Order {
    id
    publicId
    dateStart
    billingDayOfMonth
    status
    PaymentPref {
      ...PaymentPrefFragment
    }
    Contract {
      id
      Customer {
        id
        Address {
          name
          address1
          address2
          zip
          town
        }
        name
        Contact {
          id
          firstname
          lastname
          email
          type
        }
      }
    }
    OrderLine {
      id
      quantity
      dateStart
      dateEnd
      Item {
        id
        label
        price
        vat
      }
    }
  }
`
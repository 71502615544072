import { gql } from "@apollo/client"
import OrderSearchFragment from "../fragment/OrderSearchFragment"

export default gql`

  ${OrderSearchFragment}

  mutation orderDelete($id: String!) {
    orderDelete(id: $id) {
      ...OrderSearchFragment
    }
  }

`
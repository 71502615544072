import {CompanyForm} from "components/backoffice/BILLING/Company/Form/CompanyForm"

type ProfileFormProps = {
  user: any,
  onChange: (data: any) => void,
}

export const ProfileForm = (props: ProfileFormProps) => {

  const user = props?.user
  const isBilling = user?.isBilling

  const onChange = (data: any) => {
    props?.onChange(data)
  }

  return <div>
    {isBilling && <div>
      <CompanyForm errors={{}} company={user?.Company} onChange={(Company: any) => {
        onChange({
          ...user,
          Company,
        })
      }} />
      </div>}
  </div>
}

import { useQuery } from "@apollo/client"
import hostingConfigListGql from "graphql/HOSTING/Config/query/hostingConfigList.gql"
import phoneConfigListAllGql from "graphql/HOSTING/Phone/Config/query/phoneConfigListAll.gql"
import { HostingConfig, PhoneConfig, UserInput, UserRoleEnum } from "model"

type UserFormProps = {
  user: UserInput,
  onChange: (user: UserInput) => void,
}

export const UserForm = ({ user, onChange }: UserFormProps) => {

  const { data } = useQuery<{ list: HostingConfig[] }>(hostingConfigListGql)
  const phoneConfigResponse = useQuery<{ list: PhoneConfig[] }>(phoneConfigListAllGql)

  // console.log({user})

  const hostingConfigList = data?.list || []
  const phoneConfigList = phoneConfigResponse?.data?.list || []

  return <div className="user-details-container">
    <div className="input-group">
      <span className="input-group-text">Email</span>
      <input className="form-control" type="text" value={user?.email || ""} placeholder="Email" onChange={(e) => {
        onChange({
          ...user,
          email: e.target.value,
        })
      }} />
    </div>
    <div className="input-group">
      <span className="input-group-text">Role</span>
      <div className="form-control" style={{ height: "100%" }}>
        {Object.values(UserRoleEnum).map((role) => {
          const key = `role_${role}`
          return <div key={`${key}`}>
            <input type="checkbox" checked={user?.roles?.includes(role)} id={key} onChange={(e: any) => {
              const checked = e.target.checked
              onChange({
                ...user,
                roles: checked ? [...(user?.roles ? user?.roles : []), role] : user?.roles?.filter((el: string) => el !== role)
              })
            }} />
            <label className="" htmlFor={key}>{role}</label>
          </div>
        })}
      </div>
    </div>
    <div className="input-group">
      <span className="input-group-text">PhoneConfig</span>
      <div className="form-control" style={{ height: "100%" }}>
        {phoneConfigList.map((phoneConfig) => {
          const key = `phoneConfig_${phoneConfig?.id}`
          return <div key={`${key}`}>
            <input type="checkbox" checked={user?.phoneConfigIds?.includes(phoneConfig?.id)} id={key} onChange={(e: any) => {
              const checked = e.target.checked
              onChange({
                ...user,
                phoneConfigIds: checked ? [...(user?.phoneConfigIds ? user?.phoneConfigIds : []), phoneConfig?.id] : user?.roles?.filter((el: string) => el !== phoneConfig?.id)
              })
            }} />
            <label className="ml-1" htmlFor={key}>{phoneConfig?.name || phoneConfig?.id}</label>
          </div>
        })}
      </div>
    </div>
    <div className="input-group">
      <span className="input-group-text">HostingConfig</span>
      <div className="form-control" style={{ height: "100%" }}>
        {hostingConfigList.map((hostingConfig) => {
          const key = `hostingConfig_${hostingConfig?.id}`
          return <div key={`${key}`}>
            <input type="checkbox" checked={user?.hostingConfigIds?.includes(hostingConfig?.id)} id={key} onChange={(e: any) => {
              const checked = e.target.checked
              onChange({
                ...user,
                hostingConfigIds: checked ? [...(user?.hostingConfigIds ? user?.hostingConfigIds : []), hostingConfig?.id] : user?.roles?.filter((el: string) => el !== hostingConfig?.id)
              })
            }} />
            <label className="ml-1" htmlFor={key}>{hostingConfig?.name || hostingConfig?.id}</label>
          </div>
        })}
      </div>
    </div>
  </div>

}
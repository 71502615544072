import {gql} from "@apollo/client";
import CompanyFragmentGql from "graphql/BILLING/Company/fragment/CompanyFragment.gql";

export default gql`

${CompanyFragmentGql}

fragment UserProfileFragment on User {
  id
  email
  roles
  impersonator
  isAdmin
  isManager
  isBilling
  isWebmaster
  isUser
  Company {
    ...CompanyFragment
  }
}
`
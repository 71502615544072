import { useMutation } from "@apollo/client"
import customerAddGql from "graphql/BILLING/Customer/mutation/customerAdd.gql"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import { useState } from "react"
import { CustomerForm } from "../Form/CustomerForm"

type CustomerAddProps = {
  onDone?: () => void,
}


export const CustomerAdd = (props: CustomerAddProps) => {

  const [customer, updateCustomer] = useState<any>({})
  const [errors, updateErrors] = useState(null)

  const [mutate] = useMutation(customerAddGql)

  const onSubmit = (e: any) => {

    const { id, __typename, ...input } = customer

    e.preventDefault()
    mutate({
      variables: {
        input,
      }
    })
      .then(() => {
        props?.onDone && props?.onDone()
      })
      .catch((e: any) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  return <div>
    <form onSubmit={onSubmit}>
      <CustomerForm errors={errors} customer={customer} onChange={(customer: any) => updateCustomer(customer)} />
      <div>
        <button className="btn btn-sm btn-dark">Enregistrer</button>
      </div>
    </form>
  </div>

}
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { useQuery } from "@apollo/client"
import { TagDisplay } from "../Display/TagDisplay"
import tagListRootGql from "graphql/BILLING/Tag/query/tagListRoot.gql"
import { Tag } from "model"
import { useState } from 'react'
import { TagAdd } from '../Add/TagAdd'
import { Modal } from '@zipou/front_tools'

type TagListProps = {

}

export const TagList = (props: TagListProps) => {

  const [focusAdd, updateFocusAdd] = useState<boolean>()

  const { data, refetch } = useQuery<{ list: Tag[] }>(tagListRootGql)
  const tagList = data?.list

  return <>
    <Modal display={!!focusAdd} title='Ajouter un tag' onClose={() => updateFocusAdd(false)}>
      <TagAdd onDone={() => {
        updateFocusAdd(false);
        refetch();
      }} />
    </Modal>

    <div className="card">
      <div className="card-header">
        Tag
      </div>
      <div className="card-body">

        <DndProvider backend={HTML5Backend}>
          {tagList?.map(t => {
            return <TagDisplay key={t.id} id={t.id} />
          })}
        </DndProvider>
      </div>
      <div className="card-footer">
        <button className="btn btn-dark mr-1 btn-sm" onClick={() => updateFocusAdd(true)}>Ajouter</button>
      </div>
    </div>

  </>

}
import { gql } from '@apollo/client'
import ItemFragment from '../fragment/ItemFragment'


export default gql`

  ${ItemFragment}

  mutation itemUpdate($id: ID!, $input: ItemInput!) {
    itemUpdate(id: $id, input: $input) {
      ...ItemFragment
    }
  }
`
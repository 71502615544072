import ServerFragment from "../fragment/ServerFragment";
import gql from "graphql-tag";

export default gql`

  ${ServerFragment}

  query serverGet($id: String!) {
    server: serverGet(id: $id) {
      ...ServerFragment
    }
  }
`
import { CameraListModule } from "@zipou/video_front"

type CameraListModuleDisplayProps = {
  videoConfigId: string,
  token: string,
}

export const CameraListModuleDisplay = ({ videoConfigId, token }: CameraListModuleDisplayProps) => {

  return <div>
    <CameraListModule videoConfigId={videoConfigId} token={token} moduleConfig={{
      url: "http://localhost:4001"
    }} />
  </div>

}
import React from "react"
import {useMutation, useQuery} from "@apollo/client"

import userProfileQuery from "graphql/APP/User/query/userProfile"
import userListQuery from "graphql/APP/User/query/userList"
import userImpersonateMutation from 'graphql/APP/User/mutation/userImpersonate'

export const Impersonation = (props) => {

  const {onDone} = props

  const {data: {userList} = {}} = useQuery(userListQuery)

  const [impersonate] = useMutation(userImpersonateMutation, {
    refetchQueries: [
      {
        query: userProfileQuery,
      }
    ]
  })

  const startImpersonation = (userId) => {
    return impersonate({
      variables: {
        id: userId,
      }
    })
    .then(() => {
      onDone && onDone()
    })
  }

  return <div style={{display: "flex", flexWrap: "wrap"}}>
    {userList && userList.map(user => {
      return user && <div style={{padding: 10}}><button className="btn btn-sm btn-dark" onClick={() => startImpersonation(user.id)}>{user.email}</button></div>
    })}
  </div>
}
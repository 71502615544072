import React from "react"
import PropTypes from "prop-types"

export const PaymentStatus = props => {
  switch (props.status) {
    case "ACTION_NEEDED": {
      return <h2><span className="badge badge-warning">{"En attente d'une action"}</span></h2>
    }
    case "PENDING": {
      return <h2><span className="badge badge-secondary">En cours</span></h2>
    }
    case "ABORTED": {
      return <h2><span className="badge badge-dark">Annulé</span></h2>
    }
    case "ERROR": {
      return <h2><span className="badge badge-danger">Erreur</span></h2>
    }
    case "DONE": {
      return <h2><span className="badge badge-success">OK</span></h2>
    }
    default: {
      return <h2><span className="badge badge-dark">{props.status}</span></h2>
    }
  }
}

PaymentStatus.propTypes = {
  status: PropTypes.string,
}


import {TextInput} from "@zipou/front_tools"

export type RibFormProps = {
  rib: any,
  errors: any,
  onChange: (data: any) => void,
}

export const RibForm = (props: RibFormProps) => {

  const {rib, errors} = props

  const onChange = (data: any) => {
    props?.onChange(data)
  }

  return <div>
    <TextInput label="Iban" errors={errors} value={rib?.iban} id="iban" onChange={(v: string) => onChange({
      ...rib,
      iban: v,
    })} />
    <TextInput label="Bic" errors={errors} value={rib?.bic} id="bix" onChange={(v: string) => onChange({
      ...rib,
      bic: v,
    })} />

  </div>

}
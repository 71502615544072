import {gql} from '@apollo/client'
import InvoiceLineFragment from '../../InvoiceLine/fragment/InvoiceLineFragment'
import PaymentFragment from '../../Payment/fragment/PaymentFragment'

export default gql`

  ${InvoiceLineFragment}
  ${PaymentFragment}

  fragment InvoiceFragment on Invoice {
    id
    publicId
    status
    date
    totalTTC
    totalHT
    contactId
    contractId
    Contact {
      id
      lastname
    }
    Contract {
      id
      Customer {
        id
        name
      }
    }
    InvoiceLine {
      ...InvoiceLineFragment
    }
    Payment {
      ...PaymentFragment
    }
  }
`
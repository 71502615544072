import { VideoConfigList } from "components/backoffice/VIDEO/Config/List/VideoConfigList"
import { Breadcrumb as BootstrapBreadcrumb } from "react-bootstrap"


export const VideoConfigListRoute = () => {

  return <div className="video-config-list-route">


    <BootstrapBreadcrumb>
      <BootstrapBreadcrumb.Item active>Video</BootstrapBreadcrumb.Item>
      <BootstrapBreadcrumb.Item active>Configurations</BootstrapBreadcrumb.Item>
    </BootstrapBreadcrumb>


    <VideoConfigList />
  </div>
}
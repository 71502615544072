import {gql} from '@apollo/client'
import OrderPublicFragment from '../fragment/OrderPublicFragment'

export default gql`

  ${OrderPublicFragment}

  mutation orderUpdatePaymentPref($publicId: String!, $id: ID!, $type: PaymentPrefTypeEnum! ) {
    orderUpdatePaymentPref(publicId: $publicId, input: {
      id: $id
      type: $type
    }) {
      ...OrderPublicFragment
    }
  }

`
import {gql} from "@apollo/client";

export default gql`

fragment ContactEditFragment on Contact {
  id
  title
  type
  lastname
  firstname
  email
}
`
import { TextInput } from "@zipou/front_tools"
import { HostingConfigInput } from "model"

type HostingConfigFormProps = {
  hostingConfigInput?: HostingConfigInput,
  onChange: (hostingConfigInput: HostingConfigInput) => void,
  errors: any,
}

export const HostingConfigForm = ({ hostingConfigInput, onChange, errors }: HostingConfigFormProps) => {

  return <>
    <TextInput id="name" errors={errors} label="Nom" value={hostingConfigInput?.name || ""} onChange={(v) => {
      onChange({
        ...hostingConfigInput,
        name: v
      })
    }} />
  </>

}
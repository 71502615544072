import { useQuery } from "@apollo/client"
import serverGet from "graphql/HOSTING/Server/query/serverGet"
import { Server } from "model"

type ServerBadgeProps = {
  id: string
}


export const ServerBadge = ({ id }: ServerBadgeProps) => {

  const { data } = useQuery<{ server: Server }>(serverGet, {
    variables: {
      id
    },
    skip: !id,
  })

  return <span className="badge badge-dark">{data?.server?.name}</span>

}
import gql from "graphql-tag";
import ServerFragment from "../fragment/ServerFragment";

export default gql`

  ${ServerFragment}

  mutation serverUpdate($id: String!, $input: ServerInput!){
    server: serverUpdate(id: $id, input: $input) {
      ...ServerFragment
    }
  }

`
import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
// import {Route} from "react-router-dom"

// import Card from "react-bootstrap/Card"
import CardColumns from "react-bootstrap/CardColumns"
import { Modal } from "@zipou/front_tools"


import { SearchEngine } from "components/backoffice/BILLING/SearchEngine"
import { OrderEdit } from "components/backoffice/BILLING/Order/Edit/OrderEdit"
import { ItemEdit } from "components/backoffice/BILLING/Item/Edit/ItemEdit"
import { InvoiceEdit } from "components/backoffice/BILLING/Invoice/Edit/InvoiceEdit"
import { InvoiceListPending } from 'components/backoffice/BILLING/Invoice/List/InvoiceListPending'
import { InvoiceListDraft } from 'components/backoffice/BILLING/Invoice/List/InvoiceListDraft'
import { InvoiceListError } from 'components/backoffice/BILLING/Invoice/List/InvoiceListError'
import { BillingOperationLast } from "components/backoffice/BILLING/BillingOperation/BillingOperationLast";


import { URL_ITEM_ADD, URL_INVOICE_ADD, URL_ORDER_ADD, URL_BILLING_ROOT } from "static/constant/backoffice/url"

import "./style.scss";

export const BillingRoute = (props: any) => {

  const navigateHook = useNavigate()

  const navigate = (target: string) => {
    navigateHook(`/manager${target}`)
  }


  // const url = `${process.env.REACT_APP_API_ENDPOINT}/billing/export/invoice/list/2020?access_token=${findToken()}`

  const { match } = props
  return (
    <div>
      {/* <Breadcrumb match={match} /> */}
      <Routes>
        <Route path={URL_ITEM_ADD} element={
          <Modal display={true} title="Ajouter un item" onClose={() => navigate(URL_BILLING_ROOT)}>
            <ItemEdit
              onDone={() => navigate(URL_BILLING_ROOT)}
            />
          </Modal>}>
        </Route>
        <Route path={URL_INVOICE_ADD} element={
          <Modal display={true} title="Ajouter une facture" onClose={() => navigate(URL_BILLING_ROOT)}>
            <InvoiceEdit
              onDone={() => navigate(URL_BILLING_ROOT)}
            />
          </Modal>}>
        </Route>
        <Route path={URL_ORDER_ADD} element={
          <Modal display={true} title="Ajouter un bon de commande" onClose={() => navigate(URL_BILLING_ROOT)}>
            <OrderEdit
              onDone={() => navigate(URL_BILLING_ROOT)}
            />
          </Modal>}>
        </Route>
      </Routes>
      <div className="main-engine">
        <SearchEngine />
      </div>
      <CardColumns>
        <BillingOperationLast />
      </CardColumns>
      {/* <Row>
        <InvoiceListError />
        <InvoiceListPending />
        <InvoiceListDraft />
      </Row> */}
      {/* <Card title={"Actions"}>
        <a href={url} target="_blank" rel="noopener noreferrer" ><button>Export Bilan Csv</button></a>
      </Card> */}
    </div>
  )
}

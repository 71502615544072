import React, { useState } from "react"
// import {useQuery} from "@apollo/client"
// import {Link} from "react-router-dom"

// import {Modal} from "@zipou/front_tools"

// import {OrderEdit} from "components/backoffice/BILLING/Order/OrderEdit"

import "./style.less"
import { OrderEdit } from "../Edit/OrderEdit"
import { URL_ORDER_PDF } from "static/constant/backoffice/url"
import { findToken } from "helpers/User"
import { Vars } from "libs/Vars"
import orderListGql from "graphql/BILLING/Order/query/orderList.gql"
import { useQuery } from "@apollo/client"
import { Pagination } from "components/common/Navigation/Pagination/Pagination"
import { OrderAdd } from "../Add/OrderAdd"
import { Link, useNavigate } from "react-router-dom"
import { CustomerEdit } from "../../Customer/Edit/CustomerEdit"
import { IconEdit, IconSearch, Modal } from "@zipou/front_tools"
import { OrderCursorList, OrderStatusEnum } from "model"
import { OrderStatus } from "../Status/OrderStatus"

type OrderListProps = {
  // orderList: any[],
}

export const OrderList = (props: OrderListProps) => {

  const navigate = useNavigate()
  const [modal, updateModal] = useState<any>(null)

  const [filterInput, updateFilterInput] = useState<any>({
    orderField: "id",
    orderDirection: "DESC",
    status: ["STATUS_DRAFT", "STATUS_WAITING_VALIDATION", "STATUS_ACTIVE", "STATUS_DONE"],
    contractId: [],
  })


  const { data, refetch } = useQuery<{ list: OrderCursorList }>(orderListGql, {
    variables: {
      cursor: {
        page: 1,
        limit: 20
      },
      input: {
        ...filterInput,
      }
    }
  })
  const { cursor } = data?.list || {}
  const { __typename, totalPages, hasMore, ..._ } = cursor || {}



  const _renderModal = () => {
    if (modal) {
      const { type, data } = modal
      switch (type) {
        case "VIEW_PDF":
          return <Modal display={!!modal} onClose={() => updateModal(false)}>
            <object data={data} type="application/pdf" style={{ width: '100%', height: '90vh', border: 0 }}>
              <embed src={data} type="application/pdf" />
            </object>
          </Modal>

        case "ORDER_EDIT":
          return <Modal display={!!modal} title={"Modifier un bon de commande"} onClose={() => updateModal(false)}>
            <OrderEdit
              id={data.id}
              onDone={() => updateModal(false)}
            />
          </Modal>

        case "ORDER_ADD":
          return <Modal display={!!modal} title={"Ajouter un bon de commande"} onClose={() => updateModal(false)}>
            <OrderAdd
              onDone={() => updateModal(false)}
            />
          </Modal>

        case "CUSTOMER_EDIT":
          console.log('data', data)
          return <Modal display={!!modal} title={"Modifier un client"} onClose={() => updateModal(false)}>
            <CustomerEdit
              id={data.id}
              onDone={() => updateModal(false)}
            />
          </Modal>

        default: {
          return <div />
        }
      }
    }
  }



  const orderList = data?.list?.nodes
  const { api_enpoint } = Vars
  const url = (publicId: string) => URL_ORDER_PDF.replace(':apiRoot', api_enpoint).replace(":publicId", publicId).replace(":accessToken", findToken())

  return <div className="card">
    <div className="card-header">
      Bons de commandes
    </div>
    <div className="card-body">
      {_renderModal()}
      <table className='table table-bordered table-stripped'>
        <thead>
          <tr className="bg-dark text-white">
            <td>
              <button className="btn btn-sm btn-secondary" onClick={() => {
                updateFilterInput({
                  orderField: "id",
                  orderDirection: filterInput?.orderDirection === "ASC" ? "DESC" : 'ASC'
                })
              }}>
                {filterInput?.orderField === "id" && <span className={filterInput?.orderDirection === "DESC" ? "icon-arrow-up" : "icon-arrow-down"}></span>} Id
              </button>
            </td>
            <td>
              <button className="btn btn-sm btn-secondary" onClick={() => {
                updateFilterInput({
                  orderField: "billingDayOfMonth",
                  orderDirection: filterInput?.orderDirection === "ASC" ? "DESC" : 'ASC'
                })
              }}>
                {filterInput?.orderField === "billingDayOfMonth" && <span className={filterInput?.orderDirection === "DESC" ? "icon-arrow-up" : "icon-arrow-down"}></span>} Jour
              </button>
            </td>
            <td>Client</td>
            <td>Status</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {orderList && orderList.map((order) => {
            const { id, publicId, billingDayOfMonth, Contract } = order
            const viewUrl = url(order?.publicId)
            return (
              <tr key={id}>
                <td>{publicId}</td>
                <td>{billingDayOfMonth}</td>
                <td>
                  <button className="btn btn-sm btn-dark" onClick={() => updateModal({ type: "CUSTOMER_EDIT", data: Contract?.Customer })}>
                    {Contract?.Customer?.name}
                  </button>
                </td>
                <td>
                  {order?.status && <OrderStatus status={order?.status} />}
                </td>
                <td>
                  <button className="btn btn-dark btn-sm" type="button" onClick={() => updateModal({ type: "VIEW_PDF", data: viewUrl })}>
                    <IconSearch />
                  </button>
                  <button className="btn btn-warning btn-sm" style={{ marginLeft: 5 }} type="button" onClick={() => updateModal({ type: "ORDER_EDIT", data: order })}>
                    <IconEdit />
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
    <div>
      <Pagination cursor={data?.list?.cursor} onChange={(page: number, limit?: number) => {
        //@ts-ignore
        const { __typename, totalPages, hasMore, ...cursorRest } = cursor
        refetch({
          input: filterInput,
          cursor: {
            ...cursorRest,
            page,
            limit,
          }
        })
      }} />
      <button className="btn btn-dark" onClick={() => updateModal({ type: "ORDER_ADD" })}>Ajouter</button>
    </div>
  </div>

}
import { useMemo, useState } from "react"
import { TagForm } from "../Form/TagForm"
import { Tag, TagInput } from "model"
import { useMutation, useQuery } from "@apollo/client"
import tagGetGql from "graphql/BILLING/Tag/query/tagGet.gql"
import tagEditGql from "graphql/BILLING/Tag/mutation/tagEdit.gql"

type TagEditProps = {
  id: string,
  onDone?: () => void,
}

export const TagEdit = (props: TagEditProps) => {

  const [mutate] = useMutation(tagEditGql)

  const { data } = useQuery<{ tag: Tag }>(tagGetGql, {
    variables: {
      id: props?.id
    },
    skip: !props?.id,
    onCompleted: (response) => {
      const tag = response?.tag
      updateTagInput({
        name: tag?.name,
        color: tag?.color,
        parentId: tag?.parentId,
      })
    }
  })

  const onReset = () => {
    const tag = data?.tag
    updateTagInput({
      name: tag?.name,
      color: tag?.color,
      parentId: tag?.parentId,
    })
  }

  const onSave = () => {
    mutate({
      variables: {
        id: props?.id,
        input: tagInput,
      }
    })
      .then(() => {
        props?.onDone && props?.onDone()
      })
      .catch((e) => {
        console.log("E", e)
      })
  }

  const [tagInput, updateTagInput] = useState<TagInput>({})

  return <div>
    <TagForm tagInput={tagInput} onChange={(v) => updateTagInput(v)} />
    <div>
      <button className="btn btn-sm btn-dark mr-1" onClick={() => onSave()}>Enregister</button>
      <button className="btn btn-sm btn-danger mr-1" onClick={() => onReset()}>Reset</button>
    </div>
  </div>

}
import gql from "graphql-tag";

export default gql`

fragment ServerFragment on Server {
  id
  name
  ip
  type
  hostingConfigId
  backupTargetId
}`
import React from "react"
import {Link, Route} from "react-router-dom"

import {URL_ORDER_UPDATE_PAYMENT_BANK_ACCOUNT_ADD_TEMPLATE, URL_ORDER_UPDATE_PAYMENT_PREF_TEMPLATE, URL_ORDER_UPDATE_TEMPLATE, URL_ORDER_UPDATE_PAYMENT_CARD_ADD_TEMPLATE} from "static/constant/backoffice/url"

import {OrderUpdate} from "components/backoffice/BILLING/Order/Update/OrderUpdate"
import {PaymentPrefUpdate} from "components/backoffice/BILLING/PaymentPref/Update/PaymentPrefUpdate"
import { Modal } from "@zipou/front_tools"

import {BankAccountForm} from 'components/backoffice/BILLING/PaymentPref/BankAccount/BankAccountForm'
import {CardForm} from 'components/backoffice/BILLING/PaymentPref/Card/CardForm'
import {StripeContainer} from 'components/backoffice/BILLING/StripeContainer';
import {Breadcrumb} from "components/common/Navigation/Breadcrumb/Breadcrumb"


export const OrderUpdateRoute = (props) => {

  const {match, history} = props
  const {params, path} = match || {}
  const {id} = params
  return (
    <div className="order-edit-route">
      {/* <Breadcrumb match={match} /> */}
      <Route path={URL_ORDER_UPDATE_PAYMENT_PREF_TEMPLATE}>
        <Modal display={true} title={"Mode de Règlement"} centered={false} onClose={() => history.push(URL_ORDER_UPDATE_TEMPLATE.replace(":id", id))} footer={<div><Link to={URL_ORDER_UPDATE_PAYMENT_CARD_ADD_TEMPLATE.replace(":id", id)}><button className="btn btn-dark">Ajouter une CB</button></Link>&nbsp;<Link to={URL_ORDER_UPDATE_PAYMENT_BANK_ACCOUNT_ADD_TEMPLATE.replace(":id", id)}><button className="btn btn-dark">Ajouter un mandat SEPA</button></Link></div>} >

        </Modal>
      </Route>
      <Route path={URL_ORDER_UPDATE_PAYMENT_BANK_ACCOUNT_ADD_TEMPLATE}>
        <Modal display={true} title={"Ajouter un mandat SEPA"} centered={false} onClose={() => history.push(URL_ORDER_UPDATE_PAYMENT_PREF_TEMPLATE.replace(":id", id))} >
          <StripeContainer>
            <BankAccountForm onDone={() => history.push(URL_ORDER_UPDATE_PAYMENT_PREF_TEMPLATE.replace(":id", id))} />
          </StripeContainer>
        </Modal>
      </Route>
      <Route path={URL_ORDER_UPDATE_PAYMENT_CARD_ADD_TEMPLATE}>
        <Modal display={true} title={"Ajouter une carte bancaire"} centered={false} onClose={() => history.push(URL_ORDER_UPDATE_PAYMENT_PREF_TEMPLATE.replace(":id", id))} >
          <StripeContainer>
            <CardForm onDone={() => history.push(URL_ORDER_UPDATE_PAYMENT_PREF_TEMPLATE.replace(":id", id))} />
          </StripeContainer>
        </Modal>
      </Route>
      <div className="">
        <OrderUpdate
          id={id}
        />
      </div>
    </div>
  )
}
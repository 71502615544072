import React from "react";

export const TransferItem = (props) => {
  const {publicId, totalTTC} = props
  return <table className="table table-bordered table-stripped  col-12">
  <tbody>
    <tr>
      <th>{`Référence SEPA`}</th>
      <td>{publicId}</td>
    </tr>
    <tr>
      <th>IBAN</th>
      <td>FR76 3000 3011 1200 0205 4897 714</td>
    </tr>
    <tr>
      <th>Code B.I.C</th>
      <td>SOGEFRPP</td>
    </tr>
    <tr>
      <th>Total Mensuel</th>
      <td>{totalTTC} €</td>
    </tr>
  </tbody>
</table>
}
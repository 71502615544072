import { useMutation, useSubscription } from "@apollo/client"
import { IconLoading, IconPlay } from "@zipou/front_tools"
import clusterProvisioningTaskRunGql from "graphql/HOSTING/Cluster/ProvisioningTask/mutation/clusterProvisioningTaskRun.gql"
import clusterProvisioningTaskUpdateGql from "graphql/HOSTING/Cluster/ProvisioningTask/subscription/clusterProvisioningTaskUpdate.gql"
import { useState } from "react"

type ProvisioningTaskStartProps = {
  task: any,
}

export const ProvisioningTaskStart = (props: ProvisioningTaskStartProps) => {

  // const [isRunning, updateIsRunning] = useState(false)

  const [taskStartMutate] = useMutation(clusterProvisioningTaskRunGql)

  useSubscription(clusterProvisioningTaskUpdateGql, {
    variables: {
      id: props?.task?.id
    },
    skip: !props?.task?.id,
    onSubscriptionData: (data: any) => {
      console.log("DATA")
    }
  })

  const onTaskStart = (id: string) => {
    // updateIsRunning(true)
    taskStartMutate({
      variables: {
        id,
      }
    })
      .then(() => {
        // updateIsRunning(false)
      })
      .catch((e: any) => {
        console.log("e", e.message)
      })
  }

  const task = props?.task
  const isStartable = task.status === "TODO" || task.status === "ERROR"
  const isRunning = task.status === "RUNNING"

  return <span>
    {isStartable && <button disabled={isRunning} className="btn btn-sm btn-success" onClick={() => onTaskStart(task.id)}>
      {isRunning && <IconLoading />}
      <IconPlay />
    </button>}
  </span>
}
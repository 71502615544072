import { useQuery } from "@apollo/client"
import { AutoCompleteInput } from "@zipou/front_tools"
import itemGet from "graphql/BILLING/Item/query/itemGet"
import itemSearchGql from "graphql/BILLING/Item/query/itemSearch.gql"
import { Item } from "model"

type ItemAutoCompleteInputProps = {
  isError: boolean,
  value: string,
  onChange: (id: string) => void,
}

export const ItemAutoCompleteInput = (props: ItemAutoCompleteInputProps) => {


  const { data } = useQuery<{ item: Item }>(itemGet, {
    variables: {
      id: props?.value,
    },
    skip: !props?.value,
  })


  const onChange = (item: Item) => {
    props?.onChange(item?.id)
  }

  const { refetch } = useQuery(itemSearchGql, {
    skip: true,
  })

  const onSearch = (value: string) => {
    return refetch({
      item: value,
    })
      .then(response => {
        return response.data.list || []
      })
  }

  const item = data?.item

  return < AutoCompleteInput<any>
    value={item}
    onChange={onChange}
    onSearch={onSearch}
    displayValue={(result) => `${result?.label} - ${result?.price}€`}
    label="Item"
    isError={props?.isError}
    disabled={false}
  />

}


/*
  onSearch: (value: string) => Promise<any[]>
  onChange: (value: any) => void,
  displayValue: (value: any) => string,
  displayResult: (value: any) => string,
  renderNoResult?: () => any,
  minLength?: number,
  label: string,
  isError: boolean,
  value: any,
  disabled: boolean,
  placeholder?: string,

*/
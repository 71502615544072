import { useQuery } from "@apollo/client"
import { IconEdit, Modal } from "@zipou/front_tools"
import phoneConfigListAllGql from "graphql/HOSTING/Phone/Config/query/phoneConfigListAll.gql"
import { PhoneConfig } from "model"
import { useState } from "react"
import { PhoneConfigEdit } from "../Edit/PhoneConfigEdit"



export const PhoneConfigList = () => {

  const { data } = useQuery<{ list: PhoneConfig[] }>(phoneConfigListAllGql)

  const [focusEdit, updateFocusEdit] = useState<string | null>()

  const phoneConfigList = data?.list
  return <div>
    {!!focusEdit && <Modal display title="Editer une config" onClose={() => updateFocusEdit(null)}>
      <PhoneConfigEdit id={focusEdit} onDone={() => updateFocusEdit(null)} />
    </Modal>}
    <h1>Liste des configurations Téléphonie</h1>
    <table className="table table-striped table-bordered">
      <thead>
        <tr className="bg-dark text-white">
          <td className="bg-dark text-white">Identifiant</td>
          <td className="bg-dark text-white">Numéros</td>
          <td className="bg-dark text-white">Actions</td>
        </tr>
      </thead>
      <tbody>
        {phoneConfigList?.map(phoneConfig => <tr key={`phoneConfig_${phoneConfig?.id}`}>
          <td>{phoneConfig?.name || phoneConfig?.id}</td>
          <td>
            <ul className="list-group">{phoneConfig?.PhoneNumber?.map(number => {
              return <li className="list-group-item">{number?.number}</li>
            })}
            </ul>
          </td>
          <td>
            <button className="btn btn-sm btn-warning" onClick={() => updateFocusEdit(phoneConfig?.id)}>
              <IconEdit />
            </button>
          </td>
        </tr>)}
      </tbody>
    </table>

  </div >
}
import gql from "graphql-tag"
import CursorFragment from "graphql/Cursor/fragment/CursorFragment"
import CustomerListFragment from "../fragment/CustomerListFragment"

export default gql`

  ${CustomerListFragment}
  ${CursorFragment}

  query customerList($input: CustomerListInput!, $cursor: CursorInput!) {
    list: customerList(cursor: $cursor, input: $input) {
      cursor {
        ...CursorFragment
      }
      nodes {
        ...CustomerListFragment
      }
    }
  }
`
import { Pagination } from "components/common/Navigation/Pagination/Pagination"
import { Cursor, Picture } from "model"
import { useNavigate } from "react-router-dom"
import { URL_WEDDING_PAGE } from "static/constant/front/url"
import { PictureDisplay } from "../Display/PictureDisplay"


type PictureListProps = {
  page: number,
  pictureList?: Picture[],
  cursor?: Cursor,
}


export const PictureList = (props: PictureListProps) => {

  // const [focusPicture, updateFocusPicture] = useState<Picture | null>()

  const page = props?.page ? Number(props?.page) : 1
  const navigate = useNavigate()

  const pictureList = props?.pictureList
  const cursor = props?.cursor

  return <div className="picture-list-container ">

    {/* {focusPicture && <div style={{ position: "fixed", top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 5000, backgroundColor: "black", overflow: "hidden" }}>
      <div className="" style={{ position: "fixed", top: 10, right: 10, zIndex: 10000, fontSize: 30 }} onClick={() => updateFocusPicture(null)}>
        <span style={{color: "white"}} className="icon-close"></span>
      </div>
      <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: "red", zIndex: 5001, width: '100vw', height: '100vh', }}>
        <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: "black", zIndex: 5001, width: '100vw', height: '100vh', overflow: "scroll" }}>
          <picture>
            <img className="img-fluid" width={focusPicture?.isLandscape ? "" : "100%"} height={focusPicture?.isLandscape ? "100%" : ""} src={focusPicture?.File?.url} />
          </picture>
        </div>
      </div>
    </div>
    } */}

    <div className="row">
      {pictureList?.map(picture => {
        return <PictureDisplay picture={picture} key={`picture_${picture?.id}`} />
      })}

    </div>
    <div className="d-flex justify-content-center pt-2">
      <Pagination cursor={cursor} onChange={(page) => {
        navigate(URL_WEDDING_PAGE.replace(":page", `${page}`))
      }} />

    </div>

  </div>

}
import {gql} from "@apollo/client"
import OrderSearchFragment from "../fragment/OrderSearchFragment"

export default gql`

  ${OrderSearchFragment}

  mutation orderAdd($input: OrderInput!) {
    orderAdd(input: $input) {
      ...OrderSearchFragment
    }
  }
`
import { gql } from '@apollo/client'

import InvoiceFragment from '../fragment/InvoiceFragment'

export default gql`

${InvoiceFragment}

mutation invoiceAdd($input: InvoiceInput!) {
  invoice: invoiceAdd(input: $input) {
    ...InvoiceFragment
  }
}
`
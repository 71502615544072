import { useMutation, useQuery } from "@apollo/client"
import { IconEdit, IconSearch, IconTrash, Modal, TextInput } from "@zipou/front_tools"
import { Pagination } from "components/common/Navigation/Pagination/Pagination"
import customerDeleteGql from "graphql/BILLING/Customer/mutation/customerDelete.gql"
import customerListGql from "graphql/BILLING/Customer/query/customerList.gql"
import { Customer, CustomerListInput } from "model"
import { useState } from "react"
import { Link } from "react-router-dom"
import { URL_BILLING_CUSTOMER_VIEW } from "static/constant/backoffice/url"
import { CustomerEdit } from "../Edit/CustomerEdit"
import { CustomerAdd } from "../Add/CustomerAdd"


type CustomerListProps = {

}

export const CustomerList = (props: CustomerListProps) => {


  const [focusEdit, updateFocusEdit] = useState<Customer | null>()
  const [focusAdd, updateFocusAdd] = useState(false)

  const [mutateDelete] = useMutation(customerDeleteGql)
  const [input, updateInput] = useState<CustomerListInput>({
    name: "",
  })

  const [focusName, updateFocusName] = useState(false)

  const { data, refetch } = useQuery(customerListGql, {
    fetchPolicy: "cache-and-network",
    variables: {
      input,
      cursor: {
        page: 1,
        limit: 10,
      },
    }
  })

  const onDelete = (id: string) => {
    mutateDelete({
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: customerListGql,
          variables: {
            cursor: {
              page: data?.list?.cursor?.page,
              limit: data?.list?.cursor?.limit,
            }
          }
        }
      ]

    })
  }

  const customerList = data?.list?.nodes

  return <div>
    <Modal display={!!focusEdit} onClose={() => updateFocusEdit(null)}>
      <CustomerEdit id={focusEdit?.id || ""} onDone={() => updateFocusEdit(null)} />
    </Modal>
    <Modal display={focusAdd} onClose={() => updateFocusAdd(false)}>
      <CustomerAdd onDone={() => updateFocusAdd(false)} />
    </Modal>
    <Modal display={focusName} onClose={() => updateFocusName(false)}>
      <TextInput value={input.name || ""} onChange={(v) => updateInput({
        ...input,
        name: v
      })} errors={{}} label="Nom" rightContent={<span className="" onClick={() => {
        updateInput({
          ...input,
          name: ""
        })
        updateFocusName(false)
      }}><IconTrash /></span>} />
    </Modal>
    <table className="table table-stripped table-bordered">
      <thead>
        <tr className="bg-dark text-white">
          <td>
            <button className="btn btn-sm btn-secondary text-white" onClick={() => updateFocusName(true)}>Nom</button>
          </td>
          <td>Actions</td>
        </tr>
      </thead>

      <tbody>
        {customerList?.map((customer: Customer) => {
          return <tr key={`customer_list_${customer.id}`}>
            <td>{customer?.name}</td>
            <td>
              <Link to={`/manager${URL_BILLING_CUSTOMER_VIEW.replace(':id', customer?.id)}`}>
                <button className="btn btn-sm btn-warning ml-1" >
                  <IconSearch />
                </button>
              </Link>
              <button className="btn btn-sm btn-dark ml-1" onClick={() => updateFocusEdit(customer)}>
                <IconEdit />
              </button>
              <button className="btn btn-sm btn-danger ml-1" onClick={() => onDelete(customer.id)}>
                <IconTrash />
              </button>

            </td>
          </tr>
        })}
      </tbody>
    </table>
    <Pagination cursor={data?.list.cursor} onChange={(page: number, limit?: number) => refetch({
      cursor: {
        page,
        limit: limit || data?.list?.cursor?.limit,
      },
    })} />
    <div>

      <button className="btn btn-sm btn-dark" onClick={() => updateFocusAdd(true)}>Ajouter</button>
    </div>
  </div >



}
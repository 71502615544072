import { gql } from "@apollo/client";
import ClusterRoleConfigFragmentGql from "../fragment/ClusterRoleConfigFragment.gql";

export default gql`

${ClusterRoleConfigFragmentGql}

mutation clusterRoleConfigForceDeploy($id: String!) {
  clusterRoleConfig: clusterRoleConfigForceDeploy(id: $id) {
    ...ClusterRoleConfigFragment
  }
}

`
import { gql } from "@apollo/client";
import CustomerViewFragment from "../fragment/CustomerViewFragment";

export default gql`

${CustomerViewFragment}

query customerGet($id: String!) {
  customer: customerGet(id: $id) {
    ...CustomerViewFragment
  }
}

`
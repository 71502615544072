
import {useQuery} from "@apollo/client"
import {Count} from "components/backoffice/Widget/Count"

import billingOperationLastQuery from "graphql/BILLING/BillingOperation/query/billingOperationLast"
import {DateTime} from "../../../../libs/DateTime"


export const BillingOperationLast = (props: any) => {

  const {data} = useQuery(billingOperationLastQuery)

  const dateFormatted = data?.billingOperationLast && new DateTime(data?.billingOperationLast).getDateString()

  return <Count type="info"
    number={dateFormatted}
    lib="Dernière Facturation"
    icon="calendar"
  />

}
import { IconTrash, TextInput } from "@zipou/front_tools"
import { CameraInput, VideoConfigInput } from "model"
import { CameraForm } from "../../Camera/Form/CameraForm"

type VideoConfigFormProps = {
  videoConfig: VideoConfigInput,
  errors: any,
  onChange: (videoConfig: VideoConfigInput) => void,
}

export const VideoConfigForm = ({ videoConfig, errors, onChange }: VideoConfigFormProps) => {


  const cameraList: CameraInput[] = videoConfig?.CameraList || []

  return <div>
    <TextInput id="name" value={videoConfig?.name || ""} errors={errors} label="Nom" onChange={(v) => {
      onChange({
        ...videoConfig,
        name: v,
      })
    }} />
    <TextInput id="storageDir" value={videoConfig?.storageDir || ""} errors={errors} label="Dossier Stockage" onChange={(v) => {
      onChange({
        ...videoConfig,
        storageDir: v,
      })
    }} />
    <div className="input-group">
      <span className="input-group-text">Camera</span>
      <div className="form-control" style={{ height: "100%" }}>
        {cameraList?.map((camera, index) => {
          return <div className="input-group" key={`camera_${camera?.id || ""}_${index}`}>
            <span className="input-group-text">Camera {index + 1}</span>
            <div className="form-control" style={{ height: "100%" }}>
              <CameraForm
                errors={errors}
                camera={camera}
                onChange={(c) => {
                  onChange({
                    ...videoConfig,
                    CameraList: videoConfig?.CameraList?.map((cam, idx) => idx === index ? c : cam)
                  })
                }} />
            </div>
            <span className="input-group-text" onClick={() => {
              onChange({
                ...videoConfig,
                CameraList: [...(videoConfig?.CameraList || []), {}]
              })
            }}>

              <IconTrash />
            </span>
          </div>
        })}

        <button className="btn btn-sm btn-dark" onClick={() => {
          onChange({
            ...videoConfig,
            CameraList: [...(videoConfig?.CameraList || []), {}]
          })
        }}>Ajouter</button>
      </div>
    </div>
  </div>
}


export const TinkLinkRoute = (props: any) => {


  const search = props.location.search; // could be '?foo=bar'
  const params = new URLSearchParams(search);
  const code = params.get('code'); // bar
  const credentialsId = params.get('credentialsId'); // bar

  return <div>
    <h1>Tink Route</h1>
    <p>Code: {code}</p>
    <p>CredentialsId: {credentialsId}</p>
  </div>
}


/* 

code=372ddafa925c4a5593c3199cc34749de&
credentialsId=110c5d321d6d43a58a922f9c66dc08fd&
credentials_id=110c5d321d6d43a58a922f9c66dc08fd

*/
import { Picture } from "model"
import { translateCategory } from "../Category"
import { useState } from "react"

type PictureDisplayProps = {
  picture: Picture,
  // onClick: (picture: Picture) => void,
}


export const PictureDisplay = (props: PictureDisplayProps) => {
  const [focusPicture, updateFocusPicture] = useState<Picture | null>()

  const picture = props?.picture
  const thumburl = picture?.ThumbnailFile?.url

  return <div key={`picture_${picture?.id}`} className="col-6 col-sm-4 p-2">
    {focusPicture && <div style={{ position: "fixed", top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 5000, backgroundColor: "black", overflow: "hidden" }}>
      <div className="" style={{ position: "fixed", top: 10, right: 10, zIndex: 10000, fontSize: 30 }} onClick={() => updateFocusPicture(null)}>
        <span style={{ color: "white" }} className="icon-close"></span>
      </div>
      <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: "red", zIndex: 5001, width: '100vw', height: '100vh', }}>
        <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: "black", zIndex: 5001, width: '100vw', height: '100vh', overflow: "scroll" }}>
          <picture>
            <img className="img-fluid" width={focusPicture?.isLandscape ? "" : "100%"} height={focusPicture?.isLandscape ? "100%" : ""} src={focusPicture?.File?.url || ""} />
          </picture>
        </div>
      </div>
    </div>
    }
    <div>
      <img className="img-fluid" src={thumburl || ""} style={{ border: "1px black solid" }} onClick={() => updateFocusPicture(picture)}></img>
    </div>
    <div><span className="" style={{ fontWeight: 200, fontStyle: "italic" }}>{translateCategory(picture?.category || "")}</span> par <span className="" style={{ fontStyle: "italic", fontWeight: 400 }}>{picture?.Guest?.fullName}</span></div>
  </div>
}
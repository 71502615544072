import {gql} from "@apollo/client";

export default gql`

fragment OrderSearchFragment on Order {
  id
  publicId
  dateStart
  dateEnd
  interval
  orderStatus: status
  billingDayOfMonth
  OrderLine {
    id
    quantity
    dateStart
    dateEnd
    Item {
      id
      label
      price
      vat
    }
  }
}

`
import { gql } from '@apollo/client'
import ItemFragment from '../fragment/ItemFragment'

export default gql`

  ${ItemFragment}

  mutation itemAdd($input: ItemInput!) {
    itemAdd(input: $input) {
      ...ItemFragment
    }
  }
`
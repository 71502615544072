import { PhoneConfigModule } from "@zipou/phone_front"

type PhoneConfigModuleDisplayProps = {
  id: string,
  token: string,
}

export const PhoneConfigModuleDisplay = ({ id, token, }: PhoneConfigModuleDisplayProps) => {

  return <div>
    <PhoneConfigModule
      phoneConfigId={id}
      token={token || ""}
      moduleConfig={{
        url: "https://www.box4b.fr",
        wsUrl: "wss://www.box4b.fr/subscriptions",
      }}
    />
  </div>

}
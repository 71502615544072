import { gql } from "@apollo/client";
import VideoConfigFragmentGql from "../fragment/VideoConfigFragment.gql";

export default gql`

  ${VideoConfigFragmentGql}

  mutation videoConfigUpdate($id: String!, $input: VideoConfigInput!) {
    videoConfig: videoConfigUpdate(id: $id, input: $input) {
      ...VideoConfigFragment
    } 
  }

`
import React from "react"
import {Link} from "react-router-dom";
import {useQuery} from "@apollo/client";

import {Card} from "components/common/Card/Card"

import {DateTime} from "libs/DateTime"


import {URL_ORDER_UPDATE_PAYMENT_PREF_TEMPLATE} from "static/constant/backoffice/url"
import {BankAccountItem} from "components/backoffice/BILLING/PaymentPref/BankAccount/BankAccountItem";
import {CardItem} from "components/backoffice/BILLING/PaymentPref/Card/CardItem";
import {TransferItem} from "components/backoffice/BILLING/PaymentPref/Transfer/TransferItem";

import './style.scss'
import orderGet from "graphql/BILLING/Order/query/orderGet";
import {Contact, OrderLine} from "model";

type OrderUpdateProps = {
  id: string
}

export const OrderUpdate = (props: OrderUpdateProps) => {

  const {data} = useQuery(orderGet, {
    variables: {
      id: props?.id,
    },
  })

  const order = data && data.order;

  const {dateStart, OrderLine, Contract, billingDayOfMonth, PaymentPref, publicId} = order || {}

  const {Customer} = Contract || {}
  const {Address, name, Contact} = Customer || {}
  const {address1, address2, zip, town} = Address || {}
  const adminContact = Contact?.find((el: Contact) => el.type === "TYPE_ADMIN")
  const {firstname, lastname} = adminContact || {}
  const dateStartFormatted = new DateTime(dateStart).getString()

  const {totalTTC, totalHT} = OrderLine?.reduce((acc: any, current: OrderLine) => {
    const {Item, quantity} = current || {}
    const price = Item?.price || 1
    const vat = Item?.vat || 1
    const priceTTC = Math.round(price * (1 + vat / 100))
    return {
      totalTTC: Number(acc.totalTTC + Number((priceTTC) * quantity)),
      totalHT: Number(acc.totalHT + Number((price) * quantity)),
    }
  }, {
    totalTTC: 0,
    totalHT: 0,
  }) || {}

  // console.log({type})

  return (
    <div className="order-front-edit-container">
      <div className="order-edit-row">
        <h1>Bon de commande</h1>
        <div className="row">
          <div className="order-edit-ids col-md-6">
            <Card title="Informations">
              <table className="table table-stripped  ">
                <tbody>
                  <tr>
                    <th>Identifiant</th>
                    <td>{publicId}</td>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <td>{dateStartFormatted}</td>
                  </tr>
                  <tr>
                    <th>Jour de Facturation</th>
                    <td>{billingDayOfMonth}</td>
                  </tr>
                </tbody>
              </table>
            </Card>

          </div>
        </div>
      </div>
      <div className="order-edit-row row">
        <div className="order-edit-lines col-12">
          <Card title="Détails">
            <table className="table table-stripped  ">
              <thead>
                <tr>
                  <th>Prestation</th>
                  <th>Date de début</th>
                  <th>Date de fin</th>
                  <th>Qté</th>
                  <th>P.U.</th>
                  <th>Total mensuel HT</th>
                </tr>
              </thead>
              <tbody>
                {OrderLine && OrderLine.map((line: OrderLine) => {
                  const {id, dateStart, dateEnd, Item, quantity} = line
                  const {label, price} = Item || {}
                  const proceFormatted = (price || 1) / 100
                  const totalHT = (price || 1) * quantity / 100
                  const dateStartFormated = dateStart && new DateTime(dateStart).getDateString()
                  const dateEndFormated = (dateEnd && new DateTime(dateEnd).getDateString()) || "XXX"
                  return (
                    <tr key={id}>
                      <td>{label}</td>
                      <td>{dateStartFormated}</td>
                      <td>{dateEndFormated}</td>
                      <td>{quantity}</td>
                      <td>{proceFormatted}</td>
                      <td>{totalHT}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="order-total col-12 col-sm-3 offset-sm-8">
              <table className="table table-stripped  ">
                <tbody>
                  <tr>
                    <td>Total HT</td>
                    <th>Total TTC</th>
                  </tr>
                  <tr>
                    <td>{totalHT / 100} €</td>
                    <th>{totalTTC / 100} €</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </div>
      </div>
      <div className="payment-container">
        {PaymentPref && <div className="">
          <Card title="Votre mode de Paiement">
            {PaymentPref.type === "TYPE_DEBIT" && <div>
              <h2>Prélèvement SEPA</h2>
              <BankAccountItem bankAccount={PaymentPref.BankAccount} />
            </div>}
            {PaymentPref.type === "TYPE_CARD" && <div>
              <h2>Carte Bancaire</h2>
              <CardItem card={PaymentPref.Card} />
            </div>}
            {PaymentPref.type === "TYPE_TRANSFER" && <div>
              <h2>Virement bancaire</h2>
              <TransferItem publicId totalTTC/>
            </div>}
          </Card>
        </div>}
      </div>
      <div className="actions">
        <Card title="Actions">
          <Link to={URL_ORDER_UPDATE_PAYMENT_PREF_TEMPLATE.replace(':id', order?.id)}><button className="btn btn-dark" style={{marginRight: 10}}>Modifier votre mode de règlement</button></Link>
        </Card>
      </div>
    </div>
  )
}

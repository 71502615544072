import { gql } from '@apollo/client'
import InvoiceFragment from '../fragment/InvoiceFragment'

export default gql`

  ${InvoiceFragment}

  mutation invoiceUpdate($id: String!, $input: InvoiceInput!) {
    invoice: invoiceUpdate(id: $id, input: $input) {
      ...InvoiceFragment
    }
  }
`
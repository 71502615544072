import { useQuery } from "@apollo/client"
import tagListGql from "graphql/BILLING/Tag/query/tagList.gql"
import { Tag } from "model"
import { useState } from "react"

import './style.scss'
import { IconTrash } from "@zipou/front_tools"

type TagInputProps = {
  tagSelectedList: (Tag | null)[],
  onSelect: (tag: Tag | null) => void,
  onDelete: (tag: Tag | null) => void,
}

export const TagInput = (props: TagInputProps) => {


  const [inputValue, updateInputValue] = useState("")
  const [resultListIndexSelected, updateResultListIndexSelected] = useState<number>(0)

  const { data } = useQuery<{ list: Tag[] }>(tagListGql)

  const tagSelectedList = props?.tagSelectedList
  const hasTagSelected = tagSelectedList?.length > 0
  const tagList: Tag[] = data?.list || []
  const hasResults = inputValue?.length > 0 && tagList?.length > 0
  const resultList = tagList?.filter(el => el.name?.toLowerCase().includes(inputValue?.toLowerCase()))

  const onSelectValue = (value: Tag) => {
    props?.onSelect(value)
    updateInputValue("")
  }

  const handleKeyDown = (e: any) => {
    const nbResults = resultList?.length
    // console.log(e.keyCode)
    if (e.keyCode === 38 && (resultListIndexSelected > 0)) {
      //UP
      updateResultListIndexSelected(resultListIndexSelected - 1)
      // console.log("UP")
    } else {
      if (e.keyCode === 40 && (resultListIndexSelected < nbResults - 1)) {
        //DOWN
        // console.log("DOWN")
        updateResultListIndexSelected(resultListIndexSelected + 1)
      } else {
        if (e.keyCode === 13) {
          e.preventDefault()
          onSelectValue(resultList[resultListIndexSelected])
        }
      }
    }
  }

  return <div className="tag-input-container d-flex flex-row justify-content-center " style={{ border: "1px #CCC solid", height: 33 }}>
    {hasTagSelected && <div className="tag-container d-flex flex-row p-2 justify-content-center">
      {tagSelectedList?.map(tag => {
        return <span className="badge badge-info ml-1 mr-1 d-flex align-items-center" style={{ backgroundColor: tag?.color ? `#${tag.color}` : "" }}>
          {tag?.name}
          <span className="ml-1" onClick={() => props?.onDelete(tag)} >
            <IconTrash />
          </span>
        </span>
      })}
    </div>}
    <div className="input-container d-flex flex-column" style={{ flexGrow: 1, }}>
      <div className="input-container d-flex flex-row " style={{ flexGrow: 1 }}>
        <input className="form-control form-control-sm" onKeyDown={handleKeyDown} value={inputValue} placeholder="Ajouter un tag" style={{ border: 0, boxShadow: "none!important:" }} onChange={e => updateInputValue(e.target.value)}></input>
      </div>
      <div className="">
        <ul className="list-group d-flex">
          {hasResults && resultList?.map((result, index: number) => {
            const isActive = index === resultListIndexSelected
            return <li style={{ flexFlow: "column wrap" }} className={isActive ? "list-group-item active" : "list-group-item"} onMouseEnter={() => updateResultListIndexSelected(index)} onMouseLeave={() => updateResultListIndexSelected(-1)} onClick={() => onSelectValue(result)}>
              {result?.name}
            </li>
          })}
        </ul>
      </div>
    </div>
  </div>

}
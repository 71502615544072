import {gql} from '@apollo/client'
import CursorFragment from 'graphql/Cursor/fragment/CursorFragment'
import OrderPublicFragment from '../fragment/OrderPublicFragment'

export default gql`

  ${OrderPublicFragment}
  ${CursorFragment}

  query orderList($cursor: CursorInput!, $input: OrderListInput!) {
    list: orderList(cursor: $cursor, input: $input) {
      nodes {
        ...OrderPublicFragment
      }
      cursor {
				...CursorFragment
      }
    }
  }

`
import {gql} from "@apollo/client";

export default gql`

  fragment AddressFragment on Address {
    address1
    address2
    zip
    town
  }
`
import {gql} from "@apollo/client";
import CursorFragment from "graphql/Cursor/fragment/CursorFragment";
import ClusterProvisioningTaskFragmentGql from "../fragment/ClusterProvisioningTaskFragment.gql"

export default gql`

${ClusterProvisioningTaskFragmentGql}
${CursorFragment}

query clusterProvisioningTaskList($input: ClusterProvisioningTaskListInput!, $cursor: CursorInput!) {
  list: clusterProvisioningTaskList(input: $input, cursor: $cursor) {
    input
    cursor {
      ...CursorFragment
    }
    nodes {
      ...ClusterProvisioningTaskFragment
    }
  }
}

`
import { gql, useMutation, useQuery } from "@apollo/client"
import { Modal } from "@zipou/front_tools"

import { Pagination } from "components/common/Navigation/Pagination/Pagination"
import clusterProvisioningTaskDeleteGql from "graphql/HOSTING/Cluster/ProvisioningTask/mutation/clusterProvisioningTaskDelete.gql"
import clusterProvisioningTaskListGql from "graphql/HOSTING/Cluster/ProvisioningTask/query/clusterProvisioningTaskList.gql"
import { useState } from "react"
import { ProvisioningTaskStart } from "../Start/ProvisioningTaskStart"
import { ClusterProvisioningTaskStatus } from "../Status/ClusterProvisioningTaskStatus"
import { IconTrash } from "@zipou/front_tools"
import { ClusterProvisioningTask, ClusterProvisioningTaskList as ClusterProvisioningTaskListModel } from "model"

export const ClusterProvisioningTaskList = () => {


  const [showFilterInput, updateShowFilterInput] = useState(false)
  const [input, updateInput] = useState({
    status: ["TODO", "ERROR", "RUNNING"],
    orderField: "status",
    orderDirection: "ASC",
  })

  const statusListQuery = useQuery(gql`query clusterProvisioningTaskStatusList { list: clusterProvisioningTaskStatusList }`)
  const { data, refetch } = useQuery<{ list: ClusterProvisioningTaskListModel }>(clusterProvisioningTaskListGql, {
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        ...input,
      },
      cursor: {
        page: 1,
        limit: 10,
      }
    }
  })



  const [deleteMutation] = useMutation(clusterProvisioningTaskDeleteGql, {
    refetchQueries: [{
      query: clusterProvisioningTaskListGql,
      variables: {
        input: {
          ...input,
        },
        cursor: {
          page: data?.list?.cursor?.page,
          limit: data?.list?.cursor?.limit,
        }
      }
    }]
  })


  const handleTaskDelete = (id: string) => {
    deleteMutation({
      variables: {
        id
      }
    })
  }

  const taskList = data?.list?.nodes || []
  const cursor = data?.list?.cursor
  const hasElements = taskList?.length > 0

  const taskMap = taskList?.reduce<{ [key: string]: ClusterProvisioningTask[] }>((acc, item) => {
    return {
      ...acc,
      [item?.Server?.id || ""]: [
        ...(acc[item?.Server?.id || ""] || []),
        item,
      ]
    }
  }, {})


  return <div>
    <h1>Liste des taches</h1>
    <Modal display={showFilterInput} title="Status" onClose={() => updateShowFilterInput(false)}>
    </Modal>
    <div className="card">
      <div className="card-header">Filtres</div>
      <div className="card-body">
        <ul className="list-group">
          {statusListQuery?.data?.list?.map((status: string) => {
            const isChecked = input?.status.includes(status)
            return <li className="list-group-item" key={`status_${status}`}>
              <input id={status} type="checkbox" checked={isChecked} onChange={(e: any) => {
                updateInput({
                  ...input,
                  status: (e.target.checked) ? [...(input.status || []), status] : input.status?.filter((el: string) => el !== status)
                })
              }} /> <label htmlFor={status}>
                <ClusterProvisioningTaskStatus status={status} />
              </label>
            </li>
          })}
        </ul>

      </div>
    </div>

    {Object.keys(taskMap)?.map((key, index) => {
      const taskList = taskMap[key]
      const server = taskList[0].Server
      return <div className="card mt-2" key={`task_${server?.id}_index_${index}`}>
        <div className="card-header bg-dark text-white">
          <span>{server?.name}</span>
          <span className="badge badge-warning ml-2">{taskList?.length}</span>
        </div>
        <div className="card-body">
          <table className="table table-striped table-bordered">
            <tbody>
              {taskList?.map((task) => {

                return <tr key={`taskList_ID_${task?.id}`}>
                  <td>{task?.Server?.name}</td>
                  <td>{task.payload?.name}</td>
                  <td>{task?.type}</td>
                  <td><ClusterProvisioningTaskStatus status={task?.status} /></td>
                  <td>
                    <ProvisioningTaskStart task={task} />
                    <button className="btn btn-sm btn-danger" style={{ marginLeft: 5 }} onClick={() => handleTaskDelete(task?.id)}>
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
    })}

    {/* 
    <table className="table table-bordered table-stripped">
      <thead>
        <tr className="bg-dark text-white">
          <th className="bg-dark text-white align-middle" >Server</th>
          <th className="bg-dark text-white align-middle">Name</th>
          <th className="bg-dark text-white align-middle">Type</th>
          <th className="bg-dark text-white align-middle">
            <button className="btn btn-sm btn-secondary" onClick={() => updateShowFilterInput(true)}>Status</button>
          </th>
          <th className="bg-dark text-white align-middle">Action</th>
        </tr>
      </thead>
      <tbody>
        {!hasElements && <tr>
          <td colSpan={5}>
            <div className="alert alert-warning">Rien à afficher</div>
          </td>
        </tr>}
        {hasElements && taskList?.map((task: any) => {

          // const isStartable = task.status === "TODO" || task.status === "ERROR"
          // const isActive = isRunning === task?.id

          return <tr key={`taskList_ID_${task?.id}`}>
            <td>{task?.Server?.name}</td>
            <td>{task.payload?.name}</td>
            <td>{task?.type}</td>
            <td><ClusterProvisioningTaskStatus status={task?.status} /></td>
            <td>
              <ProvisioningTaskStart task={task} />
              <button className="btn btn-sm btn-danger" style={{ marginLeft: 5 }} onClick={() => handleTaskDelete(task?.id)}>
                <IconTrash />
              </button>
            </td>
          </tr>
        })}
      </tbody>
    </table> */}
    {/* <div>
      <Pagination cursor={cursor} onChange={page => {
        // @ts-ignore
        const { __typename, totalPages, hasMore, ...cursorInput } = cursor
        refetch({
          input,
          cursor: {
            ...cursorInput,
            page,
          }
        })
      }}></Pagination>
    </div> */}
  </div>
}
import {gql} from "@apollo/client"
import InvoiceDetailsFragment from  "../fragment/InvoiceDetailsFragment"

export default gql`

  ${InvoiceDetailsFragment}

  query invoiceGetById($invoiceId: String!) {
    invoiceGetById(invoiceId: $invoiceId) {
      ...InvoiceDetailsFragment
    }
  }
`
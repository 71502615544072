import {gql} from '@apollo/client'
import CardFragment from '../fragment/CardFragment'

export default gql`

  ${CardFragment}

  query cardList {
    cardList {
      ...CardFragment
    }
  }
`
export const http_protocol  =  "http";
export const root_hostname  =  "127.0.0.1";
export const port = 4001;
export const graphql_endpoint  =  `${http_protocol}://${root_hostname}:${port}/graphql`;
export const api_enpoint  =  `${http_protocol}://${root_hostname}:${port}`;
export const url_bucket_image = `http://192.168.50.145:9005/images`

export const URL_DEPLOYER = `http://deployer.localhost:3003`

export const URL_FRONT_CANONICAL = `${http_protocol}://${root_hostname}`;

export const URL_VHOST_PHONE = "phone.localhost"
export const PORT_VHOST_PHONE = 444
export const PORT_VHOST_PHONE_LEGACY = 445
export const URL_ROOT_PHONE = `https://${URL_VHOST_PHONE}:${PORT_VHOST_PHONE}`
export const URL_ROOT_PHONE_LEGACY = `https://${URL_VHOST_PHONE}:${PORT_VHOST_PHONE_LEGACY}`

export const stripe_publishable = `pk_test_AES9SKiCoemfCO3z4RDbKnql`
import {gql} from "@apollo/client"
import PaymentDetailsFragment from "../fragment/PaymentDetailsFragment"

export default gql`

  ${PaymentDetailsFragment}

  query paymentGetById($paymentId: String!) {
    paymentGetById(paymentId: $paymentId) {
      ...PaymentDetailsFragment
    }
  }


`
import { gql } from "@apollo/client";

export default gql`

  fragment PhoneConfigListFragment on PhoneConfig {
    id
    name
    token
    PhoneNumber {
      id
      number
    }
  }

` 
import React from "react"

export const BankAccountItem = (props) => {

  const {bankAccount} = props

  const {stripeMandateUrl, last4, bankName} = bankAccount || {}
  const accountNumber = (last4) ? `XXXX-XXXX-XXXX-XXXX-XX${last4.substring(0, 2)}-${last4.substring(2, 4)}` : ''
  return (
    <div className="bank-account-item">
      <div className="account-name col-12">
        {bankName && <div className="input-group">
          <span>{bankName}</span>
        </div>}
      </div>
      <div className="account-number col-12">
        <div className="input-group">
          <span>{accountNumber}</span>
        </div>
      </div>
      {stripeMandateUrl && <div className="account-name col-12">
        <div className="input-group">
          <a href={stripeMandateUrl} target='_blank' rel="noopener noreferrer">Détails Mandat</a>
        </div>
      </div>}
    </div>
  )
}

import { gql } from "@apollo/client";

export default gql`


  fragment PictureFragment on Picture {

    id
    category
    isLandscape
    width
    height
    File {
      id
      url
    }
    ThumbnailFile {
      id
      url
    }
    Guest {
      id
      fullName
    }

  }

`
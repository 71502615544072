import {gql} from "@apollo/client";
import UserProfileFragment from "../fragment/userProfileFragment";

export default gql`

  ${UserProfileFragment}

  mutation userProfileUpdate($input: ProfileInput!) {
    user: userProfileUpdate(input: $input) {
      ...UserProfileFragment
    }
  }

`
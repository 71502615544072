import { useState } from 'react'

import { ServerList } from 'components/backoffice/HOSTING/Server/List/ServerList'
import { Modal } from "@zipou/front_tools"

import { ServerAdd } from 'components/backoffice/HOSTING/Server/Add/ServerAdd'

export const HostingServerListRoute = () => {

  const [focusAdd, updateFocusAdd] = useState<any>(null)


  return <div>
    <Modal title="Ajouter" display={focusAdd} onClose={() => updateFocusAdd(null)}>
      <ServerAdd onDone={() => updateFocusAdd(null)} />
    </Modal>
    <h1>Hébergement</h1>
    <ServerList />

    <button className="btn btn-dark btn-sm" onClick={() => updateFocusAdd(true)}>Ajouter</button>

  </div>
}

import { IconLoading } from "@zipou/front_tools"
import { ClusterProvisioningTaskStatusEnum } from "model"

export type ClusterProvisioningTaskStatusProps = {
  status: string,
}

export const ClusterProvisioningTaskStatus = (props: ClusterProvisioningTaskStatusProps) => {

  switch (props?.status) {
    case ClusterProvisioningTaskStatusEnum.RUNNING: {
      return <span className="badge badge-warning">
        <IconLoading />
        <span className="ml-1">EN COURS</span>
      </span>
    }
    case ClusterProvisioningTaskStatusEnum.TODO: {
      return <span className="badge badge-secondary">A FAIRE</span>
    }
    case ClusterProvisioningTaskStatusEnum.DONE: {
      return <span className="badge badge-success">DONE</span>
    }
    case ClusterProvisioningTaskStatusEnum.ERROR: {
      return <span className="badge badge-danger">ERREUR</span>
    }
    default: {
      return <span className="badge badge-secondary">{props?.status}</span>

    }
  }

}
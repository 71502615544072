import { PhoneConfigList } from "components/backoffice/PHONE/Config/List/PhoneConfigList"




export const PhoneConfigListRoute = () => {


  return <div className="phone-config-list-route">
    <PhoneConfigList />
  </div>
}
import {gql} from "@apollo/client";

export default gql`

fragment OrderFragment on Order {
  id
  publicId
  dateStart
  dateEnd
  interval
  status
  billingDayOfMonth
  paymentPrefId
  contractId
  Contract {
    id
    Company {
      id
      stripePk
    }
    Customer {
      id
    }
  }
  OrderLine {
    id
    quantity
    dateStart
    dateEnd
    description
    itemId
  }
}

`
import {gql} from "@apollo/client";
import FirewallFragment from "../fragment/FirewallFragment";

export default gql`

  ${FirewallFragment}

  query firewallGetByServerId($id: String!) {
    firewall: firewallGetByServerId(id: $id) {
      ...FirewallFragment
    }
  }

`
import { gql } from "@apollo/client";
import TagFragment from "../fragment/TagFragment";

export default gql`

  ${TagFragment}

  query tagList {
    list: tagList {
      ...TagFragment
    }
  }

`
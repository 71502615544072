import {useEffect, useRef, useState} from "react"
import yaml from "js-yaml"

import codemirror from 'codemirror';
import 'codemirror/mode/yaml/yaml.js';
import 'codemirror/lib/codemirror.css';


type YamlEditorProps = {
  onChange: (datas: any) => void,
  content: string,
}

export const YamlEditor = (props: YamlEditorProps) => {

  const [content, updateContent] = useState(props?.content || "")
  const [cmLoaded, updateCmLoaded] = useState(false)
  const textarea = useRef(null)

  const onEditorChange = (e: any) => {
    const value = e.getValue()
    updateContent(value)
  }


  useEffect(() => {
    if (textarea.current && !cmLoaded) {
      // console.log("TEXTAREA PRESENT")
      //@ts-ignore
      const cm = codemirror.fromTextArea(textarea.current, {
        mode: 'yaml',
        lineNumbers: true
      })

      content && cm.getDoc().setValue(content)
      cm.on('change', onEditorChange);
      updateCmLoaded(true)
    }
  })

 

  const _checkYaml = (content: any) => {
    try {
      const doc = yaml.load(content);
      return Promise.resolve(doc);
    } catch (e) {
      return Promise.reject(e)
    }
  }


  const onSubmit = (e: any) => {
    e.preventDefault()
    return _checkYaml(content)
      .then(() => {
        props?.onChange(content)
      })
      .catch(() => {
        // console.log("NOK")
      })
  }

  return <div className="yaml-edit-container">
    <form onSubmit={onSubmit}>
      <div className="yaml-edit-form" style={{textAlign: "left"}}>
        <textarea ref={textarea} className="form-control" />
      </div>
      <button type="submit" className="btn btn-info">Save</button>
    </form>
  </div>
}

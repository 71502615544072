import { gql } from "@apollo/client";

export default gql`

  fragment TagFragment on Tag {
    id
    parentId
    name
    color
    Children {
      id
      name
      color 
      parentId
    }
  }

`
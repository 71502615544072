import React from 'react'

import {TemplateList} from 'components/backoffice/HOSTING/Provisioning/Template/TemplateList'
import {Breadcrumb} from "components/common/Navigation/Breadcrumb/Breadcrumb";

export const HostingTemplateListRoute = props => {
  return (
    <div>
      {/* <Breadcrumb match={props.match} /> */}
      <TemplateList />
    </div>
  )
}

import { useMutation, useQuery } from "@apollo/client"
import { CurrencyFormat } from "components/common/Format/CurrencyFormat"
import { DateFormat } from "components/common/Format/DateFormat"
import { Pagination } from "components/common/Navigation/Pagination/Pagination"
import transactionListGql from "graphql/BILLING/Transaction/query/transactionList.gql"
import { Cursor, Transaction } from "model"
import { useState } from "react"
import { TransactionValueDisplay } from "../Value/Display/TransactionValueDisplay"
import { TransactionListDisplay } from "./Display/TransactionListDisplay"
import { Modal } from "@zipou/front_tools"
import { TransactionImport } from "../Import/TransationImport"

type TransactionListProps = {

}

export const TransactionList = (props: TransactionListProps) => {

  const [focusAdd, updateFocusAdd] = useState(false)

  const [cursor, updateCursor] = useState({
    page: 1,
    limit: 20,
  })

  const [input, updateInput] = useState({
    orderDirection: "DESC",
    orderField: "date",
  })

  const { data, refetch } = useQuery<{ list: { nodes: Transaction[], cursor: Cursor } }>(transactionListGql, {
    variables: {
      input,
      cursor,
    }
  })

  const transactionList = data?.list?.nodes
  const hasTransactions = !!(transactionList?.length && transactionList?.length > 0)
  return <div>
    <Modal display={focusAdd} onClose={() => updateFocusAdd(false)}>
      <TransactionImport onDone={() => {
        updateFocusAdd(false)
        refetch({
          input,
          cursor,
        })
      }} />
    </Modal>
    {/* <div className="card">
      <div className="card-header">
        Rechercher
      </div>
      <div className="card-body">
        <div className="input-group">
          <span className="input-group-text">Rechercher</span>
          <input type="text" className="form-control" />
        </div>
      </div>
    </div> */}



    <div className="card mt-2">
      <div className="card-header">
        Transactions
      </div>
      <div className="card-body">
        {hasTransactions && <table className="table table-bordered table-stripped">
          <thead>
            <tr className="bg-dark text-white">
              <th className="bg-dark text-white">Date</th>
              <th className="bg-dark text-white">Label</th>
              <th className="bg-dark text-white">Montant</th>
            </tr>
          </thead>
          <tbody>
            {transactionList?.map((transaction) => {

              return <tr key={`transaction_id_${transaction?.id}`}>
                <td style={{ verticalAlign: "middle" }}>
                  <DateFormat unixTimestamp={transaction?.date} short={true} />
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <TransactionListDisplay transactionId={transaction?.id} />
                </td>
                <td style={{ verticalAlign: "middle" }}><TransactionValueDisplay value={transaction.value || 0} /></td>
              </tr>
            })}
          </tbody>
        </table>}
        <div>
          <Pagination cursor={data?.list?.cursor} onChange={(page, limit) => {
            updateCursor({
              ...cursor,
              page,
              limit: Number(limit),
            })
          }} />
        </div>
      </div>
    </div>
    <div>
      <button className="btn btn-sm btn-dark ml-1" onClick={() => updateFocusAdd(true)}>Ajouter</button>
    </div>
  </div>

}
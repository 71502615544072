import React from "react"

import BootstrapCard from "react-bootstrap/Card"

export const Card = props => {
  const {title, children, footer, marginTop= 30 } = props
  return <BootstrapCard style={{marginTop}}>
    {title && <BootstrapCard.Header>{title}</BootstrapCard.Header>}
    <BootstrapCard.Body>{children && children}</BootstrapCard.Body>
    {footer && <BootstrapCard.Footer>{footer}</BootstrapCard.Footer>}
  </BootstrapCard>
}
import React from "react";
import {Link} from "react-router-dom";

import Card from "react-bootstrap/Card"


export class Count extends React.Component {

  render() {
    const {number, icon, link, lib, type} = this.props;
    return (
      <Card>
        <Card.Header>{lib}</Card.Header>
        <Card.Body>
          <Card.Title>
            <div style={{display: "flex"}}>
              {/* <i className={`fa fa-${icon} icon-${icon}`} ></i> */}
              <div style={{fontSize:38}}>{number}</div>
            </div>
          </Card.Title>
        </Card.Body>
        {link && <Card.Footer>
         <Link to={link}>Détails</Link>
        </Card.Footer>}
      </Card>
    )
  }

}



import { gql } from "@apollo/client";
import TagFragment from "graphql/BILLING/Tag/fragment/TagFragment";
// import TransactionFragment from "../../fragment/TransactionFragment";

export default gql`

  ${TagFragment}

  fragment TransactionTagFragment on TransactionTag {
    id
    tagId
    transactionId
    intermediate
    Tag {
      ...TagFragment
    }
  }

`
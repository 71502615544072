import {gql} from "@apollo/client";


export default gql`
fragment CursorFragment on Cursor {
  totalPages
  page
  limit
  hasMore
}
`
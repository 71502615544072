import { useMutation, useQuery } from "@apollo/client"
import { AutoCompleteInput } from "@zipou/front_tools"
import guestSearchGql from "graphql/WEDDING/Guest/query/guestSearch.gql"
import { Guest, GuestInput } from "model"
import { useState } from "react"

type GuestFormProps = {
  guest?: Guest,
  onChange: (guest?: Guest) => void,
}

export const GuestForm = (props: GuestFormProps) => {

  const { data, refetch } = useQuery<{ list: Guest[] }>(guestSearchGql, {
    skip: true,
  })

  const onSearch = (name: string) => {
    return refetch({
      name,
    })
      .then(response => {
        return response.data.list
      })
  }


  const onChange = (guest?: Guest) => {
    props?.onChange(guest)
  }

  return <div className="">
    <AutoCompleteInput<Guest>
      value={props?.guest}
      onSearch={(name) => onSearch(name)}
      onChange={v => onChange(v)}
      displayValue={v => v?.fullName || ""}
      disabled={false}
      isError={false}
      label="Votre nom"
    />

  </div>
}

/*

onSearch: (value: string) => Promise<any[]>
onChange: (value?: T) => void,
displayValue: (value?: T) => string| undefined,
displayResult?: (value: T) => string,
renderNoResult?: () => any,
minLength?: number,
label: string,
isError: boolean,
value: T | undefined,
disabled: boolean,
placeholder?: string, 


*/
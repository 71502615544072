import { useQuery } from "@apollo/client"
import { IconEdit, Modal } from "@zipou/front_tools"
import hostingConfigListGql from "graphql/HOSTING/Config/query/hostingConfigList.gql"
import { HostingConfig } from "model"
import { HostingConfigEdit } from "../Edit/HostingConfigEdit"
import { useState } from "react"
import { HostingConfigAdd } from "../Add/HostingConfigAdd"

type HostingConfigListProps = {

}

export const HostingConfigList = ({ }: HostingConfigListProps) => {

  const [focusEdit, updateFocusEdit] = useState<HostingConfig>()
  const [focusAdd, updateFocusAdd] = useState<boolean>(false)

  const { data, refetch } = useQuery<{ list: HostingConfig[] }>(hostingConfigListGql)

  const hostingConfigList = data?.list

  return <div>
    {!!focusAdd && <Modal display title="Ajouter" onClose={() => updateFocusAdd(false)}>
      <HostingConfigAdd onDone={() => {
        refetch()
        updateFocusAdd(false)
      }
      } />
    </Modal>}
    {!!focusEdit && <Modal display title="Editer" onClose={() => updateFocusEdit(undefined)}>
      <HostingConfigEdit id={focusEdit?.id} onDone={() => updateFocusEdit(undefined)} />
    </Modal>}
    <table className="table table-bordered table-striped">
      <thead>
        <tr>
          <td className="bg-dark text-white">Id</td>
          <td className="bg-dark text-white">Nom</td>
          <td className="bg-dark text-white">Server</td>
          <td className="bg-dark text-white">Actions</td>
        </tr>
      </thead>
      <tbody>
        {hostingConfigList?.map(config => {
          return <tr key={`hosting_config_${config?.id}`}>
            <td>{config?.id}</td>
            <td>{config?.name}</td>
            <td>
              <ul className="list-group">{config?.Server?.map(el => <li className="list-group-item">{el?.name}</li>)}
              </ul>
            </td>
            <td>
              <button className="btn btn-sm btn-warning" onClick={() => updateFocusEdit(config)}>
                <IconEdit />
              </button>
            </td>
          </tr>
        })}
      </tbody>
    </table>
    <div className="pt-2 pb-5">
      <button className="btn btn-sm btn-dark" onClick={() => updateFocusAdd(true)}>Ajouter</button>
    </div>
  </div>


}
import {gql} from '@apollo/client'
import UserLoginTokenFragment from 'graphql/APP/User/fragment/userLoginTokenFragment'

export default gql`

${UserLoginTokenFragment}

mutation userLoginByToken($id: ID!, $token: String!){
  userLoginByToken(input: {
    id: $id
    token: $token
  }) {
    ...UserLoginTokenFragment
  }
}
`
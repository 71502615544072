import { gql } from "@apollo/client";
import VideoConfigFragmentGql from "../fragment/VideoConfigFragment.gql";

export default gql`

  ${VideoConfigFragmentGql}

  query videoConfigList {
    list: videoConfigList {
      ...VideoConfigFragment
    }
  }

`
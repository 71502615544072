import { useState } from "react"
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ApolloProvider as ApolloHooksProvider } from "@apollo/client";
import { Routes, BrowserRouter, Route } from 'react-router-dom';

// import {HeaderBar} from "components/common/Navigation/HeaderBar";

import { URL_ROOT, URL_DEV, URL_HOSTING, URL_NETWORK, URL_PHONE, URL_CGU, URL_WEDDING, URL_WEDDING_PAGE } from 'static/constant/front/url'

import { MainRoute } from "./routes/front/MainRoute/MainRoute";
import { AuthWrapper } from "components/AuthWrapper";
import { CGURoute } from "./routes/front/MainRoute/CGURoute";
import { WeddingMainRoute } from "./routes/front/Wedding/MainRoute";


import { getClient } from "helpers/Apollo";

import "static/css/common.scss"
import { MainContainer } from "components/common/Container/Container";
import { HeaderBar as HeaderBo } from "components/backoffice/Navigation/HeaderBar/HeaderBar";
// import Breadcrumb from "react-bootstrap/esm/Breadcrumb";
import Container from "react-bootstrap/esm/Container";
import { UserListRoute } from "routes/backoffice/APP/User/UserListRoute";
// import { BillingRoute, BillingGraphRoute, InvoiceEditRoute, InvoiceDetailsRoute } from "routes/backoffice/BILLING";
import { CustomerListRoute } from "routes/backoffice/BILLING/Customer/CustomerListRoute";
import { CustomerViewRoute } from "routes/backoffice/BILLING/Customer/CustomerViewRoute";
import { InvoiceListRoute } from "routes/backoffice/BILLING/Invoice/InvoiceListRoute";
import { OrderListRoute } from "routes/backoffice/BILLING/Order/OrderListRoute";
import { OrderUpdateRoute } from "routes/backoffice/BILLING/Order/OrderUpdateRoute";
import { PaymentDetailsRoute } from "routes/backoffice/BILLING/Payment/PaymentDetailsRoute";
import { TagManagerRoute } from "routes/backoffice/BILLING/Tag/TagManagerRoute";
import { TinkLinkRoute } from "routes/backoffice/BILLING/Tink/TinkLinkRoute";
import { TransactionListRoute } from "routes/backoffice/BILLING/Transaction/TransactionListRoute";
import { CrmMainRoute } from "routes/backoffice/Crm/CrmMainRoute";
import GraphiqlRoute from "routes/backoffice/Graphql/GraphiqlRoute";
import { HostingTemplateEditRoute, HostingTemplateListRoute, HostingProvisioningActionRoute, HostingServerFirewallEditRoute, HostingServerDetailsRoute, HostingServerListRoute } from "routes/backoffice/HOSTING";
import { ClusterAddRoute } from "routes/backoffice/HOSTING/Cluster/Cluster/ClusterAddRoute";
import { ClusterEditRoute } from "routes/backoffice/HOSTING/Cluster/Cluster/ClusterEditRoute";
import { ClusterListRoute } from "routes/backoffice/HOSTING/Cluster/Cluster/ClusterListRoute";
import { ClusterProvisioningTaskListRoute } from "routes/backoffice/HOSTING/Cluster/ProvisioningTask/ClusterProvisioningTaskListRoute";
import { ClusterRoleListRoute } from "routes/backoffice/HOSTING/Cluster/Role/ClusterRoleListRoute";
import { ProfileRoute } from "routes/backoffice/ProfileRoute";
import { URL_BILLING_ROOT, URL_ITEM_ADD, URL_INVOICE_ADD, URL_QUOTE_ADD, URL_ORDER_ADD, URL_ORDER_LIST, URL_INVOICE_EDIT, URL_BILLING_INVOICE_LIST, URL_BILLING_CUSTOMER_LIST, URL_BILLING_CUSTOMER_VIEW, URL_BILLING_TINK_LINK, URL_BILLING_TRANSACTION_LIST, URL_BILLING_TAG_MANAGER, URL_BILLING_INVOICE_DETAILS_TEMPLATE, URL_BILLING_INVOICE_DETAILS_PDF_TEMPLATE, URL_HOSTING_TEMPLATE_ADD, URL_HOSTING_TEMPLATE_EDIT, URL_HOSTING_TEMPLATE_LIST, URL_HOSTING_SERVER_PROV_ACTION, URL_HOSTING_SERVER_FIREWALL_EDIT, URL_HOSTING_SERVER_DETAILS, URL_HOSTING_CLUSTER_ROLE_LIST, URL_HOSTING_CLUSTER_LIST, URL_HOSTING_CLUSTER_EDIT, URL_HOSTING_CLUSTER_ADD, URL_HOSTING_CLUSTER_PROVISIONING_TASK_LIST, URL_PHONE_ROOT, URL_ORDER_UPDATE_TEMPLATE, URL_ORDER_UPDATE_PAYMENT_PREF_TEMPLATE, URL_ORDER_UPDATE_PAYMENT_BANK_ACCOUNT_ADD_TEMPLATE, URL_ORDER_UPDATE_PAYMENT_CARD_ADD_TEMPLATE, URL_CRM_ROOT, URL_USER_LIST, URL_PAYMENT_DETAILS_TEMPLATE, URL_PAYMENT_DETAILS_3D_SECURE_TEMPLATE, URL_GRAPHIQL, URL_HOSTING_SERVER_LIST, URL_PHONE_DIRECTORY, URL_ITEM_LIST, URL_HOSTING_CONFIG_LIST, URL_PHONE_CONFIG_LIST, URL_PHONE_CONFIG, URL_PHONE_MODULE_DISPLAY, URL_VIDEO_CONFIG_LIST, URL_CAMERA_LIST } from "static/constant/backoffice/url";
import { BillingRoute } from "routes/backoffice/BILLING/BillingRoute";
import { InvoiceEditRoute } from "routes/backoffice/BILLING/Invoice/InvoiceEditRoute";
import { InvoiceDetailsRoute } from "routes/backoffice/BILLING/Invoice/InvoiceDetailsRoute";
import { Breadcrumb } from "components/common/Navigation/Breadcrumb/Breadcrumb";
import { PhoneDirectoryRoute, PhoneMainRoute } from "routes/backoffice/PHONE";
import { ItemListRoute } from "routes/backoffice/BILLING/Item/ItemListRoute";
import { HostingConfigListRoute } from "routes/backoffice/HOSTING/Config/HostingConfigListRoute";
import { PhoneConfigListRoute } from "routes/backoffice/PHONE/PhoneConfigListRoute";
import { PhoneConfigListByUserRoute } from "routes/backoffice/PHONE/PhoneConfigListByUserRoute";
import { PhoneConfigModuleDisplayRoute } from "routes/backoffice/PHONE/PhoneConfigModuleDisplayRoute";
import { VideoConfigListRoute } from "routes/backoffice/VIDEO/VideoConfigListRoute";
import { CameraListModuleDisplayRoute } from "routes/backoffice/VIDEO/CameraListRoute";
// import { WeitFormModule } from "@zipou/weit_form";

const App = () => {

  const apolloClient = getClient()

  return <div>
    <BrowserRouter>
      <ApolloHooksProvider client={apolloClient} >
        <GoogleOAuthProvider clientId="68826268714-d89s9uc71e0kcuralh1fmq1vbqdmr5sg.apps.googleusercontent.com" >
          <div>
            <Routes>
              <Route path="/manager/*" element={<AuthWrapper authType="REQUIRED">
                <MainContainer>
                  <HeaderBo />
                  <Container style={{ paddingTop: 30 }}>
                    <Breadcrumb />

                    <Routes>

                      <Route path={"/"} element={<ProfileRoute />} />
                      <Route path={"/profile"} element={<ProfileRoute />} />
                      <Route path={"/profile/impersonate"} element={<ProfileRoute displayImpersonate />} />

                      <Route path={URL_BILLING_ROOT} element={<BillingRoute />} />
                      <Route path={URL_ITEM_ADD} element={<BillingRoute />} />
                      <Route path={URL_INVOICE_ADD} element={<BillingRoute />} />
                      <Route path={URL_QUOTE_ADD} element={<BillingRoute />} />

                      <Route path={URL_ORDER_ADD} element={<BillingRoute />} />
                      <Route path={URL_ORDER_LIST} element={<OrderListRoute />} />
                      <Route path={URL_ITEM_LIST} element={<ItemListRoute />} />


                      <Route path={URL_INVOICE_EDIT} element={<InvoiceEditRoute />} />
                      <Route path={URL_BILLING_INVOICE_LIST} element={<InvoiceListRoute />} />

                      <Route path={URL_BILLING_CUSTOMER_LIST} element={<CustomerListRoute />} />
                      {/* <Route path={URL_BILLING_CUSTOMER_EDIT} element={<CustomerListRoute />} />
                        <Route path={URL_BILLING_CUSTOMER_ADD} element={<CustomerListRoute />} /> */}

                      <Route path={URL_BILLING_CUSTOMER_VIEW} element={<CustomerViewRoute />} />

                      <Route path={URL_BILLING_TINK_LINK} element={<TinkLinkRoute />} />

                      <Route path={URL_BILLING_TRANSACTION_LIST} element={<TransactionListRoute />} />
                      <Route path={URL_BILLING_TAG_MANAGER} element={<TagManagerRoute />} />

                      <Route path={URL_BILLING_INVOICE_DETAILS_TEMPLATE} element={<InvoiceDetailsRoute />} />
                      <Route path={URL_BILLING_INVOICE_DETAILS_PDF_TEMPLATE} element={<InvoiceDetailsRoute />} />

                      <Route path={URL_HOSTING_TEMPLATE_ADD} element={<HostingTemplateEditRoute />} />
                      <Route path={URL_HOSTING_TEMPLATE_EDIT} element={<HostingTemplateEditRoute />} />
                      <Route path={URL_HOSTING_TEMPLATE_LIST} element={<HostingTemplateListRoute />} />

                      <Route path={URL_HOSTING_SERVER_PROV_ACTION} element={<HostingProvisioningActionRoute />} />


                      <Route path={URL_HOSTING_CONFIG_LIST} element={<HostingConfigListRoute />} />
                      <Route path={URL_PHONE_CONFIG_LIST} element={<PhoneConfigListRoute />} />

                      <Route path={URL_VIDEO_CONFIG_LIST} element={<VideoConfigListRoute />} />

                      <Route path={URL_CAMERA_LIST} element={<CameraListModuleDisplayRoute />} />

                      <Route path={URL_HOSTING_SERVER_LIST} element={<HostingServerListRoute />} />
                      <Route path={URL_HOSTING_SERVER_FIREWALL_EDIT} element={<HostingServerFirewallEditRoute />} />
                      <Route path={URL_HOSTING_SERVER_DETAILS} element={<HostingServerDetailsRoute />} />


                      <Route path={URL_HOSTING_CLUSTER_ROLE_LIST} element={<ClusterRoleListRoute />} />
                      <Route path={URL_HOSTING_CLUSTER_LIST} element={<ClusterListRoute />} />
                      <Route path={URL_HOSTING_CLUSTER_EDIT} element={<ClusterEditRoute />} />
                      <Route path={URL_HOSTING_CLUSTER_ADD} element={<ClusterAddRoute />} />
                      <Route path={URL_HOSTING_CLUSTER_PROVISIONING_TASK_LIST} element={<ClusterProvisioningTaskListRoute />} />

                      <Route path={URL_PHONE_MODULE_DISPLAY} element={<PhoneConfigModuleDisplayRoute />} />
                      <Route path={URL_PHONE_ROOT} element={<PhoneMainRoute />} />
                      <Route path={URL_PHONE_DIRECTORY} element={<PhoneDirectoryRoute />} />
                      <Route path={URL_PHONE_CONFIG} element={<PhoneConfigListByUserRoute />} />


                      <Route path={URL_ORDER_UPDATE_TEMPLATE} element={<OrderUpdateRoute />} />
                      <Route path={URL_ORDER_UPDATE_PAYMENT_PREF_TEMPLATE} element={<OrderUpdateRoute />} />
                      <Route path={URL_ORDER_UPDATE_PAYMENT_BANK_ACCOUNT_ADD_TEMPLATE} element={<OrderUpdateRoute />} />
                      <Route path={URL_ORDER_UPDATE_PAYMENT_CARD_ADD_TEMPLATE} element={<OrderUpdateRoute />} />

                      <Route path={URL_CRM_ROOT} element={<CrmMainRoute />} />

                      <Route path={URL_USER_LIST} element={<UserListRoute />} />



                      <Route path={URL_PAYMENT_DETAILS_TEMPLATE} element={<PaymentDetailsRoute />} />
                      <Route path={URL_PAYMENT_DETAILS_3D_SECURE_TEMPLATE} element={<PaymentDetailsRoute />} />

                    </Routes>

                  </Container>
                  <Routes>
                    <Route path={URL_GRAPHIQL} element={<GraphiqlRoute />} />
                  </Routes>
                </MainContainer>
              </AuthWrapper>} />

              {/* <Route path={URL_WEIT} element={<WeitFormModule url={"https://www.box4b.fr/graphql"} />} /> */}

              <Route path={URL_WEDDING} element={<AuthWrapper authType={"OPTIONAL"}><WeddingMainRoute /></AuthWrapper>} />
              <Route path={URL_WEDDING_PAGE} element={<AuthWrapper authType={"OPTIONAL"}><WeddingMainRoute /></AuthWrapper>} />
              <Route path={URL_DEV} element={<AuthWrapper authType={"OPTIONAL"}><MainRoute /></AuthWrapper>} />
              <Route path={URL_HOSTING} element={<AuthWrapper authType={"OPTIONAL"}><MainRoute /></AuthWrapper>} />
              <Route path={URL_NETWORK} element={<AuthWrapper authType={"OPTIONAL"}><MainRoute /></AuthWrapper>} />
              <Route path={URL_PHONE} element={<AuthWrapper authType={"OPTIONAL"}><MainRoute /></AuthWrapper>} />
              <Route path={URL_CGU} element={<AuthWrapper authType={"OPTIONAL"}><CGURoute /></AuthWrapper>} />
              <Route path={URL_ROOT} element={<AuthWrapper authType={"OPTIONAL"}><MainRoute /></AuthWrapper>} />
            </Routes>

          </div>
        </GoogleOAuthProvider>
      </ApolloHooksProvider>
    </BrowserRouter>
  </div >
}



export default App;

import { BooleanInput } from "@zipou/front_tools"
import { ClusterRole, ClusterRoleTypeEnum } from "model"
import { EnvVarForm } from "../../../EnvVar/Form/EnvVarForm"
// import {EnvVarForm} from "../../EnvVar/Form/EnvVarForm"

type ClusterRoleFormProps = {
  clusterRole: ClusterRole,
  errors?: any,
  onChange: (clusterForm: any) => void,
}

export const ClusterRoleForm = (props: ClusterRoleFormProps) => {

  const clusterRole = props?.clusterRole

  const onChange = (clusterRole: any) => {
    props?.onChange(clusterRole)
  }

  const onEnvAdd = () => {
    props?.onChange({
      ...clusterRole,
      envVarList: [
        ...clusterRole?.envVarList,
        {
          id: new Date().getTime(),
          name: "",
          value: "",
        }
      ]
    })
  }

  const onEnvDelete = (id: string) => {
    props?.onChange({
      ...clusterRole,
      envVarList: clusterRole?.envVarList?.filter((el: any) => el.id !== id)
    })
  }

  const clusterRoleTypeList = Object.values(ClusterRoleTypeEnum)

  return <div className="cluster-role-form-container">
    <div className="input-group">
      <span className="input-group-text">Nom</span>
      <input className="form-control" type="text" value={clusterRole?.name} onChange={(e: any) => {
        onChange({
          ...clusterRole,
          name: e.target.value,
        })
      }} />
    </div>
    <div className="input-group">
      <span className="input-group-text">Type</span>
      <select className="form-control" value={clusterRole?.type} onChange={(e: any) => {
        onChange({
          ...clusterRole,
          type: e.target.value as ClusterRoleTypeEnum,
        })
      }}>
        <option value="" disabled>Choisissez un type</option>
        {clusterRoleTypeList?.map((clusterRoleType: string) => <option key={`clusterRole_${clusterRoleType}`} value={clusterRoleType}>{clusterRoleType}</option>)}
      </select>
    </div>
    <div className="input-group">
      <span className="input-group-text">Image</span>
      <input className="form-control" type="text" value={clusterRole?.image} onChange={(e: any) => {
        onChange({
          ...clusterRole,
          image: e.target.value,
        })
      }} />
    </div>
    <div className="input-group">
      <span className="input-group-text">Tag</span>
      <input className="form-control" type="text" value={clusterRole?.tag || ""} onChange={(e: any) => {
        onChange({
          ...clusterRole,
          tag: e.target.value,
        })
      }} />
    </div>
    <div className="input-group">
      <span className="input-group-text">Version</span>
      <input className="form-control" type="text" value={clusterRole?.version || ""} onChange={(e: any) => {
        onChange({
          ...clusterRole,
          version: Number(e.target.value),
        })
      }} />
    </div>
    <BooleanInput errors={props?.errors} label={"LoadBalancerClient ?"} value={clusterRole?.isLoadBalancerClient === true} onChange={(v) => {
      onChange({
        ...clusterRole,
        isLoadBalancerClient: v,
      })
    }} />
    <div className="input-group">
      <span className="input-group-text">Env</span>
      <div className="form-control" style={{ height: "100%" }}>
        {clusterRole?.envVarList?.map((envVar: any) => {
          return <div className="input-group">
            <div className="form-control" style={{ height: "100%" }}>
              <EnvVarForm key={`envVar_${envVar?.id}`} envVar={envVar} onChange={(value) => {
                onChange({
                  ...clusterRole,
                  envVarList: clusterRole?.envVarList?.map((el: any) => {
                    if (el.id === envVar.id) {
                      return value
                    } else {
                      return el
                    }
                  })
                })
              }} />
            </div>
            <span className="input-group-text" onClick={() => onEnvDelete(envVar?.id)}><span className="icon-trash"></span></span>
          </div>
        })}
        <div className="actions">
          <button className="btn btn-sm btn-secondary" onClick={onEnvAdd}>Ajouter</button>
        </div>
      </div>
    </div>
  </div>

}
import React from "react"
import {Vars} from "libs/Vars"
import {findToken} from 'helpers/User'

// import {InputAddon} from "components/common/Form/Form"

import './style.scss'

export class ProvisioningAction extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      log: [],
      eventSourceOpen: false,
      template: "",
    }
    this._initSSE()
  }

  componentWillUnmount(){
    if (this.eventSource) {
      // console.log("Closing Event Source")
      this.eventSource.close()
    }
  }

  _initSSE() {
    const token = findToken();
    const url = `${Vars["api_enpoint"]}/stream?access_token=${token}`
    this.eventSource = new EventSource(url)
    const setState = this.setState.bind(this)

    this.eventSource.onmessage = (event) => {
      const {log} = this.state
      const {data} = event
      const dataParsed = data.replace(/"/g, '').split("\\n").reverse()

      setState({
        log: [...dataParsed, ...log],
        // log: [data, ...log],
      })
    };

    this.eventSource.onopen = () => {
      // console.log("OPEN")
      setState({
        eventSourceOpen: true
      })
    };
  }

  _handleTemplateChange(e) {
    const value = e.target.value
    this.setState({
      template: value,
    })
  }

  _handleFormSubmit(e) {
    e.preventDefault();
    const {launchAnsible} = this.props
    const {template} = this.state
    launchAnsible && launchAnsible(template)
  }

  render() {
    const {log, eventSourceOpen, template} = this.state
    const {templateList} = this.props
    return <div className="hosting-provisioning-action-container">
      <h1>Provisioning</h1>
      <h2>Template</h2>
      <form onSubmit={this._handleFormSubmit.bind(this)} className="provisioning-action-form">
        <div className="form-row input-group">
          <div className="input-group-text">Action</div>
          <select className="form-control" name="template" value={template} onChange={this._handleTemplateChange.bind(this)}>
            <option value="" disabled>Choisissez une valeur</option>
            {templateList && templateList.map(template => {
              const {id, name} = template
              return <option key={`template_key_${id}`} value={id}>{name}</option>
            })}
          </select>
        </div>
        <button type="submit"><span className="loading-spin"> </span>Launch Provisioning</button>
      </form>
      <h2>Logs {eventSourceOpen ? "connecté" : "déconnecté"}</h2>
      <div ref={ref => this.logContainer = ref} className="log-container">
        {log.map((logLine, i) => {
          return <div key={i}>{logLine}</div>
        })}
      </div>
    </div>
  }
}
import { useMutation, useQuery } from "@apollo/client"
import userUpdate from "graphql/APP/User/mutation/userUpdate"
import userGet from "graphql/APP/User/query/userGet"
import { User, UserInput } from "model"
import { useState } from "react"
import { UserForm } from "../Form/UserForm"

type UserEditProps = {
  id: string,
  onDone?: () => void,
}

export const UserEdit = ({ id, onDone }: UserEditProps) => {

  const [user, updateUser] = useState<UserInput>()

  const [mutate] = useMutation<{ user: User }, { id: string, input?: UserInput }>(userUpdate)

  const { data } = useQuery<{ user: User }>(userGet, {
    variables: {
      id
    },
    onCompleted: (data) => {
      const { id, ...rest } = data?.user
      updateUser(rest)
    }
  })

  const onSave = () => {
    mutate({
      variables: {
        id,
        input: user,
      }
    })
      .then(() => {
        console.log("OK")
        onDone && onDone()
      })
      .catch((e) => console.log("ERROR", e))
  }

  return <div>
    <UserForm user={user as User} onChange={user => updateUser(user)} />
    <div>
      <button className="btn btn-dark btn-sm" onClick={() => onSave()}>Enregister</button>
    </div>
  </div>

}
import {gql} from "@apollo/client";

export default gql`
  fragment InvoiceDetailsFragment on Invoice {
    id
    publicId
    status
    date
    totalHT
    totalTTC
    Payment {
      id
      type
      value
      date
      comment
      status
      stripeClientSecret
    }
  }
`
import React, { useState } from "react"
import { UserDetails } from "../Details/UserDetails"
import { User } from "model"
import { IconEdit, Modal } from "@zipou/front_tools"
import { UserEdit } from "../Edit/UserEdit"

type UserListProps = {
  userList: User[]
}

export const UserList = (props: UserListProps) => {
  const { userList } = props

  const [focusEdit, updateFocusEdit] = useState<User>()
  const [focusAdd, updateFocusAdd] = useState<boolean>(false)

  return <div className="user-list-container">
    <h1>Liste des utilisateurs</h1>
    {focusEdit && <Modal display title="Editer" onClose={() => updateFocusEdit(undefined)} >
      <UserEdit id={focusEdit?.id} onDone={() => updateFocusEdit(undefined)} />
    </Modal>}
    <div>
      <table className="table table-striped table-bordered">
        <thead>
          <tr className="bg-dark text-white">
            <td className="bg-dark text-white">Email</td>
            <td className="bg-dark text-white">Actions</td>
          </tr>
        </thead>
        <tbody>
          {userList?.map(user => <tr key={`user_list_${user?.id}`}>
            <td>
              {user?.email}
            </td>
            <td>
              <button className="btn btn-warning btn-sm" onClick={() => updateFocusEdit(user)}>
                <IconEdit />
              </button>
            </td>
          </tr>)}
        </tbody>
      </table>
    </div>
  </div>
}
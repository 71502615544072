import {gql} from "@apollo/client"
import userProfileFragment from "graphql/APP/User/fragment/userProfileFragment"


export default gql`
  ${userProfileFragment}

  query profile {
    userProfile {
      ...UserProfileFragment
    }
  }

`
import ClusterRoleFragment from "../fragment/ClusterRoleFragment";
import gql from "graphql-tag";

export default gql`

  ${ClusterRoleFragment}

  mutation clusterRoleUpdate($id: String!, $input: ClusterRoleInput!) {
    clusterRole: clusterRoleUpdate(id: $id, input: $input) {
      ...ClusterRoleFragment
    }
  }

`
import React from "react"
import {Link} from "react-router-dom"

import {Card} from "components/common/Card/Card"
import {PaymentStatus} from "components/backoffice/BILLING/Payment/PaymentStatus"
import {CurrencyFormat} from "components/common/Format/CurrencyFormat"
import {DateFormat} from "components/common/Format/DateFormat"


import {URL_PAYMENT_DETAILS_TEMPLATE} from "static/constant/backoffice/url"

export const PaymentList = (props) => {
  const {paymentList} = props
  return <Card title="Payment">
    <table className="table table-bordered  ">
      <thead>
        <tr>
          <th>Date</th>
          <th>Montant</th>
          <th>Type</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {paymentList && paymentList.map(payment => {
          const {id, value, status, date, type} = payment || {}
          return <tr key={`payment_${id}`}>
            <td><DateFormat unixTimestamp={date} /></td>
            <td><CurrencyFormat value={value} /></td>
            <td>{type}</td>
            <td><PaymentStatus status={status} /></td>
            <td><Link to={URL_PAYMENT_DETAILS_TEMPLATE.replace(":paymentId", id)}><button className="btn btn-dark">Détails</button></Link></td>
          </tr>
        })}
      </tbody>
    </table>
  </Card>
}
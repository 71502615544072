import { BooleanInput } from "@zipou/front_tools"

type AsteriskFormProps = {
  onChange: (datas: any) => void,
  config: any,
  errors: any,
}

export const AsteriskForm = ({ onChange, config, errors }: AsteriskFormProps) => {

  const { isMaster } = config || {}

  return <div>
    <BooleanInput value={isMaster} id="isMaster" errors={errors} label="IsMaster ?" onChange={(v) => {
      onChange({
        ...config,
        isMaster: v
      })
    }} />
  </div>
}
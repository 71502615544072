import { gql } from "@apollo/client";
import TagFragment from "../fragment/TagFragment";

export default gql`

  ${TagFragment}

  mutation tagMove($id: String!, $parentId: String!) {
    tag: tagMove(id: $id, parentId: $parentId) {
      ...TagFragment
    }
  }

`
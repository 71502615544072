import { useState } from "react"
import { ContactForm } from "../Form/ContactForm"
import { Contact, ContactInput } from "model"
import { useMutation, useQuery } from "@apollo/client"
import contactGetGql from "graphql/BILLING/Contact/query/contactGet.gql"
import contactUpdateGql from "graphql/BILLING/Contact/mutation/contactUpdate.gql"
import { formatErrorResponseForJoi } from "helpers/Apollo"

type ContactEditProps = {
  id: string,
  onDone: () => void,
}

export const ContactEdit = ({ id, onDone }: ContactEditProps) => {

  const [contact, updateContact] = useState<ContactInput>({})
  const [errors, updateErrors] = useState<any>()

  const { data } = useQuery<{ contact: Contact }>(contactGetGql, {
    variables: {
      id,
    },
    onCompleted: (response) => {
      const contact = response?.contact
      updateContact({
        id: contact?.id,
        email: contact?.email,
        firstname: contact?.firstname,
        lastname: contact?.lastname,
        type: contact?.type,
        title: contact?.title
      })
    }
  })

  const [mutate] = useMutation(contactUpdateGql)

  const onSave = () => {
    mutate({
      variables: {
        input: contact,
        id,
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch(e => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  const onChange = (contact: ContactInput) => {
    updateContact(contact)
  }

  const onReset = () => {
    const contact = data?.contact
    updateContact({
      id: contact?.id,
      email: contact?.email,
      firstname: contact?.firstname,
      lastname: contact?.lastname,
      type: contact?.type,
      title: contact?.title
    })
  }


  return <div>
    <ContactForm errors={errors} contact={contact} onChange={onChange} />
    <div>
      <button className="btn btn-sm btn-dark ml-1" onClick={() => onSave()}>Enregistrer</button>
      <button className="btn btn-sm btn-danger ml-1" onClick={() => onReset()}>Annuler</button>
    </div>
  </div>
}
import { gql } from "@apollo/client";
import HostingConfigFragmentGql from "../fragment/HostingConfigFragment.gql";

export default gql`

  ${HostingConfigFragmentGql}

  mutation hostingConfigAdd($input: HostingConfigInput!) {
    hostingConfig: hostingConfigAdd(input: $input) {
      ...HostingConfigFragment
    }
  }

`
import React from "react"
import clusterRoleList from "graphql/HOSTING/Cluster/ClusterRole/query/clusterRoleList"
import {useQuery} from "@apollo/client"
import {ClusterRoleList} from "components/backoffice/HOSTING/Cluster/Cluster/Role/List/ClusterRoleList"


export const ClusterRoleListRoute = () => {

  const {data} = useQuery(clusterRoleList)

  return <div>
    <h1> Cluster Role List</h1>
    <ClusterRoleList clusterRoleList={data?.clusterRoleList} />
  </div>
}


import { gql } from "@apollo/client";
import TagFragment from "../fragment/TagFragment";

export default gql`

  ${TagFragment}

  query tagGet($id: String!) {
    tag: tagGet(id: $id) {
      ...TagFragment
    }
  }

`
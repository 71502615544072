import { CurrencyFormat } from "components/common/Format/CurrencyFormat"

type TransactionValueDisplayProps = {
  value: number,
}



export const TransactionValueDisplay = (props: TransactionValueDisplayProps) => {

  const value = props?.value

  if (value <= 0) {
    return <span className="badge badge-info">
      <CurrencyFormat value={value} withDecimal />
    </span>
  } else {
    return <span className="badge badge-success">
      <CurrencyFormat value={value} withDecimal />
    </span>

  }


}
import {InvoiceList} from "components/backoffice/BILLING/Invoice/List/InvoiceList"

export const InvoiceListRoute = (props: any) => {

  return <div className="card">
    <div className="card-header">
      Liste des Factures
    </div>
    <div className="card-body">
      <InvoiceList />
    </div>
  </div>



}
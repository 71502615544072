import gql from "graphql-tag";

export default gql`
  fragment ServerListFragment on Server {
    id
    token
    name
    type
    lastseen
    lastBackup
    status
    provStatus
    ip
    version
  }
`
import {gql} from "@apollo/client";
import CustomerEditFragment from "../fragment/CustomerEditFragment";

export default gql`

  ${CustomerEditFragment}

  mutation customerUpdate($id: String!, $input: CustomerInput!) {
    customer: customerUpdate(id: $id, input: $input) {
      ...CustomerEditFragment
    }
  }

`
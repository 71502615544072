import gql from "graphql-tag";
import AddressFragmentGql from "graphql/BILLING/Address/fragment/AddressFragment.gql";
import CompanyStripeFragmentGql from "graphql/BILLING/Company/fragment/CompanyStripeFragment.gql";
import ContactEditFragment from "graphql/BILLING/Contact/fragment/ContactEditFragment";
import InvoiceFragment from "graphql/BILLING/Invoice/fragment/InvoiceFragment";
import OrderFragment from "graphql/BILLING/Order/fragment/OrderFragment";
import PaymentPrefFragment from "graphql/BILLING/PaymentPref/fragment/PaymentPrefFragment";

export default gql`

  ${AddressFragmentGql}
  ${ContactEditFragment}
  ${PaymentPrefFragment}
  ${CompanyStripeFragmentGql}
  ${InvoiceFragment}
  ${OrderFragment}

  fragment CustomerViewFragment on Customer {
    id
    initials
    name
    Order {
      ...OrderFragment
    }
    Invoice {
      ...InvoiceFragment
    }
    Company {
      ...CompanyStripeFragment
    }
    PaymentPref {
      ...PaymentPrefFragment
    }
    Address {
      ...AddressFragment
    }
    Contact {
      ...ContactEditFragment
    }
  }
`
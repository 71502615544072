import gql from "graphql-tag";
import ClusterRoleFragment from "graphql/HOSTING/Cluster/ClusterRole/fragment/ClusterRoleFragment";


export default gql`

  ${ClusterRoleFragment}

  fragment ClusterConfigFragment on ClusterConfig {
    id
    name
    serverId
    clusterId
    Cluster {
      id
    }
    Server {
      id
      name
    }
    ClusterRoleConfig {
      id
      clusterRoleId
      ClusterRole {
        ...ClusterRoleFragment
      }
      name
      port
      type
      version
      status
      options
    }
  }
`
import {gql} from '@apollo/client'
import BankAccountFragment from '../fragment/BankAccountFragment'

export default gql`

  ${BankAccountFragment}

  query bankAccountListByCustomerId($customerId: String!) {
    bankAccount: bankAccountListByCustomerId(customerId: $customerId) {
      ...BankAccountFragment
    }
  }
`
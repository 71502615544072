import { TextInput } from "@zipou/front_tools"
import { PhoneConfigInput } from "model"

type PhoneConfigFormProps = {
  phoneConfigInput: PhoneConfigInput,
  onChange: (phoneConfigInput: PhoneConfigInput) => void,
  errors: any,
}

export const PhoneConfigForm = ({ phoneConfigInput, onChange, errors }: PhoneConfigFormProps) => {

  return <>
    <TextInput id="name" errors={errors} label="Nom" value={phoneConfigInput?.name || ""} onChange={(v) => {
      onChange({
        ...phoneConfigInput,
        name: v
      })
    }} />
  </>

}
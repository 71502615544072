
import {useQuery} from "@apollo/client"
import {UserList} from "components/backoffice/APP/User/List/UserList"

import userListQuery from "graphql/APP/User/query/userList"

export const UserListRoute = () => {
  const {data} = useQuery(userListQuery)
  const userList = data?.userList && [...data?.userList]?.sort((el1: any, el2: any) => el1.email.localeCompare(el2.email))

return <UserList userList={userList} />

}

import React, { useState } from "react"

import orderAddMutation from "graphql/BILLING/Order/mutation/orderAdd"
import notificationListQuery from "graphql/Notification/query/notificationList"
import { useMutation } from "@apollo/client"
import { OrderForm } from "../Form/OrderForm"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import { OrderInput, OrderLineInput } from "model"

type OrderAddProps = {
  onDone?: () => void
}

export const OrderAdd = (props: OrderAddProps) => {

  const [errors, updateErrors] = useState<any>({})

  const [orderAddMutate] = useMutation(orderAddMutation, {
    refetchQueries: [
      {
        query: notificationListQuery,
      },
    ]
  })

  const [order, updateOrder] = useState<OrderInput | null>(null)

  const _handleSubmit = (event: any) => {
    event.preventDefault()
    orderAddMutate({
      variables: {
        input: {
          ...order,
          dateStart: order?.dateStart,
          dateEnd: order?.dateEnd,
          billingDayOfMonth: order?.billingDayOfMonth,
          OrderLine: order?.OrderLine?.map((orderLine: OrderLineInput) => {
            const { id, quantity, dateStart, dateEnd, itemId } = orderLine
            return {
              id,
              quantity: quantity && Number(quantity),
              dateStart: (dateStart !== "") ? dateStart : null,
              dateEnd: (dateEnd !== "") ? dateEnd : null,
              itemId,
            }
          })
        }
      }
    })
      .then(() => {
        props?.onDone && props?.onDone()
      })
      .catch((e: any) => {
        updateErrors(formatErrorResponseForJoi(e))
        console.log("error", e)
      })
  }

  console.log("errors", errors)

  return (
    <div className="order-edit-container">
      <form onSubmit={_handleSubmit}>
        <OrderForm order={order || {}} errors={errors} onChange={(order: OrderInput) => {
          updateOrder(order)
        }} />
        <div className="footer">
          <button className="btn btn-sm btn-dark" type="submit">Enregistrer</button>
        </div>
      </form>
    </div>
  )

}
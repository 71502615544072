import { gql } from "@apollo/client"
import PhoneConfigTokenFragment from "../fragment/PhoneConfigTokenFragment"

export default gql`

  ${PhoneConfigTokenFragment}

  query phoneConfigGetToken {
    phoneConfig: phoneConfigGet {
      ...PhoneConfigTokenFragment
    }
  }
`
import React, { useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client"

import { userContext } from "../context/User"


import { LoginForm } from "../components/backoffice/APP/User/Login/LoginForm";
import userProfileQuery from 'graphql/APP/User/query/userProfile'
import loginMutation from "graphql/APP/User/mutation/userLogin";

import { handleLoginByToken } from "../helpers/User"
import { saveLoginCookie } from "libs/Cookie";
import { Modal } from "@zipou/front_tools";

type AuthWrapperProps = {
  authType: "OPTIONAL" | "REQUIRED",
  // Component: any,
  children: any,
}

export const AuthWrapper = (props: AuthWrapperProps) => {

  // const { authType, Component, ...routerProps } = props
  const { authType, children, ...routerProps } = props

  const { loading, data, refetch } = useQuery(userProfileQuery, {
    notifyOnNetworkStatusChange: true,
    // fetchPolicy: "cache-and-network",
  });

  const [loginMutate] = useMutation(loginMutation);

  const _handleLogin = async (email: string, password: string) => {
    const response = await loginMutate({
      variables: {
        email,
        password,
      },
    })
    const { data } = response || {};
    const { userLogin } = data || {};
    const { token, expiration } = userLogin || {};
    console.log("Saving Cookie")
    saveLoginCookie({
      token,
      expiration
    })
    console.log("Refetching profile")
    refetch()
  }

  const { userProfile } = data || {}

  // console.log({ userProfile })

  const isAuthenticated = !!userProfile

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id')
  const token = urlParams.get('token')

  useEffect(() => {
    if (token && id) {
      handleLoginByToken({
        id,
        token
      })
    }
  }, [])

  switch (authType) {

    case "REQUIRED": {
      return (isAuthenticated) ? <userContext.Provider value={userProfile}>
        {/* <Component {...routerProps} /> */}
        {children}
      </userContext.Provider> : <div>
        <Modal display={loading}>
          <span className="icon-spinner" style={{ fontSize: 72 }}></span>
        </Modal>
        <Modal display={!loading} title="Connexion">
          <LoginForm onLogin={_handleLogin} />
        </Modal>
        {children}
        {/* <Component
          {...routerProps}
        /> */}
      </div>
    }

    case "OPTIONAL": {
      return <userContext.Provider value={userProfile}>
        {children}
        {/* <Component
          {...routerProps}
        /> */}
      </userContext.Provider>

    }

    default: {
      return <Modal display={true} title="Connexion">
        <LoginForm onLogin={_handleLogin} />
      </Modal>
    }
  }
}
import React from "react"
import { DateTime } from "libs/DateTime"

type DateFormatProps = {
  unixTimestamp: number,
  short?: boolean,
}


export const DateFormat = (props: DateFormatProps) => {
  const { unixTimestamp, short = false } = props
  return <span>{unixTimestamp && short ?
    new Date(unixTimestamp * 1000).toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "numeric",
      year: "2-digit"
    })
    :
    new DateTime(unixTimestamp).getString() || ""}
  </span>
}
import {gql} from "@apollo/client"
import BankAccountFragment from "../../BankAccount/fragment/BankAccountFragment"
import CardFragment from "../../Card/fragment/CardFragment"

export default gql`

  ${BankAccountFragment}
  ${CardFragment}

  fragment PaymentPrefFragment on PaymentPref {
    id
    type
    Card {
      ...CardFragment
    }
    BankAccount {
      ...BankAccountFragment
    }
  }

`
import {BooleanInput} from "@zipou/front_tools";
import React from "react"

type IpsecConfigFormProps = {
  onChange: (datas: any) => void,
  config: any,
}

export const IpsecConfigForm = (props: IpsecConfigFormProps) => {

  const {config, onChange} = props;
  const {type = "", rightId, leftId, authType, psk = "", subnet = "", enableDPD} = config || {}

  const isAuthPsk = authType === "psk"
  const isTypeSite2Site = type === "site2site"


  return <div className="ipsec-config-container">
    <div className="input-group">
      <span className="input-group-text">Left ID</span>
      <input type="text" name="leftId" className="form-control" value={leftId || ""} onChange={(e) => {
        onChange({
          ...config,
          leftId: e.target.value,
        })
      }} />
    </div>
    <div className="input-group">
      <span className="input-group-text">Right ID</span>
      <input type="text" name="rightId" className="form-control" value={rightId || ""} onChange={(e) => {
        onChange({
          ...config,
          rightId: e.target.value,
        })
      }} />
    </div>
    <div className="input-group">
      <span className="input-group-text">Type</span>
      <select className="form-control" value={type || ""} onChange={(e) => {
        onChange({
          ...config,
          type: e.target.value,
        })
      }}>
        <option value="" disabled>Choisissez un type de VPN</option>
        <option value="site2site">Site to site</option>
        <option value="client">NAT Client</option>
      </select>
    </div>
    {isTypeSite2Site && <div className="input-group">
      <span className="input-group-text">Sous Réseau</span>
      <input type="text" className="form-control" value={subnet || ""} onChange={(e: any) => {
        onChange({
          ...config,
          subnet: e.target.value,
        })
      }} />
    </div>}
    {isTypeSite2Site && <BooleanInput value={enableDPD} label="Enable Dpd" errors={{}} onChange={(v: boolean) => {
      onChange({
        ...config,
        enableDPD: v,
      })
    }} />}
    <div className="input-group">
      <span className="input-group-text">Authentification</span>
      <select className="form-control" value={authType || ""} onChange={(e) => {
        onChange({
          ...config,
          authType: e.target.value,
        })
      }}>
        <option value="" disabled>Choisissez un type d'authentification'</option>
        <option value="psk">Clé partagée</option>
      </select>
    </div>
    {isAuthPsk && <div className="input-group">
      <span className="input-group-text">Clé partagée</span>
      <input type="text" className="form-control" value={psk || ""} onChange={(e: any) => {
        onChange({
          ...config,
          psk: e.target.value,
        })
      }} />
    </div>}
  </div>
}
import {gql} from "@apollo/client";

export default gql`

fragment RibFragment on Rib {

  iban
  bic
  
}
`
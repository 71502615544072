import {TextInput} from "@zipou/front_tools"

type AddressFormProps = {
  address: any,
  errors?: any,
  onChange: (data: any) => void,

}

export const AddressForm = (props: AddressFormProps) => {

  const address = props?.address
  const errors = props?.errors

  const onChange = (datas: any) => {
    props?.onChange(datas)
  }

  return <div>
    <TextInput id={"address1"} label="Adresse 1" errors={errors} value={address?.address1 || ""} onChange={(v: string) => {
      onChange({
        ...address,
        address1: v,
      })
    }} />
    <TextInput id={"address2"} label="Adresse 2" errors={errors} value={address?.address2 || ""} onChange={(v: string) => {
      onChange({
        ...address,
        address2: v,
      })
    }} />
    <TextInput id={"zip"} label="CP" errors={errors} value={address?.zip || ""} onChange={(v: string) => {
      onChange({
        ...address,
        zip: v,
      })
    }} />
    <TextInput id={"town"} label="Ville" errors={errors} value={address?.town || ""} onChange={(v: string) => {
      onChange({
        ...address,
        town: v,
      })
    }} />
  </div>
}
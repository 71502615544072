import { OrderStatusEnum } from "model"

type OrderStatusProps = {
  status: OrderStatusEnum
}

export const OrderStatus = ({ status }: OrderStatusProps) => {
  switch (status) {
    case OrderStatusEnum.STATUS_ACTIVE: {
      return <div className="badge badge-success">Actif</div>
    }
    case OrderStatusEnum.STATUS_DONE: {
      return <div className="badge badge-dark">Terminé</div>
    }
    case OrderStatusEnum.STATUS_DRAFT: {
      return <div className="badge badge-warning">Brouillon</div>
    }
    case OrderStatusEnum.STATUS_WAITING_VALIDATION: {
      return <div className="badge badge-secondary">En attente</div>
    }
    case OrderStatusEnum.STATUS_DELETED: {
      return <div className="badge badge-danger">Supprimé</div>
    }
    default:
      return <div></div>
  }


}
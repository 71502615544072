import { gql } from "@apollo/client"
import PhoneConfigFragment from "../fragment/PhoneConfigFragment"

//  ${PhoneConfigFragment}

export default gql`


  query phoneConfigGet($id: String!) {
    phoneConfig: phoneConfigGet(id: $id) {
      id
      name
    }
  }
`
import React from "react"
import {CGU} from "components/front/CGU"
import {HeaderBar} from "components/front/Navigation/HeaderBar/HeaderBar"
import "./style.scss"
export const CGURoute = () => {
  return <div>
    <div className="main-route-container" style={{textAlign: "left"}}>
      <HeaderBar />
    </div>
    <div style={{paddingTop: 130}}>
      <div className="container">
        <CGU />
      </div>
    </div>
  </div>
}
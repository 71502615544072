import { PhoneConfigListByUser } from "components/backoffice/PHONE/Config/List/PhoneConfigListByUser"
import { Breadcrumb } from "react-bootstrap"

export const PhoneConfigListByUserRoute = () => {


  return <div className="phone-config-list-route-by-user">

    <Breadcrumb>
      <Breadcrumb.Item active>Téléphonie</Breadcrumb.Item>
      <Breadcrumb.Item active>Liste des Configs</Breadcrumb.Item>
    </Breadcrumb>


    <PhoneConfigListByUser />
  </div>
}
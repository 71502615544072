import gql from "graphql-tag";

export default gql`
  fragment FirewallFragment on Firewall {
    id
    Rules {
      id
      position
      from
      type
      port
      portRangeStart
      portRangeEnd
      action
      desc
    }
  }
`
import { TextInput, Price, DateInput, NumberInput } from "@zipou/front_tools";
import { InvoiceLineInput } from "model";

type InvoiceLineFormProps = {
  errors?: any,
  invoiceLine: InvoiceLineInput,
  onChange: (invoiceLine: InvoiceLineInput) => void,
}


export const InvoiceLineForm = ({ invoiceLine, errors, onChange }: InvoiceLineFormProps) => {

  const { quantity, vat, priceUnit, label, dateStart, dateEnd } = invoiceLine


  console.log("invoiceLine", invoiceLine)

  return (
    <div>
      <TextInput id="label" label={"Label"} value={label || ""} errors={errors} onChange={v => {
        onChange({
          ...invoiceLine,
          label: v,
        })
      }} />
      <NumberInput id="quantity" label={"Quantité"} value={`${quantity}` || ""} errors={errors} onChange={(_, v) => {
        onChange({
          ...invoiceLine,
          quantity: v,
        })
      }} />
      <div className="input-group">
        <span className="input-group-text">Prix</span>
        <div className="form-control" style={{ height: "100%" }}>
          <Price errors={errors} id="priceUnit" label="Prix" value={priceUnit || 0} onChange={(value: number) => {
            onChange({
              ...invoiceLine,
              priceUnit: value,
            })
          }} />
          <TextInput id="vat" label={"TVA"} value={`${vat}` || ""} errors={errors} onChange={v => {
            onChange({
              ...invoiceLine,
              vat: Number(v),
            })
          }} />

        </div>
      </div>
      <div className="input-group">
        <span className="input-group-text">Date</span>
        <div className="form-control" style={{ height: "100%" }}>
          <DateInput id="dateStart" errors={errors} label="Date Début" value={dateStart * 1000 || 0} onChange={(v: number) => onChange({
            ...invoiceLine,
            dateStart: Math.round(v / 1000),
          })} />
          <DateInput id="dateEnd" errors={errors} label="Date Fin" value={dateEnd * 1000 || 0} onChange={(v: number) => onChange({
            ...invoiceLine,
            dateEnd: Math.round(v / 1000),
          })} />
        </div>
      </div>
    </div>
  )
}

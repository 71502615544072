import gql from "graphql-tag";
import ClusterFragment from "../fragment/ClusterFragment";


export default gql`

  ${ClusterFragment}

  mutation clusterUpdate($id: String!, $input: ClusterInput!) {
    cluster: clusterUpdate(id: $id, input: $input) {
      ...ClusterFragment
    }
  }

`
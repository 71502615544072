import gql from "graphql-tag";
import AddressFragmentGql from "graphql/BILLING/Address/fragment/AddressFragment.gql";
import CompanyStripeFragmentGql from "graphql/BILLING/Company/fragment/CompanyStripeFragment.gql";
import ContactEditFragment from "graphql/BILLING/Contact/fragment/ContactEditFragment";
import PaymentPrefFragment from "graphql/BILLING/PaymentPref/fragment/PaymentPrefFragment";

export default gql`

  ${AddressFragmentGql}
  ${ContactEditFragment}
  ${PaymentPrefFragment}
  ${CompanyStripeFragmentGql}

  fragment CustomerEditFragment on Customer {
    id
    initials
    name
    Company {
      ...CompanyStripeFragment
    }
    PaymentPref {
      ...PaymentPrefFragment
    }
    Address {
      ...AddressFragment
    }
    Contact {
      ...ContactEditFragment
    }
  }
`
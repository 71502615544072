import {gql} from '@apollo/client'
import OrderPublicFragment from '../fragment/OrderPublicFragment'

export default gql`

  ${OrderPublicFragment}

  query orderGetByPublicId($publicId: String!) {
    orderGetByPublicId(publicId: $publicId) {
      ...OrderPublicFragment
    }
  }

`
import {gql} from "@apollo/client";
import UserFragment from "../fragment/UserFragment";

export default gql`

  ${UserFragment}

  mutation userUpdate($id: String!, $input: UserInput!) {
    user: userUpdate(id: $id, input: $input) {
      ...UserFragment
    }
  }

`
import { Modal, PriceFormat } from "@zipou/front_tools"
import { Item, Order } from "model"
import { useState } from "react"
import { OrderEdit } from "../../Order/Edit/OrderEdit"

type ItemViewProps = {
  item: Item
}


export const ItemView = ({ item }: ItemViewProps) => {


  const [focusOrder, updateFocusOrder] = useState<Order | null>()

  const orderFiltered = item?.OrderLine?.reduce<any>((acc, orderLine) => {
    const order = orderLine?.Order
    return {
      ...(acc || {}),
      [order?.id || ""]: order,
    }
  }, {})

  const hasOrder = Object.keys(orderFiltered).length > 0

  return <div>
    {focusOrder && <Modal display title="Modifier bon de commande" onClose={() => updateFocusOrder(null)}>
      <OrderEdit id={focusOrder?.id} onDone={() => updateFocusOrder(null)} />
    </Modal>}
    <table className="table table-striped table-bordered">
      <tr>
        <th>Label</th>
        <td>{item?.label}</td>
      </tr>
      <tr>
        <th>Price</th>
        <td>
          <PriceFormat value={item?.price} />
        </td>
      </tr>
      <tr>
        <th>Price</th>
        <td>{item?.vat}</td>
      </tr>
      {hasOrder && <tr>
        <th>Utilisation</th>
        <td>
          <table className="table table-striped">
            {Object.values(orderFiltered).map((order: any) => {
              return <tr>
                <td>
                  <button className="btn btn-sm btn-dark" onClick={() => updateFocusOrder(order)}>
                    {order?.publicId}
                  </button>
                </td>
              </tr>
            })}
          </table>

        </td>
      </tr>}
    </table>
  </div>

}
import { ClusterEdit } from "components/backoffice/HOSTING/Cluster/Cluster/Edit/ClusterEdit"
import { Link, useParams } from "react-router-dom"

import BootstrapBreadcrumb from "react-bootstrap/Breadcrumb"
import { URL_HOSTING_CLUSTER_LIST } from "static/constant/backoffice/url"

export const ClusterEditRoute = () => {

  const { id } = useParams<{ id: string }>()

  return <div>
    <BootstrapBreadcrumb>
      <BootstrapBreadcrumb.Item active>Hébergement</BootstrapBreadcrumb.Item>
      <BootstrapBreadcrumb.Item>
        <Link to={"/manager" + URL_HOSTING_CLUSTER_LIST}>
          Liste des Applications
        </Link>
      </BootstrapBreadcrumb.Item>
      <BootstrapBreadcrumb.Item active>Editer</BootstrapBreadcrumb.Item>
    </BootstrapBreadcrumb>

    <ClusterEdit id={id || ""} />
  </div>

}
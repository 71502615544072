import {gql} from "@apollo/client"

import InvoiceDetailsFragment from "../../Invoice/fragment/InvoiceDetailsFragment"

export default gql`

${InvoiceDetailsFragment}

fragment PaymentDetailsFragment on Payment {
  id
  type
  value
  date
  comment
  status
  stripeClientSecret
  Invoice {
    ...InvoiceDetailsFragment
  }
}
`
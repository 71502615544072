import { useQuery } from "@apollo/client"
import { TextInput, SelectInput } from "@zipou/front_tools"
import contactTitleListGql from "graphql/BILLING/Contact/query/contactTitleList.gql"
import contactTypeListGql from "graphql/BILLING/Contact/query/contactTypeList.gql"
import { ContactInput } from "model"

export type ContactFormProps = {
  contact: ContactInput,
  errors: any,
  onChange: (data: ContactInput) => void,
}

export const ContactForm = (props: ContactFormProps) => {


  const contactTypeListResponse = useQuery(contactTypeListGql)
  const contactTypeList = contactTypeListResponse?.data?.list

  const contactTitleListResponse = useQuery(contactTitleListGql)
  const contactTitleList = contactTitleListResponse?.data?.list

  const onChange = (d: any) => {
    props?.onChange(d)
  }

  const contact = props?.contact
  const errors = props?.errors

  return <div>
    {contactTypeList && <SelectInput id={"type"} label='Type' choiceList={contactTypeList?.map((el: string) => ({ id: el, label: el }))} value={contact?.type || ""} errors={errors} onChange={(v: string) => onChange({
      ...contact,
      type: v,
    })} />}
    {contactTitleList && <SelectInput id={"title"} label='Title' choiceList={contactTitleList?.map((el: string) => ({ id: el, label: el }))} value={contact?.title || ""} errors={errors} onChange={(v: string) => onChange({
      ...contact,
      title: v,
    })} />}
    <TextInput label={"First Name"} id="firstname" value={contact?.firstname || ""} errors={errors} onChange={(v: string) => {
      onChange({
        ...contact,
        firstname: v,
      })
    }} />
    <TextInput label={"Last Name"} id="lastname" value={contact?.lastname || ""} errors={errors} onChange={(v: string) => {
      onChange({
        ...contact,
        lastname: v,
      })
    }} />
    <TextInput label={"Email"} id="email" value={contact?.email || ""} errors={errors} onChange={(v: string) => {
      onChange({
        ...contact,
        email: v,
      })
    }} />

  </div>
}
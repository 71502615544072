
import { useState } from "react"
import { useMutation } from "@apollo/client"
import { ItemInput } from "model"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import { ItemForm } from "../Form/ItemForm"
import itemAddGql from "graphql/BILLING/Item/mutation/itemAdd.gql"


type ItemAddProps = {
  onDone?: () => void,
}

export const ItemAdd = ({onDone}: ItemAddProps) => {

  const [item, updateItem] = useState<ItemInput>({
    label: "",
    vat: 2000,
    price: 0,
    priceBuy: 0,
  })
  const [errors, updateErrors] = useState<any>()

  const [mutate] = useMutation(itemAddGql)

  const onSave = () => {
    mutate({
      variables: {
        input: item
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  return <div className="item-edit-container">
    <ItemForm item={item} onChange={updateItem} errors={errors} />
    <div>
      <button className="btn btn-sm btn-dark ml-1" onClick={() => onSave()}>Enregistrer</button>
    </div>
  </div>

}
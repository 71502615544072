import {gql} from "@apollo/client"
import PaymentDetailsFragment from "../fragment/PaymentDetailsFragment"

export default gql`

  ${PaymentDetailsFragment}

  mutation paymentConfirmIntent($paymentId: String!) {
    paymentConfirmIntent(paymentId: $paymentId) {
      ...PaymentDetailsFragment
    }
  }

`
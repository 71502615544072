import { gql } from "@apollo/client";
import PictureFragment from "../fragment/PictureFragment";

export default gql`

  ${PictureFragment}

  query pictureList($input: PictureListInput, $cursor: CursorInput!) {
    list: pictureList(input: $input, cursor: $cursor) {
      nodes {
        ...PictureFragment
      }
      cursor {
        limit
        page
        totalPages
      }
      input
    }
  }

`

import itemGetQuery from "graphql/BILLING/Item/query/itemGet"
import { useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { Item, ItemInput } from "model"
import itemUpdateGql from "graphql/BILLING/Item/mutation/itemUpdate.gql"
import { ItemForm } from "../Form/ItemForm"
import { formatErrorResponseForJoi } from "helpers/Apollo"


type ItemEditProps = {
  id?: String,
  onDone?: () => void,
}

export const ItemEdit = ({ id, onDone }: ItemEditProps) => {

  const [item, updateItem] = useState<ItemInput | null>()
  const [errors, updateErrors] = useState<any>()

  const [mutate] = useMutation(itemUpdateGql)

  const { data } = useQuery<{ item: Item }>(itemGetQuery, {
    variables: {
      id,
    },
    skip: !id,
    onCompleted: (response) => {
      updateItem({
        label: response?.item?.label,
        vat: response?.item?.vat,
        price: response?.item?.price,
        priceBuy: response?.item?.priceBuy || 0,
      })
    }
  })

  const onSave = () => {
    mutate({
      variables: {
        id,
        input: item
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  return <div className="item-edit-container">
    {item && <ItemForm errors={errors} item={item} onChange={updateItem} />}
    <div className="footer">
      <button className="btn btn-sm btn-dark ml-1" onClick={() => onSave()}>Enregistrer</button>
    </div>
  </div>

}
import { DateInput, Price, TextInput } from '@zipou/front_tools'
import { PaymentInput } from 'model'

type PaymentEditProps = {
  payment: PaymentInput,
  onChange: (payment: PaymentInput) => void
}

export const PaymentEdit = (props: PaymentEditProps) => {
  const { date, type, value, comment } = props?.payment


  return (
    <div>
      <DateInput id={"date"} value={date * 1000 || 0} label='Date' onChange={(v: number) => {
        props?.onChange({
          ...props?.payment,
          date: Math.round(v / 1000),
        })
      }} />
      <div className="input-group">
        <div className="input-group-text">Type</div>
        <select className="form-control" value={type || ""} onChange={(e: any) => {
          props?.onChange({
            ...props?.payment,
            type: e.target.value,
          })
        }}>
          <option value="" disabled={true}>Choisissez une valeur</option>
          <option value="card">Carte</option>
          <option value="check">Chèque</option>
          <option value="transfer">Virement</option>
          <option value="debit">Prélèvement</option>
        </select>
      </div>
      <Price label="Montant" value={value || 0} onChange={(v: any) => {
        props?.onChange({
          ...props?.payment,
          value: v,
        })
      }} />
      <TextInput errors={{}} label="Comment" value={comment ||  ""} id="commend" onChange={(v) => {
        props?.onChange({
          ...props?.payment,
          comment: v,
        })
      }} />
    </div>
  )
}


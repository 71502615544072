import React, { useState, useEffect } from "react";

import { URL_FRONT, URL_BACKOFFICE } from "static/constant/url";

// import ScrollLink from "react-scroll/modules/components/Link"
import { scroller } from "react-scroll"

import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'

import "./style.scss"

import logo from 'static/images/logo.png';
import { userContext } from "context/User";
import { useNavigate } from "react-router";

export const HeaderBar = props => {

  const [navFloating, setFloating] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const navigate = useNavigate()

  const _handleLinkClic = target => {
    navigate(target)
  }

  const _handleToggle = () => {
    setExpanded(!expanded)
  }

  const _handlePoundClic = target => {
    scroller.scrollTo(target, {
      duration: 500,
      smooth: true,
      offset: -150, // Scrolls to element + 50 pixels down the page
    })
    setExpanded(false)
  }

  const handleScroll = () => {
    let fromTop = window.scrollY;
    setFloating(!!(fromTop < 10))
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll.bind(this));
    return () => {
      window.removeEventListener('scroll', handleScroll.bind(this));
    };
  })

  return (
    <Navbar expanded={expanded} onToggle={_handleToggle.bind(this)} bg="light" expand="lg" fixed="top" className={(navFloating) ? "header-nav-transition floating rounded" : "header-nav-transition"} >
      <userContext.Consumer>
        {user => {

          return <Container>
            <Nav.Link onClick={_handleLinkClic.bind(this, URL_FRONT)}>
              <Navbar.Brand><img className="d-inline-block align-center" src={logo} alt="logo" /> box4b</Navbar.Brand>
            </Nav.Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto mynavbar">
                <Nav.Link onClick={_handlePoundClic.bind(this, "section-1")}>Accueil</Nav.Link>
                <Nav.Link onClick={_handlePoundClic.bind(this, "section-2")}>Nos Services</Nav.Link>
                <Nav.Link onClick={_handlePoundClic.bind(this, "section-3")}>Contact</Nav.Link>
              </Nav>
              <Nav>
                {user && <Nav.Link onClick={_handleLinkClic.bind(this, URL_BACKOFFICE)}>Manager</Nav.Link >}
                {!user && <Nav.Link onClick={_handleLinkClic.bind(this, URL_BACKOFFICE)}>Mon Compte</Nav.Link >}
              </Nav>

            </Navbar.Collapse>
          </Container>

        }}
      </userContext.Consumer>
    </Navbar>

  )
}

import React, {useContext, useState} from "react"
import {useQuery} from "@apollo/client"
import {Link} from "react-router-dom"

import {userContext} from "context/User"
import {Card} from "components/common/Card/Card"

import serverGetQuery from "graphql/HOSTING/Server/query/serverGet"

import {URL_HOSTING_SERVER_EDIT} from 'static/constant/backoffice/url'

import "./style.scss"
import { Modal } from "@zipou/front_tools"

import {ServerFirewallEdit} from "../Firewall/ServerFirewallEdit"

type ServerDetailsProps = {
  serverId: string,
}

export const ServerDetails = (props: ServerDetailsProps) => {

  const [focusFirewall, updateFocusFirewall] = useState<boolean>(false)

  const {serverId} = props

  const {data} = useQuery(serverGetQuery, {
    variables: {
      id: serverId,
    }
  })

  const user: any = useContext(userContext)
  const {isAdmin} = user || {}

  const {id, name} = data?.server || {}

  return <div className="server-details-container">
    <Modal display={focusFirewall} title={"Edition du Firewall"} centered={false} onClose={() => updateFocusFirewall(false)}>
      <ServerFirewallEdit
        serverId={id}
      />
    </Modal>
    <Card title={"Details"}>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th>Nom</th>
            <td>{name}</td>
          </tr>
        </tbody>
      </table>
    </Card>
    <Card title="Actions">
      {isAdmin && <Link to={URL_HOSTING_SERVER_EDIT.replace(':serverId', id)}>
        <button className="btn btn-info">Modifier</button>
      </Link>}
      <button className="btn btn-dark" onClick={() => updateFocusFirewall(true)}>Gérer le firewall</button>
    </Card>
  </div>
}
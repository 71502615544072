import gql from "graphql-tag"
import ClusterRoleListFragment from "../fragment/ClusterRoleListFragment"

export default gql`

  ${ClusterRoleListFragment}

  query clusterRoleList {
    clusterRoleList {
      ...ClusterRoleListFragment
    }
  }

`

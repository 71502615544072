import {gql} from "@apollo/client";
import AddressFragmentGql from "graphql/BILLING/Address/fragment/AddressFragment.gql";
import RibFragmentGql from "graphql/BILLING/Rib/fragment/RibFragment.gql";

export default gql`

${AddressFragmentGql}
${RibFragmentGql}

fragment CompanyFragment on Company {
  id
  name
  siret
  logo
  tvaIntra
  Address {
    ...AddressFragment
  }
  Rib {
    ...RibFragment
  }
}

`
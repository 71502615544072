// import {URL_BACKOFFICE} from 'static/constant/url'

const URL_BACKOFFICE = "/"

export const URL_ROOT = URL_BACKOFFICE;
export const URL_ITEM_ADD = URL_BACKOFFICE + "item/add";
export const URL_ITEM_LIST = URL_BACKOFFICE + "item/list";
export const URL_INVOICE_ADD = URL_BACKOFFICE + "invoice/add";
export const URL_QUOTE_ADD = URL_BACKOFFICE + "quote/add";
export const URL_ORDER_ADD = URL_BACKOFFICE + "order/add";
export const URL_ORDER_LIST = URL_BACKOFFICE + "order/list";

export const URL_USER_LIST = URL_BACKOFFICE + "user/list";

export const URL_AUTOMATION_ROOT = URL_BACKOFFICE + "automation";
export const URL_CRM_ROOT = URL_BACKOFFICE + "crm";

export const URL_HOSTING_ROOT = URL_BACKOFFICE + "hosting";


export const URL_HOSTING_CONFIG_LIST = `${URL_ROOT}hosting/config/list`
export const URL_PHONE_CONFIG_LIST = `${URL_ROOT}phone/config/list`


export const URL_VIDEO_CONFIG_LIST = `${URL_ROOT}video/config/list`
export const URL_CAMERA_LIST = `${URL_ROOT}camera/list/:id/:token`
// export const URL_PHONE_CONFIG_LIST = URL_HOSTING_ROOT + "/phone/config/list";

export const URL_HOSTING_SERVER_DETAILS = URL_HOSTING_ROOT + "/server/:serverId/details";
export const URL_HOSTING_SERVER_STAT = URL_HOSTING_ROOT + "/server/:serverId/stats";
export const URL_HOSTING_SERVER_EDIT = URL_HOSTING_ROOT + "/server/:serverId/edit";
export const URL_HOSTING_SERVER_LIST = `${URL_ROOT}hosting/server/list`
export const URL_HOSTING_SERVER_ADD = URL_HOSTING_ROOT + "/server/add";
export const URL_HOSTING_SERVER_FIREWALL_EDIT = URL_HOSTING_ROOT + "/server/:serverId/firewall/edit";

export const URL_HOSTING_TEMPLATE_EDIT = URL_HOSTING_ROOT + "/template/edit/:templateId"
export const URL_HOSTING_TEMPLATE_ADD = URL_HOSTING_ROOT + "/template/add"
export const URL_HOSTING_TEMPLATE_LIST = URL_HOSTING_ROOT + "/template/list"

export const URL_HOSTING_SERVER_PROV_ACTION = URL_HOSTING_ROOT + "/server/prov/action"

export const URL_HOSTING_PROBE_LIST = URL_HOSTING_ROOT + "/probe/list";
export const URL_HOSTING_PROBE_STAT = URL_HOSTING_ROOT + "/probe/get/:probeId/stats";

export const URL_HOSTING_PHONEHARD_ADD = URL_HOSTING_ROOT + "/phone/edit";
export const URL_HOSTING_PHONEHARD_EDIT = URL_HOSTING_ROOT + "/phone/edit/:phoneHardId";
export const URL_HOSTING_PHONEHARD_LIST = URL_HOSTING_ROOT + "/phone/list";

export const URL_PHONE_SIP_LINE_LIST = URL_HOSTING_ROOT + "/phone/sipline/list";
export const URL_PHONE_CONFIG_UPDATE = URL_HOSTING_ROOT + "/phone/config/update";


export const URL_PHONE_MUSIC_ON_HOLD_LIST = URL_HOSTING_ROOT + "/phone/musiconhold/list";

export const URL_PHONE_EXTENSION_LIST = URL_HOSTING_ROOT + "/phone/extension/list";

export const URL_PHONE_SIP_PEER_LIST = URL_HOSTING_ROOT + "/phone/peer/list";
export const URL_PHONE_SIP_PEER_ADD = URL_HOSTING_ROOT + "/phone/peer/add";
export const URL_PHONE_SIP_PEER_EDIT = URL_HOSTING_ROOT + "/phone/peer/edit/:id";

export const URL_NETWORK_ROOT = `${URL_BACKOFFICE}network`


// const URL_ROOT = URL_BACKOFFICE;
// const URL_FRONT = "/";

// TEMPLATE VARS in a STRING is NOT A BUG
export const URL_INVOICE_PDF = '${apiRoot}/document/invoice/${publicId}?access_token=${accessToken}&stamp=${stamp}'
export const URL_QUOTE_PDF = '${apiRoot}/document/quote/${publicId}?access_token=${accessToken}'
export const URL_ORDER_PDF = ':apiRoot/document/order/:publicId?access_token=:accessToken'
//

// export const URL_ROOT_LOGIN = `${URL_FRONT}login`

export const URL_BILLING_ROOT = URL_ROOT + "billing";
export const URL_BILLING_INVOICE_DETAILS_TEMPLATE = URL_ROOT + "billing/invoice/:id/details";
export const URL_BILLING_INVOICE_DETAILS_PDF_TEMPLATE = URL_ROOT + "billing/invoice/:id/details/pdf";
export const URL_BILLING_GRAPH = URL_ROOT + "billing/graph";

export const URL_BILLING_TAG_MANAGER = URL_ROOT + "billing/tag";

export const URL_BILLING_TINK_LINK = URL_BILLING_ROOT + "/tink/link";

export const URL_BILLING_CUSTOMER_LIST = `${URL_BILLING_ROOT}/customer/list`
// export const URL_BILLING_CUSTOMER_ADD = `${URL_BILLING_ROOT}/customer/add`
// export const URL_BILLING_CUSTOMER_EDIT = `${URL_BILLING_ROOT}/customer/edit/:id`
export const URL_BILLING_CUSTOMER_VIEW = `${URL_BILLING_ROOT}/customer/view/:id`
export const URL_BILLING_INVOICE_LIST = `${URL_BILLING_ROOT}/invoice/list`
export const URL_BILLING_TRANSACTION_LIST = `${URL_BILLING_ROOT}/transaction/list`
export const URL_BILLING_QUOTE_LIST = `${URL_BILLING_ROOT}/quote/list`

export const URL_GRAPHIQL = URL_ROOT + "graphiql";
export const URL_INVOICE_EDIT = URL_ROOT + "invoice/edit/:id";


export const URL_PROFILE = `${URL_ROOT}profile`;
export const URL_PROFILE_IMPERSONATE = `${URL_ROOT}profile/impersonate`;
export const URL_ORDER_UPDATE_TEMPLATE = `${URL_ROOT}billing/order/update/:id`
export const URL_ORDER_UPDATE_PAYMENT_PREF_TEMPLATE = `${URL_ROOT}billing/order/update/:id/paymentPref`
export const URL_ORDER_UPDATE_PAYMENT_BANK_ACCOUNT_ADD_TEMPLATE = `${URL_ROOT}billing/order/update/:id/paymentPref/account/add`
export const URL_ORDER_UPDATE_PAYMENT_CARD_ADD_TEMPLATE = `${URL_ROOT}billing/order/update/:id/paymentPref/card/add`
// export const URL_ORDER_UPDATE_PAYMENT_CARD_ADD = `${URL_ROOT}billing/order/paymentPref/card/add`

export const URL_PHONE_ROOT = `${URL_ROOT}phone/main`
export const URL_PHONE_DIRECTORY = `${URL_ROOT}phone/directory`
export const URL_PHONE_CONFIG = `${URL_ROOT}phone/config`
export const URL_PHONE_MODULE_DISPLAY = `${URL_ROOT}phone/module/:id/:token`

export const URL_PHONE_NUMBER_MANAGE = `${URL_ROOT}phone/number/manage`
export const URL_PHONE_NUMBER_LIST = `${URL_ROOT}phone/number/list`

export const URL_PHONE_SCENARIO_ADD = `${URL_ROOT}phone/scenario/add`
export const URL_PHONE_SCENARIO_EDIT = `${URL_ROOT}phone/scenario/edit/:id`
export const URL_PHONE_SCENARIO_LIST = `${URL_ROOT}phone/scenario/list`
export const URL_PHONE_RECORD_MANAGE = `${URL_ROOT}phone/record/manage`
export const URL_PHONE_SOUND_LIST = `${URL_ROOT}phone/sound/list`
export const URL_PHONE_SOUND_ADD = `${URL_ROOT}phone/sound/add`
export const URL_PHONE_SOUND_EDIT = `${URL_ROOT}phone/sound/edit/:id`
export const URL_PHONE_IVR_MANAGE = `${URL_ROOT}phone/ivr/manage`
export const URL_PHONE_CTI = `${URL_ROOT}phone/cti`
export const URL_PHONE_QUEUE = `${URL_ROOT}phone/queue`
export const URL_PHONE_DIALER = `${URL_ROOT}phone/dialer`
export const URL_PHONE_SIP_CLIENT = `${URL_ROOT}phone/sip/client`

export const URL_PAYMENT_DETAILS_TEMPLATE = `${URL_ROOT}billing/payment/:paymentId/details`
export const URL_PAYMENT_DETAILS_3D_SECURE_TEMPLATE = `${URL_ROOT}billing/payment/:paymentId/details/3dsecure`
// export const URL_CARD_3DSECURE = `${URL_ROOT}card/3dsecure/:token/:paymentId`

export const URL_HOSTING_CLUSTER_ROLE_LIST = `${URL_ROOT}hosting/cluster/role/list`
export const URL_HOSTING_CLUSTER_PROVISIONING_TASK_LIST = `${URL_ROOT}hosting/cluster/provisioning/task/list`
export const URL_HOSTING_CLUSTER_LIST = `${URL_ROOT}hosting/cluster/list`
export const URL_HOSTING_CLUSTER_ADD = `${URL_ROOT}hosting/cluster/add`
export const URL_HOSTING_CLUSTER_EDIT = `${URL_ROOT}hosting/cluster/edit/:id`
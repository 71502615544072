
import { useState } from "react"
import { useQuery } from "@apollo/client"
import { SelectInput } from "@zipou/front_tools"


import backupConfigListQuery from 'graphql/HOSTING/Server/BackupConfig/query/backupConfigList'
import { TextInput } from "@zipou/front_tools"
import { BackupConfig, HostingConfig, ServerInput, ServerTypeEnum } from "model"
import hostingConfigListGql from "graphql/HOSTING/Config/query/hostingConfigList.gql"


type ServerFormProps = {
  server: ServerInput,
  errors: any,
  onChange: (server: ServerInput) => void,
}

export const ServerForm = ({ server, errors, onChange }: ServerFormProps) => {

  const backupConfigListQueryData = useQuery(backupConfigListQuery);
  const backupConfigList = backupConfigListQueryData?.data?.backupConfigList

  const { data } = useQuery<{ list: HostingConfig[] }>(hostingConfigListGql)
  const hostingConfigList = data?.list

  const mustHaveIp = server?.type && (server?.type !== ServerTypeEnum.SCALEWAY_DEV1_S)

  return <div className="server-edit-container">
    <div className="server-edit-form">
      <SelectInput errors={errors} id="type"
        value={server?.type || ""}
        defaultLabel="Choisissez"
        disabled={false}
        label="Type"
        choiceList={Object.values(ServerTypeEnum).map((el => ({ id: el, label: el })))}
        onChange={(value: string) => {
          onChange({
            ...server,
            type: value as ServerTypeEnum,
          })
        }} />

      <SelectInput value={server?.hostingConfigId || ""} label="Config" choiceList={hostingConfigList?.map(config => ({ id: config?.id, label: config?.name || config?.id })) || []} id={"hostingConfigId"} errors={errors} onChange={(v) => {
        onChange({
          ...server,
          hostingConfigId: v
        })
      }} />


      <SelectInput errors={errors} id="backupTargetId" value={server?.backupTargetId || ""} disabled={false} label="Sauvegarde" choiceList={backupConfigList?.map((backupConfig: BackupConfig) => ({ id: backupConfig?.id, label: backupConfig.name }))} onChange={(value: string) => {
        onChange({
          ...server,
          backupTargetId: value,
        })
      }} />

      {mustHaveIp && <TextInput errors={errors} id="ip" value={server?.ip || ""} disabled={false} label="Ip" onChange={(value: string) => {
        onChange({
          ...server,
          ip: value,
        })
      }} />}
    </div>
  </div>
}

import {gql} from "@apollo/client";

export default gql`

fragment ContactFragment on Contact {
  id
  title
  type
  lastname
  firstname
  email
  Customer {
    id
    initials
  }
}
`
import { TagAdd } from "components/backoffice/BILLING/Tag/Add/TagAdd"
import { TagList } from "components/backoffice/BILLING/Tag/List/TagList"
import { Modal } from "@zipou/front_tools"
import { useState } from "react"

type TagManagerRouteProps = {

}



export const TagManagerRoute = (props: TagManagerRouteProps) => {

  return <>
    <TagList />
  </>

}
import { gql } from "@apollo/client";
import TagFragment from "../fragment/TagFragment";

export default gql`

  ${TagFragment}

  mutation tagAdd($input: TagInput!) {
    tag: tagAdd(input: $input) {
      ...TagFragment
    }
  }

`
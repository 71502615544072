const translations = {
  "month_1" : "Janvier",
  "month_2" : "Fevrier",
  "month_3" : "Mars",
  "month_4" : "Avril",
  "month_5" : "Mai",
  "month_6" : "Juin",
  "month_7" : "Juillet",
  "month_8" : "Août",
  "month_9" : "Septembre",
  "month_10" : "Octobre",
  "month_11" : "Novembre",
  "month_12" : "Decembre",
  "DATE_FIRST_WORD" : "Le",
  "DATE_HOUR_SEPARATOR" : "h",
}

export const Intl = (key) => translations[key]

export class DateTime {

  constructor(timestamp) {
    this.date = new Date(timestamp * 1000);
  }

  getYear() {
    return this.date.getFullYear();
  }

  getMonthString() {
    return Intl("month_" + (this.date.getMonth()+1));
  }

  getMonth() {
    const month = this.date.getMonth() + 1
    return `${((month) < 10) ? `0${month}` : `${month}` }`;
  }

  getDay() {
    return `${(this.date.getDate() < 10) ? `0${this.date.getDate()}` : `${this.date.getDate()}` }`;
  }

  getMinutes() {
    return (this.date.getMinutes() < 10 ) ? "0" + this.date.getMinutes() : "" + this.date.getMinutes();
  }

  getTime() {
    return this.date.getHours() + Intl("DATE_HOUR_SEPARATOR") + this.getMinutes();
  }

  getDateString() {
    return this.getDay() + "/" +(this.getMonth()) + "/" + this.getYear().toString().replace(/20/ , "");
  }

  getString() {
    return Intl("DATE_FIRST_WORD") + " " +this.getDay() + " " + this.getMonthString() + " " + this.getYear() + " - " + this.getTime();
  }

}
export default DateTime;

import {gql} from '@apollo/client'
import ItemFragment from '../fragment/ItemFragment'

export default gql`

  ${ItemFragment}

  query itemList {
    list: itemList {
      ...ItemFragment
    }
  }
`
import { useState } from "react"
import { TagForm } from "../Form/TagForm"
import { TagInput } from "model"
import { useMutation } from "@apollo/client"
import tagAddGql from "graphql/BILLING/Tag/mutation/tagAdd.gql"

type TagAddProps = {
  onDone?: () => void,
}

export const TagAdd = ({ onDone }: TagAddProps) => {

  const [tagInput, updateTagInput] = useState<TagInput>({})

  const [mutate] = useMutation(tagAddGql)

  const onSave = () => {
    mutate({
      variables: {
        input: tagInput,
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e) => {
        console.log("E", e)
      })
  }


  return <div>
    <TagForm tagInput={tagInput} onChange={(v) => updateTagInput(v)} />
    <div>
      <button className="btn btn-sm btn-dark mr-1" onClick={() => onSave()}>Ajouter</button>
    </div>
  </div>

}
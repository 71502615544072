import {gql} from '@apollo/client'
import ItemFragment from '../fragment/ItemFragment'

export default gql`

  ${ItemFragment}

  query itemSearch($item: String!) {
    list: itemSearch(item: $item) {
      ...ItemFragment
    }
  }
`
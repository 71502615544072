import { useState } from "react"
import { VideoConfigForm } from "../Form/VideoConfigForm"
import { VideoConfig, VideoConfigInput } from "model"
import { useMutation, useQuery } from "@apollo/client"
import videoConfigAddGql from "graphql/VIDEO/Config/mutation/videoConfigAdd.gql"

type VideoConfigAddProps = {
  onDone?: () => void,
}

export const VideoConfigAdd = ({ onDone }: VideoConfigAddProps) => {

  const [videoConfig, updateVideoConfig] = useState<VideoConfigInput>()
  const [errors, updateErrors] = useState<any>()

  const [mutate] = useMutation(videoConfigAddGql)

  const onSave = () => {
    mutate({
      variables: {
        input: videoConfig,
      }
    })
      .then(() => {
        onDone && onDone()
        console.log("OK")
      })
      .catch((e) => {
        console.log("EEEEE", e)
      })
  }

  return <div>
    <VideoConfigForm videoConfig={videoConfig as VideoConfigInput} onChange={(pc) => updateVideoConfig(pc)} errors={errors} />
    <div className="mt-1">
      <button className="btn btn-sm btn-dark ml-1" onClick={() => onSave()}>Ajouter</button>
      <button className="btn btn-sm btn-danger ml-1" onClick={() => onDone && onDone()}>Annuler</button>
    </div>
  </div>
}
import gql from "graphql-tag";
import ServerListFragment from "../fragment/ServerListFragment";

export default gql`
  ${ServerListFragment}

  query serverList {
    serverList {
      ...ServerListFragment
    }
  }
`
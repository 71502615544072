import { useMutation } from "@apollo/client"
import { FileInput } from "@zipou/front_tools"
import TransactionImportGql from "graphql/BILLING/Transaction/mutation/TransactionImport.gql"
import { useState } from "react"

type TransactionImportType = {
  onDone: () => void,
}

export const TransactionImport = ({ onDone }: TransactionImportType) => {

  const [base64payload, updateBase64payload] = useState<any>()

  const [mutate] = useMutation(TransactionImportGql)

  const onSave = () => {
    mutate({
      variables: {
        base64CsvFile: base64payload
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e: any) => {
        console.log("ERROR", e)
      })
  }


  return <div>
    <FileInput label="Fichier CSV" onChange={(payload) => updateBase64payload(payload)} />
    <div>
      <button className="btn btn-dark ml-1" onClick={() => onSave()}>Enregister</button>
    </div>
  </div>

}
import {Cookies} from 'react-cookie';
import {COOKIE_KEY} from "static/constant/user";

const cookie = new Cookies()

export const saveLoginCookie = (value) => {
  cookie.set(COOKIE_KEY, JSON.stringify(value));
}


export const getLoginCookie =  () => {
  const value = cookie.get(COOKIE_KEY)
  return value;
}

export const clearLoginCookie = () => {
  cookie.set(COOKIE_KEY, JSON.stringify({}));
  // return cookie.remove(COOKIE_KEY)
  return true
}

export const Cookie = {
  save : (token, expires_in, refresh_token) => {
    let authInfo = {
      token,
      refresh_token,
      expiration: Math.floor(Date.now() / 1000) + expires_in
    }
    cookie.set(COOKIE_KEY, JSON.stringify(authInfo));
  },
  flush: () => {
    cookie.set(COOKIE_KEY, JSON.stringify({}));
  },
  isValid : () => {
    let now = Math.floor(Date.now() / 1000);
    let data = this.get();
    let {token, expiration} = (data) ? data: {};
    return (token && (expiration > now));
  },
  get : () => {
    let data = cookie.get(COOKIE_KEY);
    return (data) ? data : {};
  }
}

import { Cookies } from 'react-cookie';


export class CookieHelper {

  private cookie: Cookies;
  private key: string

  constructor(key: string) {
    this.cookie = new Cookies()
    this.key = key
  }

  save(value: any) {
    return this.cookie.set(this.key, JSON.stringify(value))
  }

  get() {
    const value = this.cookie.get(this.key)
    return value
    // console.log('value', value)
    // try {
    //   return value && JSON.parse(value)
    // } catch(e) {
    //   console.log("ERROR token cypher")
    //   return false
    // }
  }

  clear() {
    return this.cookie.remove(this.key)
  }

}

import { gql } from "@apollo/client";
import VideoConfigFragmentGql from "../fragment/VideoConfigFragment.gql";

export default gql`

  ${VideoConfigFragmentGql}

  query videoConfigGet($id: String!) {
    videoConfig: videoConfigGet(id: $id) {
      ...VideoConfigFragment
    }
  }

`
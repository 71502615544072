


import GraphiQLComponent from 'graphiql'
import "graphiql/graphiql.css";
import "./style.scss";

import {findToken} from "helpers/User";
import {Vars} from "libs/Vars";
import {Suspense} from 'react';

export const GraphiQL = () => {

  const _handleFetcher = (params: any) => {
    const {graphql_endpoint} = Vars;
    const token = findToken();
    return fetch(`${graphql_endpoint}?access_token=${token}`, {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(params),
    })
      .then(response => response.json());
  }

  return <Suspense fallback={<div>Chargement...</div>}>
    <GraphiQLComponent fetcher={_handleFetcher} tabs={true} />
  </Suspense>
}
import {gql} from "@apollo/client";
import CustomerEditFragment from "../fragment/CustomerEditFragment";

export default gql`

${CustomerEditFragment}

query customerGet($id: String!) {
  customer: customerGet(id: $id) {
    ...CustomerEditFragment
  }
}

`

import { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { SelectInput } from "@zipou/front_tools"


import serverGetQuery from 'graphql/HOSTING/Server/query/serverGet'
import serverUpdateMutation from 'graphql/HOSTING/Server/mutation/serverUpdate'
import serverAddMutation from 'graphql/HOSTING/Server/mutation/serverAdd'
import backupConfigListQuery from 'graphql/HOSTING/Server/BackupConfig/query/backupConfigList'
import { TextInput } from "@zipou/front_tools"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import serverList from "graphql/HOSTING/Server/query/serverList"
import { ServerForm } from "../Form/ServerForm"
import { Server } from "model"


type ServerEditProps = {
  serverId: any,
  onDone: () => void,
}

export const ServerEdit = ({ serverId, onDone }: ServerEditProps) => {

  const [mutate] = useMutation(serverUpdateMutation)

  const [errors, updateErrors] = useState<any>(null)
  const [isLocked, updateIsLocked] = useState(false)
  const [server, updateServer] = useState<any>({})

  const { data } = useQuery<{ server: Server }>(serverGetQuery, {
    variables: {
      id: serverId
    },
    skip: !serverId,
    onCompleted: (data) => {
      const { __typename, id, name, ...rest } = data?.server;
      updateServer(rest)
    }
  })

  const onCancel = () => {
    updateServer(data?.server || {})
  }

  const onChange = (server: any) => {
    updateServer(server)
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    updateIsLocked(true)
    mutate({
      variables: {
        id: serverId,
        input: server
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e: any) => {
        updateIsLocked(false)
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  return <div className="server-edit-container">
    <div className="server-edit-form">
      <form onSubmit={onSubmit}>
        <ServerForm server={server} errors={errors} onChange={onChange} />
        <button className="btn btn-dark btn-sm" type="submit" disabled={isLocked}>Enregistrer</button>
      </form>
    </div>
  </div>
}

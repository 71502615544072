import { TextInput, FileInput, IconArrow, IconArrowDown } from "@zipou/front_tools"
import { AddressForm } from "../../Address/Form/AddressForm"
import { RibForm } from "../../Rib/Form/RibForm"
import { useState } from "react"

type CompanyFormProps = {
  company: any,
  errors: any,
  onChange: (data: any) => void,
}

export const CompanyForm = (props: CompanyFormProps) => {

  const onChange = (data: any) => {
    const { __typename, id, ...company } = data
    props?.onChange(company)
  }

  const company = props?.company
  const errors = props?.errors

  const [open, updateOpen] = useState(false)

  return <div className="card">
    <div className="card-header" onClick={() => updateOpen(!open)}>
      {open && <IconArrowDown />}
      {!open && <IconArrow />}
      <span className="ml-3">
        Company
      </span>
    </div>
    <div style={{ transition: "max-height 400ms ease", maxHeight: open ? 600 : 0, overflow: "hidden", padding: 0 }}>
      <div className="card-body">

        <TextInput value={company?.name || ""} errors={null} label="Nom" onChange={(name: string) => {
          onChange({
            ...company,
            name,
          })
        }} />
        <TextInput value={company?.siret || ""} errors={null} label="Siret" onChange={(siret: string) => {
          onChange({
            ...company,
            siret,
          })
        }} />
        <TextInput value={company?.tvaIntra || ""} errors={null} label="Tva Intra" onChange={(tvaIntra: string) => {
          onChange({
            ...company,
            tvaIntra,
          })
        }} />
        {company?.logo && <img alt="logo" src={company?.logo} className="img img-responsive" style={{ maxWidth: 150, maxHeight: 100 }} />}
        <FileInput label={"Logo"} onChange={(base64Payload: any) => {
          onChange({
            ...company,
            logo: base64Payload,
          })
        }} />
        <div className="input-group">
          <span className="input-group-text">Adresse</span>
          <div className="form-control" style={{ height: "100%" }}>
            <AddressForm address={company?.Address} onChange={(address: any) => {
              onChange({
                ...company,
                Address: address,
              })
            }} />
          </div>
        </div>
        <div className="input-group">
          <span className="input-group-text">Rib</span>
          <div className="form-control" style={{ height: "100%" }}>
            <RibForm errors={errors?.Rib} rib={company?.Rib} onChange={(rib: any) => {
              onChange({
                ...company,
                Rib: rib,
              })
            }} />
          </div>
        </div>
      </div>
    </div>
  </div>

}
import { gql } from "@apollo/client";

export default gql`

  fragment PhoneConfigTokenFragment on PhoneConfig {
    id
    name
    token
  }

` 
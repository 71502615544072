import React from "react"
import {Route, Link} from "react-router-dom"
import {useQuery} from "@apollo/client"

import {Vars} from "libs/Vars";
import {findToken} from "helpers/User"; 

import invoiceGetByIdQuery from "graphql/BILLING/Invoice/query/invoiceGetById";

import {Card} from "components/common/Card/Card"
import { Modal } from "@zipou/front_tools"

import {PaymentList} from "components/backoffice/BILLING/Payment/List/PaymentList";
import {InvoiceDetails} from "components/backoffice/BILLING/Invoice/Details/InvoiceDetails";

import {URL_INVOICE_PDF, URL_BILLING_INVOICE_DETAILS_PDF_TEMPLATE, URL_BILLING_INVOICE_DETAILS_TEMPLATE} from "static/constant/backoffice/url"
import {Breadcrumb} from "components/common/Navigation/Breadcrumb/Breadcrumb";

export const InvoiceDetailsRoute = (props) => {
  const {match, history} = props
  const {params} = match || {}
  const {id} = params
  const {data: {invoiceGetById} = {}} = useQuery(invoiceGetByIdQuery, {
    variables: {
      invoiceId: id,
    }
  })
  // const {id} = invoiceDetails || {}

  // console.log({invoiceGetById})
  const {publicId, Payment} = invoiceGetById || {}

  const hasPayment = Payment && Payment.length > 0
  const hasInvoice = !!invoiceGetById
  const {api_enpoint} = Vars

  const viewUrl = URL_INVOICE_PDF.replace("${apiRoot}", api_enpoint).replace("${publicId}", publicId).replace("${accessToken}", findToken())

  return <div>
    <Breadcrumb match={match} />
    <h1>Facture {publicId}</h1>
    <Route path={URL_BILLING_INVOICE_DETAILS_PDF_TEMPLATE}>
      <Modal display={true} title="PDF" onClose={() => history.push(URL_BILLING_INVOICE_DETAILS_TEMPLATE.replace(':id', id))}>
        <object data={viewUrl} type="application/pdf" style={{width: '100%', height: '90vh', border: 0}}>
          <embed src={viewUrl} type="application/pdf" />
        </object>
      </Modal>
    </Route>
    {hasInvoice && <InvoiceDetails invoiceId={id} />}
    {hasPayment && <PaymentList paymentList={Payment} /> }
    <Card title="Actions">
      <Link to={URL_BILLING_INVOICE_DETAILS_PDF_TEMPLATE.replace(":id", id)}><button className="btn btn-dark">Voir le PDF</button></Link>
    </Card>
  </div>
}
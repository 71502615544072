import { useState } from "react"

import notificationListQuery from 'graphql/Notification/query/notificationList'
import contractListQuery from "graphql/BILLING/Contract/query/contractList"
import invoiceAddMutation from "graphql/BILLING/Invoice/mutation/invoiceAdd"
import { useMutation, useQuery } from "@apollo/client";
import { formatErrorResponseForJoi } from "helpers/Apollo";
import { InvoiceInput, InvoiceStatusEnum } from "model";
import { InvoiceForm } from "../Form/InvoiceForm";

type InvoiceAddProps = {
  onDone: () => void,
}

export const InvoiceAdd = ({ onDone }: InvoiceAddProps) => {

  const [invoice, updateInvoice] = useState<InvoiceInput | null>({
    status: InvoiceStatusEnum.STATUS_PENDING,
    contractId: "",
    contactId: "",
    date: "",
    InvoiceLine: [],
    Payment: [],
  })

  const [errors, updateErrors] = useState<any>(null)
  const [invoiceAdd] = useMutation(invoiceAddMutation)


  const onSave = () => {
    return invoiceAdd({
      variables: {
        input: invoice,
      },
      refetchQueries: [
        {
          query: notificationListQuery,
          variables: {
            cursor: {
              page: 1,
              limit: 30,
            }
          },
        }
      ]
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  return <div className="invoice-edit-container">
    {invoice && <InvoiceForm invoice={invoice} onChange={updateInvoice} errors={errors} />}
    <div>
      <button className="btn btn-sm btn-dark" onClick={() => onSave()}>Enregistrer</button>
    </div>

  </div >
}


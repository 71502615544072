import gql from "graphql-tag"

export default gql`
  fragment ClusterRoleListFragment on ClusterRole {
    id
    name
    type
    image
    tag
    version
    isLoadBalancerClient
  }
`


import { ColorInput } from "@zipou/front_tools"
import { TextInput } from "@zipou/front_tools"
import { TagInput } from "model"

type TagFormProps = {
  tagInput: TagInput,
  onChange: (tagInput: TagInput) => void,
}


export const TagForm = (props: TagFormProps) => {

  const tag = props?.tagInput

  const onChange = (tagInput: TagInput) => {
    props?.onChange(tagInput)
  }

  return <div>
    <TextInput errors={{}} label="Nom" value={tag?.name || ""} onChange={(v) => {
      onChange({
        ...tag,
        name: v,
      })
    }} />

    <ColorInput errors={{}} label="Couleur" value={tag?.color || ""} onChange={(_, v) => {
      onChange({
        ...tag,
        color: v,
      })
    }} />
  </div>


}
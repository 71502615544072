import {SelectInput} from "@zipou/front_tools"
import {BankAccountForm} from "../BankAccount/BankAccountForm"
import {CardForm} from "../Card/CardForm"

export type PaymentPrefFormProps = {
  paymentPref: any,
  errors?: any,
  onChange: (data: any) => void,
}

export const PaymentPrefForm = (props: PaymentPrefFormProps) => {

  const onChange = (data: any) => {
    props?.onChange(data)
  }

  const paymentPref = props?.paymentPref


  const renderSubForm = (type: string) => {
    switch (type) {
      case "TYPE_DEBIT": {
        return <div className="input-group">
          <span className="input-group-text">RIB</span>
          <div className="form-control" style={{height: "100%"}}>
            <BankAccountForm bankAccount={paymentPref?.BankAccount} onChange={(BankAccount: any) => {
              onChange({
                ...paymentPref,
                type,
                BankAccount: {
                  ...BankAccount
                },
              })
            }} />
          </div>
        </div>
      }
      case "TYPE_CARD": {
        return <div className="input-group">
          <span className="input-group-text">RIB</span>
          <div className="form-control" style={{height: "100%"}}>
            <CardForm card={paymentPref?.Card} onDone={() => { }} />
          </div>
        </div>

      }
    }
  }

  return <div>
    <SelectInput label="Type" errors={props?.errors} id={"type"} value={paymentPref?.type} choiceList={[{label: "Prélèvement", id: "TYPE_DEBIT"}, {label: "Virement", id: "TYPE_TRANSFER"}]} onChange={(type) => {
      onChange({
        ...paymentPref,
        type,
      })
    }} />
    {renderSubForm(paymentPref?.type)}
  </div>
}
import React from "react"
import {useQuery} from '@apollo/client'

import invoiceGetByIdQuery from "graphql/BILLING/Invoice/query/invoiceGetById"
import {Card} from "components/common/Card/Card"

import {CurrencyFormat} from "components/common/Format/CurrencyFormat"
import {DateFormat} from "components/common/Format/DateFormat"

export const InvoiceDetails = props => {

  const {data: {invoiceGetById} = {}} = useQuery(invoiceGetByIdQuery, {
    variables: {
      invoiceId: props.invoiceId
    }
  })

  const {publicId, date, totalHT, totalTTC} = invoiceGetById

  return <div>
    <Card title="Détails">
      <table className="table  table-bordered">
        <tr>
          <th>Identifiant</th>
          <td>{publicId}</td>
        </tr>
        <tr>
          <th>Date</th>
          <td><DateFormat unixTimestamp={date} /></td>
        </tr>
        <tr>
          <th>Montant HT</th>
          <td>{totalHT && <CurrencyFormat value={totalHT} />}</td>
        </tr>
      </table>
    </Card>
  </div>
}
import { ClusterAdd } from "components/backoffice/HOSTING/Cluster/Cluster/Add/ClusterAdd"

import BootstrapBreadcrumb from "react-bootstrap/Breadcrumb"
import { Link } from "react-router-dom"
import { URL_HOSTING_CLUSTER_LIST } from "static/constant/backoffice/url"


export const ClusterAddRoute = (props: any) => {

  return <div>

    <BootstrapBreadcrumb>
      <BootstrapBreadcrumb.Item active>Hébergement</BootstrapBreadcrumb.Item>
      <BootstrapBreadcrumb.Item>
        <Link to={"/manager" + URL_HOSTING_CLUSTER_LIST}>
          Liste des Applications
        </Link>
      </BootstrapBreadcrumb.Item>
      <BootstrapBreadcrumb.Item active>Ajouter</BootstrapBreadcrumb.Item>
    </BootstrapBreadcrumb>


    <ClusterAdd />
  </div>

}
import { gql } from "@apollo/client"


// ${PhoneConfigFragment}
export default gql`

mutation phoneConfigEdit($id: String!, $input: PhoneConfigEditInput!) {
  phoneConfig: phoneConfigEdit(id: $id, input: $input) {
    id
    name
  }
}
`
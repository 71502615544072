import {gql} from '@apollo/client'

export default gql`

  fragment InvoiceListFragment on Invoice {
    id
    publicId
    status
    date
    totalTTC
    totalHT
    contactId
    contractId
    Contact {
      id
      lastname
    }
    Contract {
      id
      Customer {
        id
        name
      }
    }
  }
`
import { useQuery } from "@apollo/client"
import { IconEdit, IconSearch, IconTrash, Modal } from "@zipou/front_tools"
import customerGetGql from "graphql/BILLING/Customer/query/customerGet.gql"
import customerViewGql from "graphql/BILLING/Customer/query/customerView.gql"
import { Contact, Customer, Invoice, InvoiceStatusEnum, Order } from "model"
import { InvoiceStatus } from "../../Invoice/Status/InvoiceStatus"
import { CustomerEdit } from "../Edit/CustomerEdit"
import { useState } from "react"
import { URL_INVOICE_PDF } from "static/constant/backoffice/url"
import { findToken } from "helpers/User"
import { Vars } from "libs/Vars"
import { InvoiceEdit } from "../../Invoice/Edit/InvoiceEdit"
import { ContactEdit } from "../../Contact/Edit/ContactEdit"
import { OrderEdit } from "../../Order/Edit/OrderEdit"
import { OrderStatus } from "../../Order/Status/OrderStatus"

type CustomerViewProps = {
  customerId: string,
}

export const CustomerView = ({ customerId }: CustomerViewProps) => {

  const [focusEdit, updateFocusEdit] = useState(false)
  const [focusInvoiceView, updateFocusInvoiceView] = useState<string | null>()
  const [focusInvoiceEdit, updateFocusInvoiceEdit] = useState<Invoice | null>()
  const [focusContactEdit, updateFocusContactEdit] = useState<Contact | null>()
  const [focusOrderEdit, updateFocusOrderEdit] = useState<Order | null>()

  const { data } = useQuery<{ customer: Customer }>(customerViewGql, {
    variables: {
      id: customerId
    }
  })
  const { api_enpoint } = Vars

  const customer = data?.customer
  const invoiceList = customer?.Invoice
  const orderList = customer?.Order
  const contactList = customer?.Contact

  return <div>
    {focusEdit && <Modal display={focusEdit} title={`${customer?.name}`} onClose={() => updateFocusEdit(false)} >
      <CustomerEdit id={customerId} onDone={() => updateFocusEdit(false)} />
    </Modal>}
    {focusInvoiceView && <Modal display={!!focusInvoiceView} title="Aperçu" onClose={() => updateFocusInvoiceView(null)}>
      <object data={focusInvoiceView} type="application/pdf" style={{ width: '100%', height: '90vh', border: 0 }}>
        <embed src={focusInvoiceView} type="application/pdf" />
      </object>
    </Modal>}
    {focusInvoiceEdit && <Modal display={!!focusInvoiceEdit} title="Facture" onClose={() => updateFocusInvoiceEdit(null)}>
      <InvoiceEdit invoiceId={focusInvoiceEdit?.id || ""} onDone={() => updateFocusInvoiceEdit(null)} />
    </Modal>}
    {focusContactEdit && <Modal display={!!focusContactEdit} title="Contact" onClose={() => updateFocusContactEdit(null)}>
      <ContactEdit id={focusContactEdit?.id || ""} onDone={() => updateFocusContactEdit(null)} />
    </Modal>}
    {focusOrderEdit && <Modal display={!!focusOrderEdit} title="Bon de commande" onClose={() => updateFocusOrderEdit(null)}>
      <OrderEdit id={focusOrderEdit?.id || ""} onDone={() => updateFocusOrderEdit(null)} />
    </Modal>}
    <h1>{customer?.name}</h1>
    <div className="card mb-2">
      <div className="card-body">
        <button className="btn btn-sm btn-dark" onClick={() => updateFocusEdit(true)}>Modifier</button>
      </div>
    </div>
    <div className="card-columns">
      <div className="card">
        <div className="card-header">
          Contact
        </div>
        <div className="card-body">
          <table className="table table-stripped table-bordered">
            <tbody>
              {contactList?.map(contact => {
                return <tr key={`contact${contact?.id}`} style={{ whiteSpace: "nowrap" }}>
                  <td>{contact?.firstname} {contact?.lastname} </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <button className="btn btn-sm btn-warning ml-1" onClick={() => updateFocusContactEdit(contact)}>
                      <IconEdit />
                    </button>
                    <button className="btn btn-sm btn-danger ml-1">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card ">
        <div className="card-header">
          Bons de Commandes
        </div>
        <div className="card-body">
          <table className="table table-stripped table-bordered">
            <tbody>
              {orderList?.map(order => {
                return <tr key={`order_${order?.id}`}>
                  <td>{order?.publicId}</td>
                  <td><OrderStatus status={order?.status} /></td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <button className="btn btn-sm btn-warning" onClick={() => updateFocusOrderEdit(order)}>
                      <IconEdit />
                    </button>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          Factures
        </div>
        <div className="card-body">
          <table className="table table-stripped table-bordered">
            <tbody>
              {[...invoiceList || []]
                ?.sort((el1, el2) => el1.date > el2.date ? -1 : 1)
                ?.map(invoice => {

                  const viewUrl = URL_INVOICE_PDF.replace("${apiRoot}", api_enpoint).replace("${publicId}", invoice?.publicId).replace("${accessToken}", findToken()).replace("${stamp}", `${new Date().getTime()}`)

                  return <tr key={`invoice${invoice?.id}`}>
                    <td>{invoice?.publicId}</td>
                    <td><InvoiceStatus status={invoice?.status as InvoiceStatusEnum} /></td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <button className="btn btn-sm btn-dark ml-1" onClick={() => updateFocusInvoiceView(viewUrl)}>
                        <IconSearch />
                      </button>
                      <button className="btn btn-sm btn-warning ml-1" onClick={() => updateFocusInvoiceEdit(invoice)}>
                        <IconEdit />
                      </button>
                    </td>
                  </tr>
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

}
import {gql} from '@apollo/client'
import CardFragment from '../fragment/CardFragment'


export default gql`

  ${CardFragment}

  mutation cardAdd($stripeId: String!) {
    cardAdd(input: {
      stripeId: $stripeId
    }) {
      ...CardFragment
    }
  }
`
import gql from "graphql-tag";
import GuestFragment from "../fragment/GuestFragment";

export default gql`

  ${GuestFragment}

  query guestList {
    list: guestList {
      ...GuestFragment
    }
  }

`

import { gql } from "@apollo/client";
import ContactFragment from "../fragment/ContactFragment";

export default gql`

  ${ContactFragment}

  query contactGet($id: String!) {
    contact: contactGet(id: $id) {
      ...ContactFragment
    }
  }

`
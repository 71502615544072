import React, {useState} from "react";
// import {GoogleLogin} from "react-google-login"
import { GoogleLogin } from '@react-oauth/google';
// import {useMutation, useQuery} from "@apollo/react-hooks";

import {saveLoginToken} from "helpers/User";
// import userLoginByGoogleToken from "graphql/User/mutation/userLoginByGoogleToken";
// import userProfileQuery from "graphql/User/query/userProfile";

import './style.scss'
import userLoginByGoogleToken from "graphql/APP/User/mutation/userLoginByGoogleToken";
import {useMutation, useQuery} from "@apollo/client";
import userProfile from "graphql/APP/User/query/userProfile";

export const LoginForm = (props: any) => {

  const [mutate] = useMutation(userLoginByGoogleToken)
  const {refetch} = useQuery(userProfile)

  const [alert, updateAlert] = useState<string>("")


  const handleLogin = async (tokenId: string) => {
    const response = await mutate({
      variables: {
        token: tokenId,
      }
    })

    const {loginToken} = response?.data || {}
    const {token, expiration} = loginToken || {};

    saveLoginToken(token, expiration)
    refetch()
  }

  const handleGoogleLogin = async (payload: any) => {
    const {credential} = payload
    if (credential) {
      handleLogin(credential)
        .then((response: any) => {
          if (!response) {
            updateAlert("Oups, cette adresse ne semble pas être autorisée :(")
          }
        })
    }
  }


  return <div className='login-container'>
    {alert && <div className="col-12">
      <div className="alert alert-danger">{alert}</div>
    </div>}
    <div className="row">
      <div className="col-12">
        <div>
          <GoogleLogin onSuccess={handleGoogleLogin}
            auto_select={true}
            useOneTap={true}
            onError={() => {
              console.log('Login Failed');
            }}
          />
          {/* <GoogleLogin
            clientId={"68826268714-d89s9uc71e0kcuralh1fmq1vbqdmr5sg.apps.googleusercontent.com"}
            buttonText="Login"
            onAutoLoadFinished={(autoload) => console.log({autoload})}
            onSuccess={handleGoogleLogin}
            onFailure={((error: any) => console.log("ERROR", error))}
            cookiePolicy={'single_host_origin'}
            isSignedIn={true}
          // autoLoad={true}
          /> */}
        </div>
      </div>
    </div>
  </div >

}

import React from "react"
import { IpsecConfigForm } from "./IpsecConfigForm";
import { IconTrash } from "@zipou/front_tools";

type IpsecFormProps = {
  onChange: (datas: any) => void,
  options: any,
}

export const IpsecForm = (props: IpsecFormProps) => {

  const { options, onChange } = props;
  const { configList = [] } = options || {}

  return <div>
    {[...(configList || [])]
      ?.sort((el1: any, el2: any) => (el1?.id < el2?.id) ? -1 : 1)
      ?.map((config: any, index: number) => {
        return <div className="input-group" key={`config_${config?.id}`}>
          <div className="input-group-text">{index + 1}</div>
          <div className="form-control" style={{ height: "100%" }}>
            <IpsecConfigForm config={config} onChange={(config: any) => {
              onChange({
                configList: [
                  ...configList?.map((el: any) => (el.id !== config.id) ? el : config) || [],
                ]
              })
            }} />
          </div>
          <div className="input-group-text" onClick={() => {
            onChange({
              configList: [
                ...configList?.filter((el: any) => el.id !== config.id),
              ]
            })
          }}>
            <IconTrash />
          </div>
        </div>
      })}
    <div>
      <button className="btn btn-dark" onClick={() => onChange({
        configList: [
          ...configList,
          {
            id: Date.now(),
            type: "",
            localId: "",
            remoteId: "",
            enableDPD: "",
          }
        ]
      })}>Ajouter</button>
    </div>
  </div>
}
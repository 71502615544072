import gql from "graphql-tag";
import AddressFragmentGql from "graphql/BILLING/Address/fragment/AddressFragment.gql";
import ContactFragment from "graphql/BILLING/Contact/fragment/ContactFragment";

export default gql`

  ${AddressFragmentGql}
  ${ContactFragment}

  fragment CustomerFragment on Customer {
    id
    initials
    name
    Address {
      ...AddressFragment
    }
    Contact {
      ...ContactFragment
    }
  }
`
import gql from "graphql-tag";
import ClusterFragment from "../fragment/ClusterFragment";


export default gql`

  ${ClusterFragment}

  mutation clusterAdd($hostingConfigId: String!, $input: ClusterInput!) {
    cluster: clusterAdd(hostingConfigId: $hostingConfigId, input: $input) {
      ...ClusterFragment
    }
  }

`
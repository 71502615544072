import {gql} from "@apollo/client";
import CustomerFragment from "../fragment/CustomerFragment";

export default gql`

  ${CustomerFragment}

  mutation customerAdd($input: CustomerInput!) {
    customer: customerAdd(input: $input) {
      ...CustomerFragment
    }
  }

`
import { InvoiceStatusEnum } from "model"

type InvoiceStatusProps = {
  status: InvoiceStatusEnum | string,
}

export const InvoiceStatus = ({ status }: InvoiceStatusProps) => {

  switch (status) {

    case InvoiceStatusEnum.STATUS_PENDING:
    case "STATUS_PENDING": {
      return <span className="badge badge-warning">EN COURS</span>
    }
    case InvoiceStatusEnum.STATUS_ERROR:
    case "STATUS_ERROR": {
      return <span className="badge badge-danger">ERREUR</span>
    }
    case InvoiceStatusEnum.STATUS_PAID:
    case "STATUS_PAID": {
      return <span className="badge badge-success">PAYE</span>
    }
    case InvoiceStatusEnum.STATUS_DRAFT:
    case "STATUS_DRAFT": {
      return <span className="badge badge-dark">BROUILLON</span>
    }
    case InvoiceStatusEnum.STATUS_PAYMENT_WAITING:
    case "STATUS_PAYMENT_WAITING": {
      return <span className="badge badge-danger">EN ATTENTE</span>
    }
    case InvoiceStatusEnum.STATUS_CANCELLED:
    case "STATUS_CANCELLED": {
      return <span className="badge badge-danger">ANNULEE</span>
    }
    default: {
      return <span className="badge badge-danger">{status}</span>
    }

  }


}

import { Cursor } from "model"
import React from "react"
import BootstrapPagination from "react-bootstrap/Pagination"

type PaginationProps = {
  cursor?: Cursor,
  onChange: (page: number, limit?: number) => void
}

export const Pagination = (props: PaginationProps) => {

  const {cursor, onChange} = props
  if (!cursor) return <div></div>

  let {page, totalPages, limit} = cursor

  if (totalPages === 1) return <div></div>

  let options = []

  for (let i = 1; i <= totalPages; i++) {
    options.push(<option value={i} key={`option_${i}`}>Page {i}</option>)
  }

  return (totalPages > 1) ? <div>

    <BootstrapPagination>
      <BootstrapPagination.Prev disabled={page === 1} onClick={() => onChange(page - 1, limit)} key={'pagination_prev'} />
      <div className="d-flex">
        <select className="form-control" value={page} onChange={(e: any) => onChange(Number(e.target.value), limit)} style={{minWidth: 100}}>
          {options}
        </select>
        <select className="form-control" value={limit} onChange={(e: any) => {
          onChange(1, Number(e.target.value))
        }}>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="500">500</option>
        </select>

      </div>
      <BootstrapPagination.Next disabled={page === totalPages} onClick={() => onChange(page + 1, limit)} key={'pagination_next'} />
    </BootstrapPagination>
  </div> : <div />
}
import { IconEdit, Modal } from "@zipou/front_tools"
import { ClusterRoleEdit } from "../Edit/ClusterRoleEdit"
import { ClusterRole } from "model"
import { useState } from "react"


type ClusterRoleListProps = {
  clusterRoleList: ClusterRole[]
}

export const ClusterRoleList = ({ clusterRoleList }: ClusterRoleListProps) => {

  const [focusEdit, updateFocusEdit] = useState<string>()

  const clusterRoleListSorted = [...clusterRoleList || []]?.sort((el1, el2) => el1?.name?.localeCompare(el2?.name))

  return <div className="cluster-role-list-container">
    {!!focusEdit && <Modal display title="Modifier" onClose={() => updateFocusEdit("")}>
      <ClusterRoleEdit id={focusEdit} />
    </Modal>}
    <table className="table table-striped table-bordered">
      <thead>
        <tr className="bg-dark text-white">
          <td className="bg-dark text-white">Nom</td>
          <td className="bg-dark text-white">Actions</td>
        </tr>
      </thead>
      <tbody>
        {clusterRoleListSorted.map(clusterRole => {
          return <tr key={`role_${clusterRole?.id}`}>
            <td>{clusterRole?.name}</td>
            <td>
              <button className="btn btn-warning btn-sm ml-1" onClick={() => updateFocusEdit(clusterRole?.id)}>
                <IconEdit />
              </button>
            </td>
          </tr>
        })}
      </tbody>
    </table>

  </div>

}
import React from 'react'

import { Modal } from "@zipou/front_tools"

import './style.scss'
import notificationListQuery from 'graphql/Notification/query/notificationList'
import {useMutation, useQuery} from '@apollo/client'
import notificationQuestionAnswerMutation from 'graphql/Notification/mutation/notificationQuestionAnswer'

export const NotificationMenu = () => {

  const {data} = useQuery(notificationListQuery)
  const notificationList = data?.notificationList

  const [mutate, {loading}] = useMutation(notificationQuestionAnswerMutation, {
    notifyOnNetworkStatusChange: true,
  })

  const _handleAnswerQuestion = ({id, value}: {id: string, value: any}) => {
    mutate({
      variables: {
        id,
        value,
      },
      refetchQueries: [{
        query: notificationListQuery,
      }]
    })
    // // const {onQuestionAnswer} = this.props
    // onQuestionAnswer && onQuestionAnswer({
    //   id,
    //   value,
    // })
  }

  const _renderModalContent = (question: any) => {
    const {id, datas} = question
    // console.log({datas})
    const {label, choiceList} = datas || {}
    return <div>
      <h1>Hop hop hop</h1>
      <span>{label}</span>
      <div className='choice-list'>
        {choiceList && choiceList.map((choice: any, index: number) => {
          const {label, value} = choice
          return <div className="choice-item" key={index}>
            <button disabled={loading} onClick={() => _handleAnswerQuestion({id, value})}>{label}</button>
          </div>
        })}
      </div>
    </div>
  }

  // console.log({notificationList})
  const notificationUnreadList = notificationList && notificationList.filter((el: any) => el.status === 'STATUS_NEW')
  const nbNewNotification = (notificationUnreadList && notificationUnreadList.length) || 0
  const hasNewNotications = !!nbNewNotification

  const questionList = notificationUnreadList && notificationUnreadList.filter((el: any) => el.type === 'TYPE_QUESTION')
  const firstQuestion = questionList && questionList.length > 0 && questionList[0]
  const renderModal = !!(firstQuestion)

  // console.log({renderModal, firstQuestion, notificationUnreadList, })


  return (
    <div className="notification">
      {renderModal && <div>
        <Modal display={renderModal}>
          {_renderModalContent(firstQuestion)}
        </Modal>
      </div>}
      {hasNewNotications && <div>
        <span className="envelope"></span>
        <div className="count">
          <span>{nbNewNotification}</span>
        </div>
      </div>}
    </div>
  )

}

import {gql} from "@apollo/client";
import ClusterProvisioningTaskFragmentGql from "../fragment/ClusterProvisioningTaskFragment.gql";

export default gql`

  ${ClusterProvisioningTaskFragmentGql}

  subscription clusterProvisioningTaskUpdate($id: String!) {
    task: clusterProvisioningTaskUpdate(id: $id) {
      ...ClusterProvisioningTaskFragment
    }
  }
`
import ClusterFragment from "../fragment/ClusterFragment";
import gql from "graphql-tag";


export default gql`

  ${ClusterFragment}

  query clusterGet($id: String!) {
    cluster: clusterGet(id: $id) {
      ...ClusterFragment
    }
  }

`



import gql from "graphql-tag";
import GuestFragment from "../fragment/GuestFragment";

export default gql`

  ${GuestFragment}

  query guestSearch($name: String!) {
    list: guestSearch(name: $name) {
      ...GuestFragment
    }
  }

`

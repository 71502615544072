import { useQuery } from "@apollo/client"
import { PhoneConfigModule } from "@zipou/phone_front"
import phoneConfigGetTokenGql from "graphql/HOSTING/Phone/Config/query/phoneConfigGetToken.gql"

export const PhoneMainRoute = () => {

  const { data } = useQuery<{ phoneConfig: { id: string, token: string } }>(phoneConfigGetTokenGql)

  const hasData = data?.phoneConfig?.id && data?.phoneConfig?.token

  return <div>
    {hasData && <PhoneConfigModule
      phoneConfigId={data?.phoneConfig?.id}
      token={data?.phoneConfig?.token} moduleConfig={{
        url: process.env.REACT_APP_API_ENDPOINT
      }} />}
    {/* {hasData && <div>
      <PhoneAgentWrapper
        targetDevice="PJSIP/macbook"
        phoneConfigId={data?.phoneConfig?.id}
        token={data?.phoneConfig?.token}
        moduleConfig={{
          url: process.env.REACT_APP_API_ENDPOINT,
          wsUrl: process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_ENDPOINT
        }}>
        {(callerString, vars, isRinging) => {
          return <div>
            {isRinging && <div>
              Caller {callerString}
            </div>}
          </div>
        }}
      </PhoneAgentWrapper>
    </div>} */}
  </div>
}
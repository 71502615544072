import gql from "graphql-tag"
import UserLoginTokenFragment from "../fragment/userLoginTokenFragment"

export default gql`

  ${UserLoginTokenFragment}

  mutation userLoginByGoogleToken($token: String!){
    loginToken: userLoginByGoogleToken(token: $token) {
      ...UserLoginTokenFragment
    }
  }

`
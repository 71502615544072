import React, { useState } from "react"
import { YamlEditor } from "components/backoffice/Utils/YamlEditor/YamlEditor"
import { Modal } from "@zipou/front_tools"

type AlertManagerFormProps = {
  onChange: (datas: any) => void,
  config: any,
}

export const AlertManagerForm = (props: AlertManagerFormProps) => {

  const [showModal, updateShowModal] = useState(false)
  const { onChange, config } = props;

  const onContentChange = (content: string) => {
    onChange({
      ...config,
      content,
    })
    updateShowModal(false)
  }

  const { content = "" } = config || {}

  return <div>
    <Modal display={showModal} onClose={() => updateShowModal(false)} title={"alertmanager.yml"}>
      <YamlEditor content={content} onChange={onContentChange} />
    </Modal>
    <button className="btn btn-info" onClick={() => updateShowModal(!showModal)}>Editer la config</button>
  </div>
}
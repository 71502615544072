import { useApolloClient, useMutation, useQuery } from "@apollo/client"
import userProfileUpdateGql from "graphql/APP/User/mutation/userProfileUpdate.gql"
import { useEffect, useState } from "react"
import { Card } from "components/common/Card/Card"

import { Link } from "react-router-dom"
import { URL_PROFILE_IMPERSONATE } from "static/constant/backoffice/url"
import { ProfileForm } from "../Form/ProfileForm"

import userProfileQuery from 'graphql/APP/User/query/userProfile'
import userStopImpersonationMutation from 'graphql/APP/User/mutation/userStopImpersonation'
import { clearLoginCookie } from "libs/Cookie"

type ProfileEditProps = {
  user: any
}

export const ProfileEdit = (props: ProfileEditProps) => {

  const { refetch } = useQuery(userProfileQuery)
  const apolloClient = useApolloClient()

  const [stopImpersonationMutate] = useMutation(userStopImpersonationMutation, {
    refetchQueries: [
      {
        query: userProfileQuery,
      }
    ]
  })

  const stopImpersonation = async () => {
    await stopImpersonationMutate()
    await apolloClient.cache.reset()
    await apolloClient.clearStore()
    await apolloClient.resetStore()
    await refetch()
  }

  const handleLogout = async () => {
    clearLoginCookie();
    await refetch()
  }


  const [user, updateUser] = useState<any>(null)

  const [mutate] = useMutation(userProfileUpdateGql)

  const onSave = (e: any) => {
    e.preventDefault();

    const { Company } = user

    mutate({
      variables: {
        input: {
          Company: {
            name: Company?.name,
            logo: Company?.logo,
            siret: Company?.siret,
            tvaIntra: Company?.tvaIntra,
            Address: {
              address1: Company?.Address?.address1,
              address2: Company?.Address?.address2,
              zip: Company?.Address?.zip,
              town: Company?.Address?.town,
            },
            Rib: {
              iban: Company?.Rib?.iban,
              bic: Company?.Rib?.bic,
            }
          }
        },
      }
    })
      .then(() => {
        console.log("OK")
      })
      .catch(() => console.log("NOP"))
  }

  useEffect(() => {
    if (!user) {
      updateUser(props?.user)
    }
  }, [props?.user, user])

  return <div>
    <form onSubmit={onSave}>
      <ProfileForm user={user} onChange={(user: any) => {
        updateUser(user)
      }} />
      <div>

        <Card title="Actions">
          <button className="btn btn-dark btn-sm" type="submit" style={{ marginLeft: 5 }}>Enregistrer</button>
          <button className="btn btn-sm btn-warning" type="button" onClick={() => handleLogout()} style={{ marginLeft: 5 }}>Déconnexion</button>
          {user?.isAdmin && <Link to={"/manager" + URL_PROFILE_IMPERSONATE}><button className="btn btn-sm btn-danger" type="button" style={{ marginLeft: 5 }}>Impersonate</button></Link>}
          {user?.impersonator && <button className="btn btn-sm btn-danger" type="button" onClick={() => stopImpersonation()} style={{ marginLeft: 5 }}>{"Arreter l'impersonation"}</button>}
        </Card>
      </div>
    </form>
  </div>

}
import gql from "graphql-tag";
import ClusterConfigFragment from "graphql/HOSTING/Server/ClusterConfig/fragment/ClusterConfigFragment";

export default gql`

  ${ClusterConfigFragment}

  fragment ClusterFragment on Cluster {
    id
    name
    user
    password
    updateDate
    ClusterConfig {
      ...ClusterConfigFragment
    }
  }
`
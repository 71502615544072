import {gql} from '@apollo/client'

import OrderSearchFragment from 'graphql/BILLING/Order/fragment/OrderSearchFragment'
import InvoiceSearchFragment from 'graphql/BILLING/Invoice/fragment/invoiceSearchFragment'
import CustomerSearchFragment from 'graphql/BILLING/Customer/fragment/customerSearchFragment'
import ContactSearchFragment from 'graphql/BILLING/Contact/fragment/contactSearchFragment'
import ItemSearchFragment from 'graphql/BILLING/Item/fragment/ItemSearchFragment'

export default gql`
${InvoiceSearchFragment}
${OrderSearchFragment}
${ItemSearchFragment}
${ContactSearchFragment}
${CustomerSearchFragment}

query search($text: String){
  search(text: $text) @connection(key: "search"){
    ... on Invoice {
      ...InvoiceSearchFragment
  	}
    ... on Contact {
      ...ContactSearchFragment
  	}
    ... on Customer {
      ...CustomerSearchFragment
    }
    ... on Order {
      ...OrderSearchFragment
    }
    ... on Item {
      ...ItemSearchFragment
    }
  }
}

`
import {gql} from "@apollo/client";

export default gql`
  fragment InvoiceSearchFragment on Invoice {
    id
    publicId
    date
    status
    totalTTC
  }
`
import { useState } from "react"

import { useQuery, useMutation } from "@apollo/client"
import clusterRoleGet from "graphql/HOSTING/Cluster/ClusterRole/query/clusterRoleGet"
import ClusterRoleUpdate from "graphql/HOSTING/Cluster/ClusterRole/mutation/clusterRoleUpdate"

import { ClusterRoleForm } from "../Form/ClusterRoleForm"


type ClusterRoleEditProps = {
  id: string,
}

export const ClusterRoleEdit = ({ id }: ClusterRoleEditProps) => {

  const [clusterRole, updateClusterRole] = useState<any>(null)
  const [isUpdated, updateIsUpdated] = useState<boolean>(false)
  const [isLocked, updateIsLocked] = useState<boolean>(false)

  const [mutate] = useMutation(ClusterRoleUpdate)

  const { data } = useQuery(clusterRoleGet, {
    skip: !id,
    variables: {
      id: id,
    },
    onCompleted: (data) => {
      updateClusterRole(data?.clusterRole)
    }
  })

  const onChange = (clusterRole: any) => {
    updateIsLocked(false);
    updateIsUpdated(true);
    updateClusterRole(clusterRole)
  }

  const onReset = () => {
    updateIsUpdated(false);
    updateClusterRole(data?.clusterRole)
  }

  const onSubmit = () => {
    updateIsLocked(true)
    const { id, __typename, ...input } = clusterRole
    mutate({
      variables: {
        id,
        input,
      }
    })
      .then(() => {
        updateIsUpdated(false);
      })
      .catch(() => {
        updateIsLocked(false)
      })
  }

  return <div className="cluster-role-item-container">
    <h2>{clusterRole?.name}</h2>
    <ClusterRoleForm clusterRole={clusterRole} onChange={onChange} />
    {isUpdated && <div className="actions">
      <button className="btn btn-sm btn-dark" onClick={onSubmit} disabled={isLocked}>Enregistrer</button>
      <button className="btn btn-sm btn-danger" onClick={onReset}>Annuler</button>
    </div>}
  </div>
}
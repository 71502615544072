import React from 'react'

import {ProvisioningAction} from 'components/backoffice/HOSTING/Provisioning/Action/ProvisioningAction'
import {Breadcrumb} from "components/common/Navigation/Breadcrumb/Breadcrumb";

export class HostingProvisioningActionRoute extends React.Component {
  render() {
    return <div className="hosting-server-prov-route">
      {/* <Breadcrumb match={this.props.match} /> */}
      <ProvisioningAction/>
    </div>
  }
}

import { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"

import serverAddMutation from 'graphql/HOSTING/Server/mutation/serverAdd'
import { formatErrorResponseForJoi } from "helpers/Apollo"
import serverList from "graphql/HOSTING/Server/query/serverList"
import { ServerForm } from "../Form/ServerForm"
import { IconLoading } from "@zipou/front_tools"
import { ServerInput } from "model"


type ServerAddProps = {
  onDone: () => void,
}

export const ServerAdd = (props: ServerAddProps) => {

  const [mutate, { loading }] = useMutation(serverAddMutation, {
    refetchQueries: [{
      query: serverList
    }]
  })

  const [errors, updateErrors] = useState<any>(null)
  const [isLocked, updateIsLocked] = useState(false)
  const [server, updateServer] = useState<any>({})

  const onServerAdd = (server: ServerInput) => {
    return mutate({
      variables: {
        input: server
      }
    })
  }

  const onChange = (server: any) => {
    updateServer(server)
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    updateIsLocked(true)
    return onServerAdd({
      ...server,
    })
      .then(() => {
        props?.onDone()
      })
      .catch((e: any) => {
        updateIsLocked(false)
        updateErrors(formatErrorResponseForJoi(e))
      })
  }


  return <div className="server-edit-container">
    <div className="server-edit-form">
      <form onSubmit={onSubmit}>
        <ServerForm server={server} errors={errors} onChange={onChange} />
        <button className="btn btn-dark btn-sm" type="submit" disabled={isLocked}>
          {loading && <IconLoading />}
          <span className="ml-1">Enregistrer</span>
        </button>
      </form>
    </div>
  </div>
}

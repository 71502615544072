import {gql} from "@apollo/client";

export default gql`

fragment ClusterProvisioningTaskFragment on ClusterProvisioningTask {
  id
  status
  type
  serverId
  Server {
    id
    name
  }
  payload
}

`
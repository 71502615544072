import {gql} from '@apollo/client'
import OrderFragment from '../fragment/OrderFragment'

export default gql`

  ${OrderFragment}

  query orderGet($id: String!) {
    order: orderGet(id: $id) {
      ...OrderFragment
    }
  }
`
import {saveLoginCookie, getLoginCookie, clearLoginCookie} from "libs/Cookie";

import loginMutation from "graphql/APP/User/mutation/userLogin";
import profile from "graphql/APP/User/query/userProfile";
import userLoginByTokenMutation from "graphql/APP/User/mutation/userLoginByToken";

import {getClient} from "helpers/Apollo"

export const saveLoginToken = (token: string, expiration: string) => {
  saveLoginCookie({
    token,
    expiration
  })
}

export const handleLoginByToken = ({id, token}: {id: string, token: string}) => {
  return getClient().mutate({
    mutation: userLoginByTokenMutation,
    variables: {
      id,
      token,
    }
  })
    .then((response) => {
      const {data} = response || {};
      const {userLoginByToken} = data || {};
      const {token, expiration} = userLoginByToken || {};
      return new Promise<void>(resolve => {
        saveLoginCookie({
          token,
          expiration
        })
        resolve()
      })
        // })
        .then(() => {
          // console.log({token: findToken()})
          return getClient().query({
            query: profile,
            fetchPolicy: "network-only",
            context: {
              loginToken: token,
            }
          })
        })
    })
  // .then(() => {
  //   return apolloClient.query({
  //     query: profile,
  //     fetchPolicy: "network-only"
  //   })
  // })
}

export const handleLogin = ({email, password} : {email: string, password: string}) => {
  return getClient().mutate({
    mutation: loginMutation,
    variables: {
      email,
      password,
    },
  })
    .then((response) => {
      const {data} = response || {};
      const {userLogin} = data || {};
      const {token, expiration} = userLogin || {};
      return new Promise<void>(resolve => {
        saveLoginCookie({
          token,
          expiration
        })
        resolve()
      })
    })
    .then(() => {
      return getClient().query({
        query: profile,
        fetchPolicy: "network-only",
      })
    })
}

export const findToken = () => {
  const cookie = getLoginCookie();
  const {token} = cookie || {};
  return token;
}

export const handleLogout = () => {

  clearLoginCookie()
  return getClient().clearStore()
    .then(() => {
      return getClient().resetStore()
    })
    .then(() => {
      return getClient().query({
        query: profile,
        fetchPolicy: "network-only",
      })
    })
}

import React from 'react'
import { Link, matchRoutes, useLocation, useMatch, useMatches, useNavigation, useParams, useResolvedPath, } from 'react-router-dom'
import BootstrapBreadcrumb from "react-bootstrap/Breadcrumb"

import { URL_HOSTING_SERVER_PROV_ACTION, URL_HOSTING_TEMPLATE_LIST, URL_HOSTING_TEMPLATE_EDIT, URL_ORDER_UPDATE_PAYMENT_PREF_TEMPLATE, URL_ORDER_UPDATE_TEMPLATE, URL_HOSTING_SERVER_STAT, URL_HOSTING_ROOT, URL_HOSTING_SERVER_DETAILS, URL_HOSTING_SERVER_FIREWALL_EDIT, URL_BILLING_INVOICE_DETAILS_TEMPLATE, URL_BILLING_ROOT, URL_BILLING_INVOICE_DETAILS_PDF_TEMPLATE, URL_PHONE_ROOT, URL_PHONE_NUMBER_MANAGE, URL_PHONE_SCENARIO_LIST, URL_PHONE_SCENARIO_EDIT, URL_PHONE_SCENARIO_ADD, URL_HOSTING_TEMPLATE_ADD, URL_HOSTING_SERVER_EDIT, URL_HOSTING_PHONEHARD_LIST, URL_HOSTING_PHONEHARD_EDIT, URL_PHONE_SIP_PEER_LIST, URL_PHONE_NUMBER_LIST, URL_HOSTING_CLUSTER_LIST, URL_HOSTING_CLUSTER_EDIT, URL_HOSTING_CLUSTER_ADD } from "static/constant/backoffice/url"
import { URL_BACKOFFICE } from 'static/constant/url'


type BreadcrumbProps = {
  match?: any
}

export const Breadcrumb = (props: BreadcrumbProps) => {

  const location = useLocation()
  const match = props?.match
  const params = useParams()

  // console.log("match", match)
  // console.log("params", params)
  // console.log("location", location)

  switch (match?.path || location?.pathname) {

    case URL_HOSTING_CLUSTER_EDIT: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_PHONE_ROOT}>Téléphonie</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Téléphones</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Liste</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    case URL_HOSTING_PHONEHARD_LIST: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_PHONE_ROOT}>Téléphonie</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Téléphones</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Liste</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    case URL_HOSTING_PHONEHARD_EDIT: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_PHONE_ROOT}>Téléphonie</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Téléphones</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active><Link to={URL_HOSTING_PHONEHARD_LIST}>Liste</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Editer</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    case URL_PHONE_SIP_PEER_LIST: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_PHONE_ROOT}>Téléphonie</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Comptes</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    case URL_HOSTING_SERVER_EDIT: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_HOSTING_ROOT}>Hébergement</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Serveur</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Editer</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    case URL_HOSTING_SERVER_DETAILS: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_HOSTING_ROOT}>Hébergement</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Serveur</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Details</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    case URL_HOSTING_SERVER_PROV_ACTION: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_HOSTING_ROOT}>Hébergement</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Provision</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    case URL_HOSTING_SERVER_STAT: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_HOSTING_ROOT}>Hébergement</Link></BootstrapBreadcrumb.Item>
        {/* <BootstrapBreadcrumb.Item><Link to={URL_HOSTING_SERVER_DETAILS.replace(":serverId", params?.serverId)}>Serveur</Link></BootstrapBreadcrumb.Item> */}
        <BootstrapBreadcrumb.Item active>Statistiques</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }
    case URL_HOSTING_SERVER_FIREWALL_EDIT: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_HOSTING_ROOT}>Hébergement</Link></BootstrapBreadcrumb.Item>
        {/* <BootstrapBreadcrumb.Item><Link to={URL_HOSTING_SERVER_DETAILS.replace(":serverId", params?.serverId)}>Serveur</Link></BootstrapBreadcrumb.Item> */}
        <BootstrapBreadcrumb.Item active>Firewall</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }
    case URL_BILLING_INVOICE_DETAILS_PDF_TEMPLATE:
    case URL_BILLING_INVOICE_DETAILS_TEMPLATE: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_BACKOFFICE}>Mon Compte</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Facture</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Détails</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }
    case URL_ORDER_UPDATE_PAYMENT_PREF_TEMPLATE:
    case URL_ORDER_UPDATE_TEMPLATE: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_BACKOFFICE}>Mon Compte</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Bon de commande</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Détails</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }
    case URL_PHONE_NUMBER_MANAGE: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_PHONE_ROOT}>Téléphonie</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Gestion des numéros</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    case URL_PHONE_NUMBER_LIST: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_PHONE_ROOT}>Téléphonie</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Lister les numéros</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }
    case URL_PHONE_SCENARIO_LIST: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_PHONE_ROOT}>Téléphonie</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Liste des scénarios</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }
    case URL_PHONE_SCENARIO_EDIT: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_PHONE_ROOT}>Téléphonie</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item><Link to={URL_PHONE_SCENARIO_LIST}>Liste des scénarios</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Scénario</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }
    case URL_PHONE_SCENARIO_ADD: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_PHONE_ROOT}>Téléphonie</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item><Link to={URL_PHONE_SCENARIO_LIST}>Liste des scénarios</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Ajouter un Scénario</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }
    case URL_HOSTING_TEMPLATE_ADD:
    case URL_HOSTING_TEMPLATE_EDIT: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_HOSTING_ROOT}>Hébergement</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item><Link to={URL_HOSTING_TEMPLATE_LIST}>Liste des template</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Editer un template</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }
    case URL_HOSTING_TEMPLATE_LIST: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_HOSTING_ROOT}>Hébergement</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Liste des template</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    case URL_HOSTING_CLUSTER_LIST: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_HOSTING_ROOT}>Hébergement</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Liste des Applications</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    case URL_HOSTING_CLUSTER_ADD: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_HOSTING_ROOT}>Hébergement</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item><Link to={URL_HOSTING_CLUSTER_LIST}>Liste des Applications</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Ajouter</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    case URL_HOSTING_CLUSTER_EDIT: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_HOSTING_ROOT}>Hébergement</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item><Link to={URL_HOSTING_CLUSTER_LIST}>Liste des Applications</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Editer</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    default: <div />
  }

  return <div />
}

/*

 <BootstrapBreadcrumb>
  <BootstrapBreadcrumb.Item href="#">Home</BootstrapBreadcrumb.Item>
  <BootstrapBreadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
    Library
  </BootstrapBreadcrumb.Item>
  <BootstrapBreadcrumb.Item active>Data</BootstrapBreadcrumb.Item>
</BootstrapBreadcrumb>

*/
import { Link } from "react-router-dom"
import React from "react"
import { Table } from 'components/common/Table/Table'
import { URL_HOSTING_TEMPLATE_ADD, URL_HOSTING_TEMPLATE_EDIT } from "static/constant/backoffice/url"

export class TemplateList extends React.Component {
  render() {
    const { templateList } = this.props
    return <div className="template-list-container">
      <h1>List Template</h1>
      <div className="server-list-container">
        <Table
          list={templateList}
          fields={{
            name: {
              label: 'Nom',
            },
            type: {
              label: 'Type',
            },
            version: {
              label: 'Version',
            },
            actions: {
              label: 'Actions',
              format: (row) => <div className="actions">
                <Link to={URL_HOSTING_TEMPLATE_EDIT.replace(":templateId", row.id)}><button>Editer</button></Link>
              </div>
            }
          }}
        />
        <Link to={URL_HOSTING_TEMPLATE_ADD}><button>Ajouter</button></Link>
      </div>
    </div>
  }
}

import { useQuery } from "@apollo/client"
import { Pagination } from "components/common/Navigation/Pagination/Pagination"
import guestListGql from "graphql/WEDDING/Guest/query/guestList.gql"
import pictureListGql from "graphql/WEDDING/Picture/query/pictureList.gql"
import { Cursor, Guest, Picture, PictureCategoryEnum, PictureListInput } from "model"
import { useState } from "react"
import { translateCategory } from "../Picture/Category"
import { PictureDisplay } from "../Picture/Display/PictureDisplay"

type GaleryProps = {

}

export const Galery = (props: GaleryProps) => {

  const [pictureListInput, updatePictureListInput] = useState<PictureListInput>()

  const { data: guestData } = useQuery<{ list: Guest[] }>(guestListGql)

  const { data, refetch } = useQuery<{ list: { nodes: Picture[], cursor: Cursor, input: any } }>(pictureListGql, {
    variables: {
      cursor: {
        page: 1,
        limit: 10,
      },
      input: pictureListInput
    }
  })

  const pictureList = data?.list?.nodes
  const cursor = data?.list?.cursor
  const guestList = guestData?.list
  const hasPicture = pictureList?.length && pictureList?.length > 0

  return <div className="galery">

    <div className="pt-1 pb-1">
      <select value={pictureListInput?.guestId || ""} className="form-control" onChange={e => {
        updatePictureListInput({
          ...pictureListInput,
          guestId: e.target.value,
        })
      }}>
        <option value="">Tous les Invités</option>
        {guestList?.map(g => {
          return <option key={`guest_${g?.id}`} value={g?.id}>{g.fullName}</option>
        })}
      </select>
    </div>
    <div className="pt-1 pb-1">
      <select value={pictureListInput?.category || ""} className="form-control" onChange={e => {
        updatePictureListInput({
          ...pictureListInput,
          category: e.target.value,
        })
      }}>
        <option value="">Filtrer par Categorie</option>
        {Object.keys(PictureCategoryEnum).map(k => {
          return <option key={`k-${k}`} value={k}>{translateCategory(k)}</option>
        })}
      </select>
    </div>
    {!hasPicture && <div className="mt-2 alert alert-info">Aucune photo pour le moment :)</div>}
    <div className="row">
      {pictureList?.map(picture => {
        return <PictureDisplay picture={picture} key={`picture_${picture?.id}`} />
      })}

    </div>
    <div>
      <Pagination cursor={cursor} onChange={page => {
        refetch({
          input: pictureListInput,
          cursor: {
            page,
            limit: cursor?.limit
          }
        })
      }} />
    </div>

  </div>
}
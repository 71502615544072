import React from "react";
import Helmet from "react-helmet";

import BootstrapContainer from "react-bootstrap/Container"

export class MainContainerComponent extends React.Component {

  render() {
    const {children} = this.props
    return (
      <div className="main-container">
        <Helmet>
          <title>box4b</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=no"></meta>
        </Helmet>
        {children}
      </div>
    )
  }
}

export const MainContainer = MainContainerComponent

export const Container = (props) => <BootstrapContainer {...props}>{props.children}</BootstrapContainer>

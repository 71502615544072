import { gql } from "@apollo/client";
import CursorFragment from "graphql/Cursor/fragment/CursorFragment";
import TransactionFragment from "../fragment/TransactionFragment";

export default gql`

  ${TransactionFragment}
  ${CursorFragment}

  query transactionList($input: TransactionListInput!, $cursor: CursorInput!) {
    list: transactionList(input: $input, cursor: $cursor) {
      nodes {
        ...TransactionFragment
      }
      cursor {
        ...CursorFragment
      }
      input
    }
  }


`
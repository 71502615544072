import ClusterListFragment from "../fragment/ClusterListFragment";
import gql from "graphql-tag";


export default gql`

  ${ClusterListFragment}

  query clusterList {
    list: clusterList {
      ...ClusterListFragment
    }
  }

`



import gql from "graphql-tag";

import ClusterRoleFragment from "../fragment/ClusterRoleFragment"

export default gql`

  ${ClusterRoleFragment}

  query clusterRoleGet($id: String!) {
    clusterRole: clusterRoleGet(id: $id) {
      ...ClusterRoleFragment
    }
  }

`
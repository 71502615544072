import React from "react"
import { InvoiceEdit } from "components/backoffice/BILLING/Invoice/Edit/InvoiceEdit";

export class InvoiceEditRoute extends React.Component {

  render() {
    return (
      <InvoiceEdit />
    )
  }
}
import React from "react"
import {Route} from "react-router-dom"

import {PaymentDetails} from "components/backoffice/BILLING/Payment/Details/PaymentDetails"
import {Card3dSecure} from "components/backoffice/BILLING/PaymentPref/Card/Card3dSecure"
import {URL_PAYMENT_DETAILS_TEMPLATE, URL_PAYMENT_DETAILS_3D_SECURE_TEMPLATE} from "static/constant/backoffice/url"
import { Modal } from "@zipou/front_tools"

import {StripeContainer} from "components/backoffice/BILLING/StripeContainer"

export const PaymentDetailsRoute = props => {

  const {match, history} = props
  const {params, path} = match || {}
  const {paymentId} = params

  return <div>
    <h1>Détails du Paiement</h1>
    <Route path={URL_PAYMENT_DETAILS_3D_SECURE_TEMPLATE}>
      <Modal display={true} title="3D Secure" onClose={() => history.push(URL_PAYMENT_DETAILS_TEMPLATE.replace(':paymentId', paymentId))}>
        <StripeContainer>
          <Card3dSecure paymentId={paymentId} />
        </StripeContainer>
      </Modal>
    </Route>
    <PaymentDetails paymentId={paymentId} />
  </div>
}
import { CustomerAdd } from "components/backoffice/BILLING/Customer/Add/CustomerAdd"
import { CustomerEdit } from "components/backoffice/BILLING/Customer/Edit/CustomerEdit"
import { CustomerList } from "components/backoffice/BILLING/Customer/List/CustomerList"
import { Modal } from "@zipou/front_tools"
import { Route, Routes, useNavigate } from "react-router-dom"
import { URL_BILLING_CUSTOMER_LIST } from "static/constant/backoffice/url"


export const CustomerListRoute = (props: any) => {

  const match = props?.match;
  const params = match?.params
  const id = params?.id

  const navigateHook = useNavigate()

  const navigate = (target: string) => {
    navigateHook(`/manager${target}`)
  }

  return <div>
    <div className="card">
      <div className="card-header">
        Liste des clients
      </div>
      <div className="card-body">
        <CustomerList />
      </div>
    </div>
  </div>
}
import {GraphiQL} from "components/backoffice/Graphql/GraphiQL";
import React, {Suspense} from "react";

export default class GraphiqlRoute extends React.Component {


  render() {
    return (
      <div className="">
        <Suspense fallback={<div>Chargement...</div>}>
          <GraphiQL />
        </Suspense>
      </div>
    )
  }
}

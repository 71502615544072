export default ({companyName, companyWebsite, companySiret, companyAddress, companyContact}: {companyName: string, companyWebsite: string, companySiret: string, companyAddress: string, companyContact: string}) => `Les présentes sont conclues entre :

+ La société ${companyName}, EURL, société de droit français (SIRET : ${companySiret}), représentée par M. Romain Fenninger, ci dessous : ${companyName}

et

+ Toute personne physique ou personne morale souhaitant s’engager dans une ou plusieurs des prestations fournies par la société ${companyName}, ci-après nommée le Client.

Définitions :
-------------

**Assistance technique** : Service chargé de conseiller le Client lors de l'installation et de l'utilisation de son Service par la fourniture de conseils techniques ou le cas échéant de la documentation adéquate.
**Client** : Personne physique ou morale, signataire des conditions contractuelles générales et particulières de ${companyName} pour tout service souscrit auprès de ${companyName} y compris à titre gratuit.
**Documentation** : ${companyName} met à la disposition du Client un ensemble d'outils et documentations accessibles sur le site ${companyName}.
**Données** : Fichiers informatiques de tout type appartenant au Client et pouvant être stockés par l’intermédiaire d'un ou des services proposées par la société ${companyName} .
**Identifiant Client** : Compte Client pour tout service souscrit auprès de ${companyName} et permettant l'accès à l'interface de gestion.
**Interface box4b** : Application Web (site internet) fourni par box4b permettant au Client d'accéder et de modifier la configuration des différents services souscrits.
**Assistance web & mail** : Service de support consistant en la mise en relation via Internet du Client et des techniciens de la societe ${companyName}.
**Assistance téléphonique** : Service de support consistant en la mise en relation via téléphone du Client et des techniciens de la societe ${companyName}.
**Maintenance distante** : Maintenance effectuée sur le matériel ou logiciel du Client sans intervention sur site d'un technicien de la societe ${companyName}.
**Installation sur site** : Prestation d'installation et de configuration des différents services souscrits par le Client fourni par ${companyName} sur le site du Client.
**Maintenance sur site** : Maintenance effectuée sur le matériel ou logiciel du Client avec intervention sur site du Client d'un technicien de la societe ${companyName}.
**Supervision complète** : Service permettant la détéction et la prise en charge proactive par les équipes de support de la société ${companyName} des erreurs ou problèmes liés à une exception dans le fonctionnement normal d'un service fourni par la société ${companyName}.

Article 1 : Objet
----------------

Le Client reconnaît avoir vérifié que le  ou les services auquels il souscrit correspond à ses besoins et reconnait également avoir reçu de ${companyName} toutes les informations et conseils qui lui étaient nécessaires pour souscrire ces services en connaissance de cause.
Les présentes ont pour objet de définir les conditions techniques et financières dans lesquelles le Client s'engage auprès de ${companyName}.
Les présentes conditions générales de service complétées le cas échéant par des conditions particulières et/ou annexes proposées par ${companyName} sont applicables, à l'exclusion de toutes autres conditions et notamment celles du Client, à toute commande par le Client de prestations de la société ${companyName}.

Article 2 : Description des Services
------------------------------------

### BOX4 PHONE

#### Description du Service

Le service BOX4B PHONE propose la fourniture d'un service de Téléphonie IP (sur Internet). Ce service peut être accessible via un materiel mis à disposition par la société ${companyName}, ou un materiel compatible en possession du Client , ou bien utilisé directement via des Logiciels de VOIP (ou SOFTPHONE) compatible avec le protocole SIP (non fourni par ${companyName} ).

##### Dans le cas où le matériel n'est pas mis à disposition par la société ${companyName}
Le service est caractérisé par la mise a disposition au client d'une ou plusieurs lignes téléphoniques (identifiées par adresse du serveur, login, mot de passe).

##### Dans le cas où le matériel est mis à disposition par la société ${companyName}
Le service est caractérisé par la mise à disposition au client d'un ou plusieurs téléphones associés chacun à une ou plusieurs lignes téléphoniques.

##### Dans les deux cas précédents
Le service est également caractérisé par la fourniture de service complémentaires associés directement aux lignes téléphoniques. Services comme la file d'attente, la mise à disposition d'un répondeur, la fonctionalité dite 'callback' (sous conditions), la fonctionalité 'CTI' (sous conditions)

#### Conditions particulières du service

##### Tarif

Les prix des services fournis par ${companyName} au titre du service BOX4B PHONE font l'objet de plusieurs tarifs établis en fonction de la nature des prestations fournies. Les tarifs en vigueur sont disponibles en consultation en ligne sur le site ${companyWebsite}

Les abonnements et prestations proposés sont mentionnés dans le bon de commande ; ils s'entendent toutes taxes comprises et sont payables en euros, d'avance lors de l'enregistrement du bon de commande ou après réception via e-mail de l'URL vers la facture pro-forma du Client.
${companyName} se réserve la faculté de modifier ses prix à tout moment, sous réserve d'en informer le Client par courrier électronique ou par un avertissement en ligne sur le site ${companyWebsite} un mois à l'avance si les nouveaux tarifs sont moins favorables au Client. 
Suite à cette information le Client sera libre de résilier le contrat, dans les conditions précisées dans l'article 16 des présentes.
A défaut, le Client sera réputé avoir accepté les nouveaux tarifs. Les modifications de tarifs seront applicables à tous les contrats et notamment à ceux en cours d'exécution.

${companyName} se réserve le droit de répercuter, sans délai, toute nouvelle taxe réglementaire, administrative ou légale ou toute augmentation de taux des taxes existantes.

Toute souscription du Service (sauf conditions particulières) nécessite le paiement préalable de frais d'installation en complément du coût éventuel de l'offre choisie.

La grille tarifaire pour les destinations géographiques non comprises dans les forfaits, ainsi que les tarifs correspondants au forfait à la seconde sont susceptibles d'être modifiés à tout moment, ceux-ci étant soumis à la tarification d'opérateurs tiers.
${companyName} recommande au Client de consulter régulièrement la page de tarification accessible sur le site pour prendre connaissance des derniers tarifs applicables.

##### Renouvellement
Le contrat souscrit par le Client est automatiquement renouvelé pour une durée d'un mois à compter de la date d'expiration de la période initialement souscrite, le paiement est
automatiquement effectué sur la carte ou le compte bancaires du Client, Le Client peut toutefois demander le non renouvellement de son abonnement offre téléphonique par l’intermédiaire de son interface de gestion. Le service sélectionné ne sera pas renouvelé à l’issue de la période en cours d’exécution au moment de la demande formulée par le Client.

##### Conditions Spécifiques à la fonctionalité CTI
Cette fonctionnalité est caractérisée par le fourniture d'une plateforme logicielle permettant l'interconnexion du système de téléphonique avec un logiciel ou site web utilisé par le Client
Afin d'assurer l'interconnexion, une passerelle logiciel peut-être nécéssaire. Dans ce cas, la fourniture de la passerelle par BOX4B n'est pas garanti ni compris dans l'offre BOX4B PHONE et fera donc l'objet de conditions spécifiques.

##### Conditions Spécifiques à la fonctionalité Callback
Cette fonctionnalité est caractérisée par le fourniture d'une plateforme logicielle permettant au Client dd'afficher sur son propre site Internet une fonctionnalité permettant l'Appel Automatique d'un client sur une ligne téléphonique de l'offre BOX4B PHONE afin d'être contacté plus simplement.
Afin d'assurer l'interconnexion avec le site internet du Client, une passerelle logiciel peut-être nécéssaire. Dans ce cas, la fourniture de la passerelle par BOX4B n'est pas garanti ni compris dans l'offre BOX4B PHONE et fera donc l'objet de conditions spécifiques.

### BOX4 BACKUP

#### Description du Service

Le service BOX4B BACKUP permet l'utilisation d'un système de sauvegarde comprenant un espace de sauvegarde distant sécurisé ainsi qu'un client (logiciel de sauvegarde) permettant la remontée des données a sauvegardées.
Les informations liées à l'utilisation de ce système se feront directement via l'interface de gestion.

#### Conditions particulières du service

##### Transfert de Licence

L'utilisation de ce service implique la validation d'un contrat de licence particulier entre le prestataire de service utilisé par la societe ${companyName} et le Client. Cette licence liée à l'utilisation du logiciel de remontée des données et de l'utilisation de l'infrastructure de stockage du prestataire.
La validation de cette licence s'effectue dès l'installation de l'outil sur le ou les postes concerncés par le système de sauvegarde.

### BOX4 INTERNET

#### Description du Service

Le service INTERNET, est un ensemble de services et prestations fournis par la société BOX4B ayant pour but d'assurer la mise à disposition d'un nom de domaine (via dépôt ou transfert), l'hébergement d'un site internet (ou son transfert), ainsi que la fourniture d'un service d'hébergement d'adresses emails associées aux noms de domaine.

#### Conditions particulières du service

##### Procuration et Délégation

Dans le but d'assurer au client une totale indépendance, par l'accès à ce service, le client autorise la société BOX4B à collecter et utiliser ses informations (Nom, Société, Adresse Email) afin de créer un compte auprès d'un fournisseur tiers (ou REGISTAR) ayant pour objectif de lui assurer la pleine propriété du nom de domaine ou des adresses emails en son nom propre.

### BOX4 MAINTENANCE

#### Description du Service

Le service est caractérisé par la fourniture au Client d'un service d'entretien, de dépannage et de maintien en bon état de fonctionnement de l'infrastructure informatique du Client.
Il est précisé que ce service ne comprend pas la maintenance ou le remplacement des supports magnétiques tels que disques, cartouches, disquettes
Ce service peut comprendre une maintenance préventive, curative et évolutive ainsi que des services d'entretien et de dépannage en fonction du niveau de service selectionné par le client

##### Maintenance préventive

En fonction du niveau de service souscrit par le Client, elle consiste en la fourniture ou la mise en place direct de bonnes pratiques de configuration permettant d'assurer une sécurité optimale de l'infrastructure du client (Poste de Travail, Serveurs, Matériel Réseau).

##### Maintenance curative

Elle sera appliquée dans la mesure où le système présente des anomalies de fonctionnement ou lorsque la panne est de la responsabilité du client (problèmes d’alimentation électrique, panne d’onduleur, erreur d’exploitation...).
En fonction du niveau de service souscrit par le Client, ce service se caractérise par :
+ un diagnostic (à distance via messagerie, téléphone, ou sur le site du Client)
+ l’intervention (à distance via messagerie, téléphone, ou sur le site du Client) pour réparation de toutes pannes matérielles (pièces non comprises) ou logicielle .

##### Maintenance évolutive

Elle comprend :
+ Les améliorations liées aux évolutions de l’environnement technique ou de la normalisation 

##### Entretien
${companyName} assure un contrôle périodique afin de vérifier la bonne marche du matériel et effectuera à cette occasion les opérations d'entretien courant éventuellement nécessaires. Le technicien chargé de l'entretien procédera aux vérifications, réglages, nettoyages et, le cas échéant, au remplacement des pièces défectueuses ou inutilisables à la suite d'un usage normal du matériel. 
La périodicité de ces visites de contrôle sera fixée par le fournisseur en fonction des nécessités techniques et du niveau de service selectionnés par le client.

#### Conditions particulières du service

##### Registre des anomalies
Le client devra tenir un registre sur lequel il devra consigner toutes les anomalies, incidents ou pannes concernant le matériel; il devra, en outre, indiquer dans ce registre tous les faits ayant entraîné ou susceptibles d'entraîner une anomalie dans le bon fonctionnement.

##### Accès au matériel
Dans le cas d’un déplacement d’un technicien ou dans celui d’une intervention à distance, le client s'engage à laisser au technicien le libre accès au matériel couvert lors de la souscription du service; il lui laissera un espace suffisant, lui assurera l'assistance nécessaire, et devra notamment mettre à sa disposition les opérateurs et le matériel nécessaires à l'exécution des travaux de réparation.
Les interventions seront effectuées par les techniciens délégués par le fournisseur, pendant les heures normales de travail correspondant à 8 heures consécutives entre 10 h et 19 h les jours ouvrables du lundi au vendredi. Si les interventions sont effectuées en dehors des heures normales de travail, ou les samedis, dimanches ou jours fériés, les déplacements et interventions pourront être facturés en sus, suivant les tarifs en vigueur.
Au cas où le technicien envoyé par le fournisseur ne pourrait avoir accès au matériel du fait du client, le temps passé par le technicien serait alors facturé en supplément.

##### Obligations du client
Le client s'engage à respecter les conditions normales d'utilisation du matériel et à appliquer strictement toutes les instructions données par le fournisseur.
Le matériel, les équipements et leur installation devront être conformes aux normes fournies par le fournisseur, ainsi que le local, les installations électriques et les supports d'information.
Le matériel ne pourra être modifié, déplacé, réparé par des tiers sans l'autorisation préalable écrite du fournisseur.
Les fournitures utilisées avec le matériel (papier, bandes, disquettes…) devront correspondre strictement aux fournitures agréées par le fournisseur. En cas de non-respect de l'une des dispositions du contrat ou des obligations précisées, le fournisseur pourra mettre fin au présent contrat en respectant un préavis d'un mois.

### BOX4 STORAGE

#### Description du Service
Le service BOX4B STORAGE consiste en la mise à disposition d'un système de stockage (ou "conteneur") sécurisé et chiffré ainsi qu'un mécanisme (ou logiciel) permettant l'envoi des données identifiées par le client comme cible du service de stockage.

#### Conditions particulières du service

##### Choix du système

Le système de stockage peut être mis à disposition directement dans l'infrastructure de ${companyName}, physiquement à l'aide d'un système autonome BOX4B BOX, ou bien directement dans l'infrastrucutre d'un fournisseur tiers de confiance sous traité par ${companyName}.
Ce choix sera effectué par le Client directement à la validation de son offre.

##### Dans le cas d'un système de stockage autonome ${companyName}

La société ${companyName} met à disposition un système autonome permettant le stockage local des données identifiées par le client comme cible de stockage.

##### Dans le cas d'un système de stockage hébergé dans l'infrastructure ${companyName}

Le système de chiffrage fonctionne par la fourniture d'une chaine de caractère (ou "clé" ou "mot de passe") par le client verrouillant l'accès à son propre conteneur.
Pour des raisons de sécurité, la société ${companyName} possède sa propre clé "maitresse" permettant également le deverrouillage du conteneur. Cependant, la société ${companyName} s'engage à ne l'utiliser qu'avec l'accord explicite du client dans le cas d'une perte ou vol de sa clé personelle.
${companyName} s’engage à ne pas intervenir sur les données du Client en dehors de la faculté de dupliquer les contenus de l’espace de stockage au sein de l’infrastructure à la seule fin de garantir la redondance du service en cas d’interruption éventuelle.

### BOX4 BOX

#### Description du Service

Ce service consiste en une une mise à disposition d'un ou plusieurs services BOX4B comme énoncé ci-dessus (INTERNET, STORAGE, BACKUP) sur un matériel physique (ou BOX) directement fourni par la société ${companyName} à des fins de stockage sur le site du client.

### Conditions Spécifiques au niveau de services souscrit par le client

L'application des services énoncées ci-dessus sont conditionnées au niveau de service (pas d'accompagnement, standard, complet) souscrit par le client lors de la commande du ou des services.

+ Service compris dans le cas du niveau de service "pas d'accompagnement" :
    + interface box4b
    + assistance web & mail
+ Service compris dans le cas du niveau de service "standard" :
    + interface box4b
    + assistance web & mail
    + assistance téléphonique
    + maintenance distante
    + installation sur site
+ Service compris dans le cas du niveau de service "complet" :
    + interface box4b
    + assistance web & mail
    + assistance téléphonique
    + maintenance distante
    + installation sur site
    + maintenance sur site
    + supervision totale
    
Tout service demandés en dehors du niveau de service séléctionnés par le client feront l'objet d'une facturation en sus après qu'un devis estimatif soit validé par le Client.

Article 3 : Obligations de ${companyName?.toUpperCase()}
---------------------------------------------------

${companyName} s'engage à apporter tout le soin et toute la diligence nécessaires à la fourniture des différents services decrit à l'article 2 de manière qualitative et conformément aux usages de la profession et à l'état de l'art. 
${companyName} ne répond que d'une obligation de moyen.

Article 4 : Responsabilité de ${companyName?.toUpperCase()}
------------------------------------------------------

La responsabilité de ${companyName} ne sera pas engagée :
+ Si l'exécution de toute obligation incombant à ${companyName} au titre des présentes, est empêchée, limitée ou dérangée du fait d'incendie, explosion, défaillance des réseaux de transmission, effondrement des installations, épidémie, tremblement de terre, inondation, panne d'électricité, guerre, embargo, loi, injonction, demande ou exigence de tout gouvernement, grève, boycott retrait d'autorisation de l'opérateur de télécommunication, ou autre circonstance hors du contrôle raisonnable de ${companyName} ("Cas de Force Majeure"), alors ${companyName}, sous réserve d'une prompte notification au Client, devra être dispensée de l'exécution de ses obligations dans la limite de cet empêchement, limitation ou dérangement, et le Client sera de la même manière dispensé de l'exécution de ses obligations dans la mesure où les obligations de cette partie sont relatives à l'exécution ainsi empêchée, limitée ou dérangée, sous réserve que la partie ainsi affectée fasse ses meilleurs efforts pour éviter ou pallier de telles causes d'inexécution et que les deux parties procèdent avec promptitude dès lors que de telles causes auront cessé ou été supprimées. La partie affectée par un Cas de Force Majeure devra tenir l'autre partie régulièrement informée par courrier électronique des pronostics de suppression ou de rétablissement de ce Cas de Force Majeure.
+ Si les effets d'un Cas de Force Majeure devaient avoir une durée supérieure à 30 jours, à compter de la notification du cas de force majeure à l'autre partie, le contrat pourra être résilié de plein droit à la demande de l'une ou l'autre partie, sans droit à indemnité de part et d'autre.

Soit encore du fait du Client, notamment dans les cas ci-après :
+ mauvaise utilisation des services par le Client ou par les utilisateurs auxquels il a permis l'accès, faute, négligence, omission ou défaillance de sa part, non-respect des conseils donnés, divulgation ou utilisation illicite du mot de passe créé par le Client,
+ faute, négligence ou omission d'un tiers sur lequel ${companyName} n'a aucun pouvoir de contrôle ou de surveillance,
+ demande d'interruption temporaire ou définitive du Service émanant d'une autorité administrative ou judiciaire compétente, ou notification d'un tiers titulaires de droits,
+ destruction partielle ou totale des informations transmises ou stockées à la suite d'erreurs imputables directement indirectement au Client. 
+ non-respect total ou partiel d'une obligation et/ou défaillance des opérateurs des réseaux de transport vers le monde Internet et en particulier du ou des fournisseurs d'accès du Client.

Les réparations dues par ${companyName} en cas de défaillance d'un ou plusieurs services souscrits par le Client et qui résulterait d'une faute établie à son encontre correspondront au préjudice direct, personnel et certain lié à la défaillance en cause, à l'exclusion expresse de tout dommage indirect tel que, notamment, préjudice commercial, perte de commandes, atteinte à l'image de marque, trouble commercial quelconque, perte de bénéfices ou de Clients (par exemple, divulgation inopportune d'informations confidentielles les concernant par suite de défectuosité ou de piratage du système, action d'un tiers contre le Client, etc.).

En tout état de cause, le montant des dommages et intérêts qui pourraient être mis à la charge de ${companyName}, si sa responsabilité était engagée, sera limité au montant des sommes effectivement versées par le Client à ${companyName} pour la période considérée ou facturées au Client par ${companyName} dans la limite de 6 mois ou au montant des sommes correspondant au prix de la prestation, pour la part du Service pour laquelle la responsabilité de ${companyName} a été retenue. Sera pris en considération le montant le plus faible de ces sommes. 
Dans le cadre d’une offre gratuite, ce montant sera automatiquement limité à dix (10) euros TTC. Toute action en responsabilité menée par le Client à l'encontre de ${companyName} devra impérativement être introduite dans un délai maximum de 12 mois à compter du fait générateur du dommage. Au delà, aucune action ne pourra être introduite à l'encontre de ${companyName}.

Si ${companyName} est amené dans le cadre d'un service à prendre toute mesure de protection requise pour la gestion de son infrastructure, il appartient au Client de prendre toutes mesures nécessaires à la sauvegarde de ses données ou de celles des utilisateurs autorisés à accéder à son espace de stockage en cas de perte, d’altération ou de détérioration des données confiées, quelle qu'en soit la cause, y compris celle non expressément visée par les présentes. De même, l’ensemble des coûts pouvant intervenir pour la récupération de données demeure à la charge exclusive du Client. La responsabilité de ${companyName} ne pourra en aucun cas être recherchée du fait de la perte ou de la détérioration de Données du Client ou des utilisateurs ayant accès à son espace de stockage. ${companyName} n'assume de même aucune responsabilité quant à la diffusion des données stockées par le Client ou ses utilisateurs autorisés.

Le Client reconnaît qu'aucune stipulation des présentes ne le dégagera de l'obligation de payer tous les montants dus à ${companyName} au titre des prestations réalisées.
${companyName} n’a pas vocation à connaître la nature des données stockées par le Client, en ce sens ${companyName} décline toute responsabilité quant aux données pouvant être stockées, transférées ou échangées par l’intermédiaire du Service. Parallèlement, ${companyName} se réserve la faculté de vérifier la conformité de l’utilisation faite par le Client du Service et dans ce cadre de vérifier la nature des Données transférés, stockés ou échangés par le Client. Dans cette hypothèse, si des contenus inappropriés sont identifiés par ${companyName}, le Service du Client sera immédiatement suspendu et résilié de plein droit.
${companyName} se réserve la possibilité de procéder à des opérations de maintenance de son infrastructure technique. Le Client reconnaît qu'un service pourra être indisponible pendant toute la durée de cette opération de maintenance. ${companyName} s’efforcera dans la limite du possible de limiter la durée et les perturbations générées par ces opérations.
De même, ${companyName} se réserve la possibilité de prendre toute mesure de limitation susceptible de garantir la pérennité de l’Infrastructure, à titre non exhaustif : limitation de la bande passante, interruption de téléchargements montants ou descendants...

${companyName} sera dégagé de toute responsabilité en cas d'inobservation par le client d'une des clauses des précédentes conditions.
${companyName} ne pourra en aucun cas être considéré comme responsable des dommages directs ou indirects résultant de l'utilisation du matériel, y compris en cas de pertes de données ou d'information.
${companyName} ne pourra être rendu responsable des pannes ou anomalies de fonctionnement du matériel, quelle que puisse être la durée de la panne ou de l'immobilisation avant la remise en marche normale.

Sont exclues du présent contrat et donnera lieu à une facturation séparée les interventions dues aux faits suivants : non-respect des normes d'entretien par le client, utilisation anormale ayant entraîné des dégâts ou accidents, négligence ou faute du personnel du client, adjonctions ou connexions de matériel ou d'unité d'une autre marque, modifications des spécifications de la machine, utilisation de fournitures autres que celles préconisées par le fournisseur, variations ou défaillances du courant électrique ou pannes téléphoniques, modifications du logiciel provoquant un mauvais fonctionnement, programmation incorrecte, défaillance de la climatisation ou du contrôle hygrométrique, réparations ou entretien effectués par des personnes étrangères au fournisseur, déplacement ou transport du matériel, usage intensif du matériel.

Article 5 : Obligations et responsabilités du Client
----------------------------------------------------

Le Client s’engage à disposer du pouvoir, de l'autorité et de la capacité nécessaires à la conclusion et à l’exécution des obligations prévues aux présentes.
Le Client s'engage à communiquer, lors de la création de son compte client et à chaque modification de celles-ci, ses coordonnées et informations bancaires (si offre payante) exactes et mises à jour à ${companyName}.
Le Client est le responsable entier et exclusif des mots de passe et clés nécessaires à l'utilisation des services. ${companyName} dégage toute responsabilité pour toute utilisation illicite ou frauduleuse des mots de passe générés par le Client. La fourniture des mots de passe est considérée comme confidentielle. Toute suspicion d'une divulgation, intentionnelle ou non, des mots de passe fournis, engage la responsabilité unique du Client à l'exclusion de celle de ${companyName}. En cas d’une demande de changement du mot de passe par le Client, ${companyName} se réserve la faculté de facturer ce service au temps passé sur cette opération.
Le Client supportera seul les conséquences du défaut de fonctionnement du service consécutif à toute utilisation, par lui- même, les membres de son entourage ou par toute personne auquel le Client aura fourni son (ou ses) mot(s) de passe. De même, le Client supporte seul les conséquences de la perte du ou des mots de passe précités.
Le Client s'engage à respecter l'ensemble des prescriptions légales et réglementaires en vigueur, et en particulier celles relatives à l'informatique, aux fichiers, aux libertés et à la propriété intellectuelle, ainsi que les droits des tiers (droit à l’image, droit au respect de la vie privée...) et s'assure à ce titre que les données stockées par lui-même ou par les utilisateurs autorisés respectent ces dispositions.
Le Client demeure seul propriétaire des données et fichiers qu’il transfère et/ou stocke sur les services.
Le Client s’interdit de transférer, stocker, copier, partager des données pour lesquelles il ne disposerait pas de l’ensemble des droits ou à défaut de l’autorisation des ayants droit.
Le Client s’interdit d’utiliser de stocker et/ou partager de contenus de nature à porter atteinte à l’ordre public ou aux bonnes mœurs, incitatifs à la haine raciale, négationnistes, révisionnistes, violents, obscènes, diffamatoires, zoophiles, pédopornographiques, pornographiques...
Le Client se porte fort du respect des engagements pris au titre des présentes par les utilisateurs auxquels il permet l'accès à son espace de stockage.
Le Client s’engage à vérifier que les transferts de fichiers effectués par l’intermédiaire du Logiciel sont effectivement réussis. ${companyName} décline toute responsabilité en cas d’échec lors de la procédure de transfert ou de téléchargement de fichiers à destination de l’espace de stockage du Client. Il appartient au Client de prendre toutes les dispositions requises pour vérifier qu’un fichier transféré est bien stocké sur le Service et qu’il n’est pas compromis ou altéré. De même, le Client reconnaît qu’il pourra être amené à transférer de nouveau un fichier dans l’hypothèse où son précédent transfert aurait été interrompu.
Le Client s'engage par ailleurs à prendre toutes les assurances nécessaires auprès d'un organisme notoirement solvable afin de couvrir tous les dommages qui lui seraient imputables dans le cadre du présent contrat ou de son exécution.
Le non-respect par le Client des points visés ci-dessus, et notamment toute activité susceptible d'engendrer une responsabilité civile et/ou pénale entraînera le droit pour ${companyName} de décâbler et/ou d'interrompre sans délai et sans mise en demeure préalable les services du Client et de résilier immédiatement et de plein droit le contrat, sans préjudice du droit à tous dommages et intérêts auxquels ${companyName} pourrait prétendre.
Le Client s'engage à informer ${companyName} dans les 48 heures de toute modification concernant sa situation, et dans les 24 heures de toute perte éventuelle des mots de passe.
Le Client, pour tout contact avec ${companyName}, s'engage à formuler clairement sa demande, selon les règles d'usage.

Article 7 : Données Personnelles
--------------------------------

${companyName} rappelle au Client que dans le cadre de l'utilisation des services proposés, ${companyName} sera amenée à conserver un ensemble de données personnelles pour répondre à ses obligatoires règlementaires et judiciaires.
A ce titre, les informations telles que « nom, prénom, adresse postale, adresse électronique, téléphones, adresses IP de connexion » seront conservées par ${companyName} pendant toute la durée de la relation contractuelle et jusqu’à douze mois à compter de l’expiration du Service.
Les données transmises par le Client sont conservées le temps légal nécessaire à l'administration de la preuve. ${companyName} s'interdit toute divulgation, toute revente des données nominatives relatives au Client, sous réserve de dispositions contraires dans les conditions particulières. Seules les filiales de ${companyName} peuvent y avoir accès.
Le Client reconnait également qu’${companyName} peut être amenée à communiquer ses informations à la demande d’autorités administratives, règlementaires ou judiciaires.
Conformément à la loi «informatique et libertés» du 6 janvier 1978, le Client bénéficie d’un droit d’accès et de rectification des informations qui le concernent. Il peut exercer ce droit et obtenir communication des informations le concernant par courriel à l’adresse électronique : ${companyContact} ou encore par courrier postal à l’adresse :
${companyName}, Correspondant Informatiques et Libertés, ${companyAddress}
Le Client s'engage à respecter l'ensemble des prescriptions légales et réglementaires relatives à l'informatique, aux fichiers et aux libertés, et notamment à faire toute déclaration des traitements auprès de la Commission Nationale de l'Informatique et des Libertés (C.N.I.L.).
En conséquence, le Client dispose d'un droit d’accès, de modification, de rectification ou de suppression de ses données collectées, en contactant simplement ${companyName}, à l'adresse ${companyAddress}
Les données transmises par le Client sont conservées le temps légal nécessaire à l'administration de la preuve. ${companyName} s'interdit toute divulgation, toute revente des données nominatives relatives au Client, sous réserve de dispositions contraires dans les conditions particulières. Seules les filiales de ${companyName} peuvent également y avoir accès.
Par ailleurs, le Client est entièrement et exclusivement responsable du respect par ses soins de l'ensemble des règles résultant de la loi Informatique et Libertés. A cet égard, en qualité de responsable du traitement des données à caractère personnelle qu'il pourrait être amené à traiter dans le cadre de l'utilisation du Service, il s'engage à procéder, auprès de la CNIL, à toute démarche appropriée et à assurer l'information des personnes concernées.

Article 8 : Sécurité et Confidentialité
---------------------------------------

${companyName} s’engage à mettre en place toutes les mesures requises pour garantir la sécurité des données du Client. Les données sont uniquement stockées sur des serveurs localisés sur le territoire français.
Les connexions effectuées par le Client à destination du Service sont cryptés par l’intermédiaire du protocole SSL (Secure Sockets Layer) quelque soit la nature de la connexion : Lecture, transfert ou téléchargement...
${companyName} a pris l’ensemble des mesures techniques nécessaires à la préservation de la confidentialité des données du Client. Les données stockées sur le Service sont strictement partitionnées de manière à ce qu’elles ne puissent être accessibles que par le Client et en aucun cas par un autre Client

Article 9 : Assistance Technique
--------------------------------

${companyName} met à disposition du Client un service d’assistance technique accessible par email.
Parallèlement, le Client peut consulter la Documentation relative aux différents services.

Article 10: Exécution, Tarif et Paiement
----------------------------------------

### Création du compte client

Pour toute commande de Service payant auprès de ${companyName}, le Client doit créer un compte client comprenant ses coordonnées et informations bancaires exactes et mises à jour.

### Exécution de la commande

La mise à disposition du service intervient après activation du compte client par ${companyName} et dans un délai maximal de 7 jours à compter du paiement effectif du bon de commande par le Client dans le cadre d’une offre payante.
Le paiement effectif est réalisé dès lors que les sommes correspondantes au Service sont créditées sur le compte de ${companyName}. Passé ce délai et à défaut de mise à disposition du service par ${companyName}, le Client est en droit de demander l'annulation de la transaction et le remboursement des sommes déjà versées.

### Tarif

Les tarifs en vigueur pour les différentes prestations proposées par ${companyName} sont disponibles en consultation en ligne sur le site ${companyWebsite} et sur demande auprès de ${companyName}, à l'adresse suivante : ${companyName}, EURL : ${companyAddress}.
Les services ou prestations commandés sont mentionnés dans le bon de commande ; ils s'entendent toutes taxes comprises sauf indication contraire et sont payables en euros.
${companyName} se réserve la faculté de modifier ses prix à tout moment, sous réserve d'en informer le Client par courrier électronique ou par un avertissement en ligne sur le site ${companyWebsite} un mois à l'avance si les nouveaux tarifs hors taxes sont moins favorables au Client. Dans cette hypothèse, le Client disposera à compter de cette information d'un délai d'un mois pour résilier le présent contrat sans pénalité. A défaut, le Client sera réputé avoir accepté les nouveaux tarifs. Les modifications de tarifs seront applicables à tous les contrats et notamment à ceux en cours d'exécution.
${companyName} se réserve le droit de répercuter, sans délai, toute nouvelle taxe ou toute augmentation de taux des taxes existantes.
Les prestations fournies par ${companyName} sont payables à la commande. Le Client est seul responsable du paiement de l'ensemble des sommes dues au titre du contrat de prestations de services ${companyName}. De convention expresse et sauf report sollicité à temps et accordé par ${companyName} de manière particulière et écrite, le défaut total ou partiel de paiement à l'échéance de toute somme due au titre du contrat entraînera de plein droit et sans mise en demeure préalable :
+ l'exigibilité immédiate de toutes les sommes restant dues par le Client au titre du contrat, quel que soit le mode de règlement prévu ;
+ la suspension de toutes les prestations en cours, quelle que soit leur nature, sans préjudice pour ${companyName} d'user de la faculté de résiliation du contrat ;
+ l'impossibilité de souscrire de nouvelles prestations ou de les renouveler ;
+ l'application d'un intérêt à un taux égal à une fois et demi le taux de l’intérêt légal en vigueur en France.
Tout désaccord concernant la facturation et la nature des services devront être exprimés par courrier électronique à l'adresse figurant sur le site ${companyWebsite} dans un délai d'un mois après émission du bon de commande.
Dans l'hypothèse où des frais seraient exposés par ${companyName}, cette dernière en informera le Client et lui communiquera les justificatifs et la facture correspondant. Le Client devra alors régler la somme due en euros.

### Paiement

#### Modalités de Paiement
Les abonnements payés d'avance sont garantis pour la période concernée.

Le paiement doit être fait :
- soit par carte bancaire (Visa®, Mastercard®, Carte Bleue® ou Eurocard®),
- soit par prélèvement automatique (sous réserve de prélèvement sur un compte bancaire situé en France et que le Client ait transmis l'ensemble des documents requis).
Aucun autre paiement ne sera accepté ou validé (ni mandat Cash, ni paiement Swift…), autre que
ceux cités précédemment.

##### Conséquences d’un retard de paiement
Tout défaut de paiement ou impayé (annulation du paiement par carte, défaut de provision et/ou rejet par notre banque suite à un paiement par prélèvement, par renouvellement automatique ou par chèque...) sera considéré comme un retard de paiement.
De convention expresse et sauf report sollicité à temps et accordé par ${companyName} de manière particulière et écrite, le défaut total ou partiel de paiement à l'échéance de toute somme due au titre du contrat entraînera de plein droit et sans mise en demeure préalable :
+ l'exigibilité immédiate de toutes les sommes restant dues par le Client au titre du contrat, quel que soit le mode de règlement prévu.
+ l’encaissement du dépôt de garantie versé par le Client dans le cadre de l’augmentation de sa limite de consommation ainsi que la résiliation immédiate du contrat en cours ;
+ la possibilité de suspendre ou de résilier, si bon semble à ${companyName}, l’exécution de toute commande en cours jusqu’au paiement complet des sommes dues par le Client ; l'application d'un intérêt à un taux égal à 12% sans que celui-ci ne puisse être inférieur à une fois et demi le taux de l’intérêt légal Français,
+ la suspension de toutes les prestations en cours, quelle que soit leur nature, sans préjudice pour ${companyName} d'user de la faculté de résiliation du contrat stipulée à l'article RESILIATION.
Tout désaccord concernant la facturation et la nature du Service devra être exprimé par courrier électronique à destination du support ${companyName} dans un délai d'un mois après émission du bon de commande.
Dans l'hypothèse où des frais seraient exposés par ${companyName}, cette dernière en informera le Client et lui communiquera les justificatifs et la facture correspondant. Le Client pourra alors régler la somme due par chèque en euros

### Durée

Sauf indications contraires sur le site ${companyWebsite}, les services proposés par la société ${companyName} sont souscrit pour une durée indéterminée, le Client peut suspendre ou résilier son Service à tout moment en se connectant à son espace client sur le site ${companyWebsite}

### Enregistrement d'un moyen de paiement

Le Client dispose de la faculté d'enregistrer sa carte bancaire afin de bénéficier du renouvellement automatique du Service. Le Client peut gérer, modifier, supprimer les cartes bancaires enregistrées en se connectant sur son espace personnel sur le site ${companyWebsite}. Le Client ne peut pas disposer de plus d'une carte bancaire active dans son interface de gestion. ${companyName} ne dispose pas du numéro complet de la carte bancaire, seul son prestataire de paiement dispose de cette information et s'engage à la conserver selon les standards de la norme PCI-DSS (norme de référence en la matière).

### Renouvellement de service

Sauf indications contraires sur le site ${companyWebsite}, les services proposés par la société ${companyName} sont automatiquement renouvelés pour une nouvelle période d'un mois sous réserve que le Client dispose d'un moyen de paiement enregistré et en cours de validité.
Dans le cas contraire, ${companyName} notifiera au Client par le biais de courriers électroniques (adresse e-mail à tenir à jour, sous la responsabilité du Client) la prochaine échéance de ses services.
${companyName} s'engage à effectuer, au minimum, trois rappels par courrier électronique avant l'expiration du service.
A défaut de renouvellement par le Client, les données sont effacées dans un délai de trente (30) jours à compter de l'expiration du ou des services souscrits. Il appartient en conséquence au Client de prendre l'ensemble des mesures nécessaires pour assurer la récupération de ses données ou celles des utilisateurs autorisés.
De même dans le cadre d’une offre payante, tout défaut de paiement ou paiement irrégulier, c'est à dire, notamment, d'un montant erroné, ou incomplet, ou ne comportant pas les références requises, ou effectué par un moyen ou une procédure non acceptés par ${companyName}, sera purement et simplement ignoré et provoquera le rejet par ${companyName} de la demande d'enregistrement ou de renouvellement.

Article 11 : Résiliation, Limitation et Suspenstion du service
--------------------------------------------------------------

+ Le contrat est résilié de plein droit à son échéance.

+ Chaque partie peut résilier de plein droit et sans indemnité le contrat en cas de force majeure subsistant plus de trente jours.

+ Le contrat est résilié de plein droit et sans préavis en cas de manquement par le Client à une obligation essentielle aux présentes.

+ Pour une résiliation avant échéance du contrat, le Client est libre de demander la suppression de son Service par l’intermédiaire du logiciel ou par le biais de son Interface de gestion. Parallèlement, il peut demander la résiliation du contrat par simple envoi postal à l'adresse ${companyName} ${companyAddress}. Le Client ne pourra prétendre au remboursement par ${companyName} des sommes déjà versées.

+ Dans tous les autres cas de manquement par l'une des parties à l'une ou l'autre de ses obligations au titre du contrat non réparé dans un délai de 7 jours à compter soit d'un courrier électronique adressé par la partie plaignante notifiant les manquements en cause, soit de toute autre forme de notification faisant foi adressée par la dite partie, le contrat sera résilié de plein droit, sans préjudice de tous dommages et intérêts éventuels qui pourraient être réclamés à la partie défaillante.
La date de notification de la lettre comportant les manquements en cause sera la date du cachet de la poste, lors de la première présentation de la lettre.

+ ${companyName} se réserve le droit d'interrompre le service du Client si ce service constitue un danger pour le maintien de la sécurité ou de la stabilité de la plate-forme d'hébergement de ${companyName}. Dans la mesure du possible, ${companyName} informera préalablement le Client.
En cas de nécessité, ${companyName} se réserve la possibilité d'interrompre le service pour procéder à une intervention technique, afin d'améliorer son fonctionnement ou pour toute opération de maintenance.

Article 12 : Conditions d'Exploitation
--------------------------------------

Le Client reconnaît par les présentes que les fluctuations de la bande passante et les aléas du fournisseur d'accès sont des éléments pouvant entraîner une discontinuité dans les prestations offertes par la société ${companyName}, et extérieure à ses moyens techniques.
Par ailleurs, les services proposés par la société ${companyName} sont restreints, limités ou suspendus de plein droit par ${companyName} :
+ s'il apparaît que le Client utilise les services qui lui sont fournis pour une activité, quelle qu’elle soit, qui ne serait pas conforme aux présentes conditions générales ;
+ si ${companyName} reçoit un avis à cet effet notifié par une autorité compétente, administrative, arbitrale ou judiciaire, conformément aux lois applicables appropriées, ou par un tiers, dès lors que le caractère signalé apparait comme illicite ou de nature à engager la responsabilité de ${companyName};
+ si les coordonnées indiquées dans le compte client apparaissent fausses, inexactes ou non actualisées. 

Article 13 : Information du Client et Conformité du Service
-----------------------------------------------------------

Le Client reconnaît avoir vérifié l'adéquation du ou des services à ses besoins et avoir reçu de ${companyName} toutes les informations et conseils qui lui étaient nécessaires pour souscrire au présent engagement en connaissance de cause.
${companyName} se réserve le droit de contrôler le respect des conditions d'utilisation du service.

Article 14 : Tolérance
----------------------

Le fait que ${companyName} ne se prévale pas à un moment donné de l'une quelconque des présentes conditions générales et/ou tolère un manquement par l'autre partie à l'une quelconque des obligations visées dans les présentes conditions générales ne peut être interprétée comme valant renonciation par ${companyName} à se prévaloir ultérieurement de l'une quelconque desdites conditions.

Article 15 : Droit de Rétractation
----------------------------------

L'exercice du droit de rétractation est exclu pour le Client pour lequel la qualité de consommateur au sens des dispositions du Code de la consommation ne peut être retenue.

Article 16 : Modification
-------------------------

Les conditions générales et particulières en ligne prévalent sur les conditions générales et particulières imprimées. Les parties conviennent que ${companyName} peut, de plein droit, modifier son service sans autre formalité que d'en informer le Client par un avertissement en ligne et/ou de porter ses modifications dans les conditions générales en ligne. Toute modification ou introduction de nouvelles options d'abonnement fera l'objet d'une information en ligne sur le site localisé à l'adresse URL ${companyWebsite} ou l'envoi d'un courrier électronique au Client. Dans cette hypothèse, le Client peut, en dérogation à l’article 10, résilier le contrat dans un délai de trente jours à compter de l'entrée en vigueur de ces modifications.

Article 17 : Dispositions Générales
-----------------------------------

### Divisibilité

La nullité d'une des clauses du contrat de prestations de services souscrit auprès de ${companyName}, en application notamment d'une loi, d'un règlement ou à la suite d'une décision d'une Juridiction compétente passée en force de chose jugée n'entraînera pas la nullité des autres clauses du contrat de prestations de services qui garderont leur plein effet et portée.
Dans ce cas, les parties devront dans la mesure du possible remplacer la disposition annulée par une disposition valable correspondant à l'esprit et à l'objet des Conditions contractuelles.

### Intitulés

Les intitulés des articles des Conditions contractuelles ont pour seul but de faciliter les références et n'ont pas par eux- mêmes, une valeur contractuelle ou une signification particulière.

### Conditions particulières et Annexes

Les conditions particulières et éventuelles annexes sont incorporées par référence aux Conditions générales de services de ${companyName} et sont indissociables desdites Conditions générales. L’ensemble de ces documents est dénommé dans le présent document «Conditions Générales ».
L'ensemble des documents incorporés au présent contrat par référence peut être consulté par le Client aux adresses indiquées sur le site ${companyWebsite} Ces documents sont en outre sujets à modifications ou évolutions.

### Communications

Pour tout échange d'informations par courrier électronique, la date et l'heure du serveur de ${companyName} feront foi entre les Parties. Ces informations seront conservées par ${companyName} pendant toute la période des relations contractuelles.
Toutes les notifications, communications, mises en demeure prévues par les Conditions générales seront réputées avoir été valablement délivrées si elles sont adressées par lettre recommandée avec demande d'avis de réception à :
+ Pour ${companyName} : ${companyAddress}
+ Pour le Client : à l'adresse postale et/ou e-mail qu’il a fourni à ${companyName}

Article 18 : Attribution de Compétences
---------------------------------------

En cas de litige avec un client non considéré comme consommateur au sens du code de la Consommation, compétence expresse est attribuée au Tribunal de Commerce de Lille Métropole (France), nonobstant pluralité de défendeur ou appel en garantie, y compris pour les mesures d'urgence, conservatoires en référé ou sur requête.

Article 19 : Loi Applicable
---------------------------

Le présent contrat est régi par la loi française. Il est en ainsi pour les règles de fond comme pour les règles de forme, à l'exclusion, d'une part, des règles de conflit prévues par la loi Française, et d'autre part, des dispositions de la loi Française qui seraient contraires au présent Contrat.
`
import {gql} from "@apollo/client";

export default gql`
fragment PaymentFragment on Payment {
  id
  type
  value
  date
  comment
}
`
import React, {useEffect, useState} from 'react'
import {useQuery, useMutation} from "@apollo/client"
// import {injectStripe} from 'react-stripe-elements'

// import './style.less'

import paymentGetByIdQuery from "graphql/BILLING/Payment/query/paymentGetById"
import paymentConfirmIntentMutation from "graphql/BILLING/Payment/mutation/paymentConfirmIntent"
import {useStripe} from '@stripe/react-stripe-js'


export const Card3dSecure = (props: any) => {

  const stripe = useStripe();
  const {paymentId} = props
  const [errorMessage, updateErrorMessage] = useState<string>("")
  const [successMessage, updateSuccessMessage] = useState<string>("")
  const [enrollStarted, setEnrollStarted] = useState(false)

  const {data: {paymentGetById} = {}} = useQuery(paymentGetByIdQuery, {
    variables: {
      paymentId,
    }
  })

  const [confirmIntentMutation] = useMutation(paymentConfirmIntentMutation, {
    variables: {
      paymentId,
    }
  })

  useEffect(() => {
    if (paymentGetById) {
      const {stripeClientSecret} = paymentGetById
      !enrollStarted && _triggerEnroll(stripeClientSecret)
    }
  })

  const _triggerEnroll = (stripeToken: any) => {
    setEnrollStarted(true);
    console.log(`Triggering Enrolling`) // eslint-disable-line no-console
    stripe && stripe.handleCardAction(stripeToken)
      .then(response => {
        const {error} = response
        console.log({response}) // eslint-disable-line no-console
        if (error) {
          throw new Error(`Oups, la validation a échouée...`)
        }

        return confirmIntentMutation()
        // const {onStripeReponse} = this.props
        // return onStripeReponse()
        // // return Promise.resolve(true)
      })
      .then(() => {
        return updateSuccessMessage("C'est parfait, votre facture va vous être envoyée directement par mail ;)")
        // this.setState({
        //   error: null,
        //   message: "C'est parfait, votre facture va vous être envoyée directement par mail ;)",
        //   loading: false,
        // })
      })
      .catch(error => {
        const {name} = error;
        console.log({error}) // eslint-disable-line no-console
        switch (name) {
          case 'IntegrationError': {
            updateErrorMessage("Oups, ce lien n'est plus valide :(")
            break;
          }

          default: {
            updateErrorMessage(error.message)
          }
        }
      })
  }

  const {Invoice} = paymentGetById || {}
  const {publicId, totalHT} = Invoice || {}
  const totalHTFormatted = `${Number(totalHT) / 100} €`
  return (
    <div className="card-3dsecure-container">
      <div className="message-body">
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        {successMessage && <div className="alert alert-success">{successMessage}</div>}
      </div>
      <div className="payment-details">
        <table className="table table-bordered  ">
          <tbody>
            <tr>
              <th>Facture</th>
              <td>{publicId}</td>
            </tr>
            <tr>
              <th>Montant</th>
              <td>{totalHTFormatted}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  )
}


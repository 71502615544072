import { gql } from "@apollo/client";
import TransactionTagFragment from "../Tag/fragment/TransactionTagFragment";

export default gql`

${TransactionTagFragment}

fragment TransactionFragment on Transaction {
  id
  date
  label
  value
  TransactionTag {
    ...TransactionTagFragment
  }
}

`
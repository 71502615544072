import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

import { URL_BILLING_CUSTOMER_LIST, URL_BILLING_INVOICE_LIST, URL_USER_LIST, URL_ROOT, URL_PROFILE, URL_BILLING_ROOT, URL_GRAPHIQL, URL_HOSTING_CLUSTER_LIST, URL_HOSTING_CLUSTER_ROLE_LIST, URL_HOSTING_CLUSTER_PROVISIONING_TASK_LIST, URL_ORDER_LIST, URL_BILLING_TRANSACTION_LIST, URL_BILLING_TAG_MANAGER, URL_PHONE_ROOT, URL_HOSTING_SERVER_LIST, URL_ITEM_LIST, URL_PHONE_DIRECTORY, URL_HOSTING_CONFIG_LIST, URL_PHONE_CONFIG_LIST, URL_PHONE_CONFIG, URL_VIDEO_CONFIG_LIST } from "static/constant/backoffice/url";
// import {URL_PHONE_ROOT} from "static/constant/backoffice/url";
import { URL_BACKOFFICE } from "static/constant/url";

import logo from 'static/images/logo.png';
import { userContext } from "context/User";


import { NotificationMenu } from "components/backoffice/Notification/Menu/NotificationMenu"
import { useNavigate } from "react-router-dom";
import { UserRoleEnum } from 'model';


export const HeaderBar = (props: any) => {

  const navigate = useNavigate()

  const _handleLinkClic = (destination: any) => {
    navigate(`/manager${destination}`)
  }

  return (
    <Navbar bg="light" expand="lg" style={{ padding: "0" }} >
      <userContext.Consumer>
        {(user: any) => {

          const { isAdmin, isManager, isUser, roles } = user || {}
          const isBilling = roles?.includes("ROLE_BILLING")
          const isHosting = roles?.includes("ROLE_HOSTING")
          const isPhone = roles?.includes("ROLE_PHONE")
          const isVideo = roles?.includes(UserRoleEnum.ROLE_ADMIN)

          return <Container>
            <Nav.Link onClick={_handleLinkClic.bind(this, URL_ROOT)}>
              <Navbar.Brand><img width="30" className="d-inline-block align-center" src={logo} alt="" />box4b</Navbar.Brand>
            </Nav.Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                {isBilling && <NavDropdown title="Facturation" id="billing-root">
                  <NavDropdown.Item eventKey="1.1.1" onClick={_handleLinkClic.bind(this, URL_BILLING_ROOT)}>Accueil</NavDropdown.Item>
                  <div className="dropdown-header">Bons de commandes</div>
                  <NavDropdown.Item eventKey="1.5.1" onClick={_handleLinkClic.bind(this, URL_ORDER_LIST)}>Lister</NavDropdown.Item>
                  <div className="dropdown-header">Prestations</div>
                  <NavDropdown.Item eventKey="1.8.1" onClick={_handleLinkClic.bind(this, URL_ITEM_LIST)}>Lister</NavDropdown.Item>
                  <div className="dropdown-header">Clients</div>
                  <NavDropdown.Item eventKey="1.2.1" onClick={_handleLinkClic.bind(this, URL_BILLING_CUSTOMER_LIST)}>Lister</NavDropdown.Item>
                  <div className="dropdown-header">Factures</div>
                  <NavDropdown.Item eventKey="1.3.1" onClick={_handleLinkClic.bind(this, URL_BILLING_INVOICE_LIST)}>Lister</NavDropdown.Item>
                  <div className="dropdown-header">Transaction</div>
                  <NavDropdown.Item eventKey="1.6.1" onClick={_handleLinkClic.bind(this, URL_BILLING_TRANSACTION_LIST)}>Lister</NavDropdown.Item>
                  <div className="dropdown-header">Tag</div>
                  <NavDropdown.Item eventKey="1.7.1" onClick={_handleLinkClic.bind(this, URL_BILLING_TAG_MANAGER)}>Gérer</NavDropdown.Item>
                  {/* <NavDropdown.Item eventKey="1.4.1" onClick={_handleLinkClic.bind(this, URL_BILLING_QUOTE_LIST)}>Devis</NavDropdown.Item> */}
                </NavDropdown>}
                {/* {isBilling && <Nav.Link onClick={_handleLinkClic.bind(this, URL_BILLING_ROOT)}>Facturation</Nav.Link>} */}
                {isHosting && <NavDropdown title="Hébergement" id="nav-dropdown">
                  <div className="dropdown-header">Serveurs</div>
                  <NavDropdown.Item eventKey="4.1" onClick={_handleLinkClic.bind(this, URL_HOSTING_SERVER_LIST)}>Liste</NavDropdown.Item>
                  <div className="dropdown-header">Applications</div>
                  <NavDropdown.Item eventKey="4.2" onClick={_handleLinkClic.bind(this, URL_HOSTING_CLUSTER_LIST)}>Liste</NavDropdown.Item>
                  {isAdmin && <div>
                    <div className="dropdown-header">Roles</div>
                    <NavDropdown.Item eventKey="4.3" onClick={_handleLinkClic.bind(this, URL_HOSTING_CLUSTER_ROLE_LIST)}>Liste des Roles</NavDropdown.Item>
                    <div className="dropdown-header">Taches</div>
                    <NavDropdown.Item eventKey="4.4" onClick={_handleLinkClic.bind(this, URL_HOSTING_CLUSTER_PROVISIONING_TASK_LIST)}>Liste des Taches</NavDropdown.Item>
                  </div>}
                </NavDropdown>}
                {/* {(isUser || isAdmin || isManager) && <Nav.Link onClick={_handleLinkClic.bind(this, URL_NETWORK_ROOT)}>Réseau</Nav.Link>} */}
                {/* {isPhone && <Nav.Link onClick={_handleLinkClic.bind(this, URL_PHONE_ROOT)}>Téléphonie</Nav.Link >} */}
                {isPhone && <NavDropdown title="Téléphonie" id="nav-dropdown">
                  <NavDropdown.Item eventKey="5.1" onClick={_handleLinkClic.bind(this, URL_PHONE_CONFIG)}>Configuration</NavDropdown.Item>
                  {/* <NavDropdown.Item eventKey="5.2" onClick={_handleLinkClic.bind(this, URL_PHONE_DIRECTORY)}>Repertoire</NavDropdown.Item> */}
                </NavDropdown>}
                {isVideo && <NavDropdown title="Vidéo" id="nav-dropdown">
                  <NavDropdown.Item eventKey="6.1" onClick={_handleLinkClic.bind(this, URL_VIDEO_CONFIG_LIST)}>Liste</NavDropdown.Item>
                  {/* <NavDropdown.Item eventKey="5.2" onClick={_handleLinkClic.bind(this, URL_PHONE_DIRECTORY)}>Repertoire</NavDropdown.Item> */}
                </NavDropdown>}
                {isAdmin && <NavDropdown title="APP" id="nav-dropdown">
                  <div className="dropdown-header">Users</div>
                  {isAdmin && <NavDropdown.Item onClick={_handleLinkClic.bind(this, URL_USER_LIST)}>User</NavDropdown.Item>}
                  <div className="dropdown-header">Hosting</div>
                  {isAdmin && <NavDropdown.Item onClick={_handleLinkClic.bind(this, URL_HOSTING_CONFIG_LIST)}>Config</NavDropdown.Item>}
                  <div className="dropdown-header">Phone</div>
                  {isAdmin && <NavDropdown.Item onClick={_handleLinkClic.bind(this, URL_PHONE_CONFIG_LIST)}>Config</NavDropdown.Item>}
                  <div className="dropdown-header">Graphql</div>
                  {isAdmin && <NavDropdown.Item onClick={_handleLinkClic.bind(this, URL_GRAPHIQL)}>Graphql</NavDropdown.Item>}
                </NavDropdown>}


              </Nav>
              <Nav>
                {user && <Nav.Link onClick={_handleLinkClic.bind(this, URL_PROFILE)}>Mon Compte</Nav.Link >}
                {!user && <Nav.Link href={URL_BACKOFFICE}>connexion</Nav.Link >}
              </Nav>

            </Navbar.Collapse>
          </Container>
        }}
      </userContext.Consumer>
      <NotificationMenu />
    </Navbar >
  )
}

import { gql } from "@apollo/client";
import PhoneConfigListFragment from "../fragment/PhoneConfigListFragment";

export default gql`

${PhoneConfigListFragment}

query phoneConfigListAll {
  list: phoneConfigListAll {
    ...PhoneConfigListFragment
  }
}

`

import "./style.scss"

import { InvoiceLineForm } from "components/backoffice/BILLING/InvoiceLine/Form/InvoiceLineForm";
import { PaymentEdit } from "components/backoffice/BILLING/Payment/Edit/PaymentEdit";
import { DateInput, IconTrash, SelectInput } from "@zipou/front_tools";


import contractListQuery from "graphql/BILLING/Contract/query/contractList"
import { useQuery } from "@apollo/client";
import { Contract, InvoiceInput, InvoiceStatusEnum } from "model";

type InvoiceFormProps = {
  invoice: InvoiceInput,
  onChange: (invoice: InvoiceInput) => void,
  errors: any,
}

export const InvoiceForm = ({ invoice, onChange, errors }: InvoiceFormProps) => {

  const contractListReponse = useQuery<{ contractList: Contract[] }>(contractListQuery)
  const contractList = contractListReponse?.data?.contractList || []
  const contractListSorted = [...contractList]?.sort((el1, el2) => el1?.Customer?.name.localeCompare(el2?.Customer?.name)) || []

  const contract = contractList && contractList.find((el) => el.id === invoice.contractId)
  const contactList = contract && contract.Customer && contract.Customer.Contact
  const invoiceLineList = invoice?.InvoiceLine

  return <div className="invoice-form-container">
    <DateInput id="date" errors={errors} label="Date" value={Number(invoice?.date * 1000) || 0} onChange={(e: number) => {
      onChange({
        ...invoice,
        date: Math.round(e / 1000),
      })
    }} />
    <SelectInput label={"Contract"} value={invoice?.contractId || ""} errors={errors} choiceList={contractListSorted?.map((contract: any) => {
      const { id, Customer } = contract
      const { name, initials } = Customer
      return { id, label: `${name} ${initials}` }
    }) || []}
      onChange={(v: string) => {
        onChange({
          ...invoice,
          contractId: v,
        })
      }} />

    <div className="input-group">
      <div className="input-group-text">Contact</div>
      <select name="Contact" className="form-control" value={invoice.contactId || ""} onChange={(e: any) => {
        onChange({
          ...invoice,
          contactId: e.target.value,
        })
      }}>
        <option value="" disabled={true}>Choisissez une valeur</option>
        {contactList && contactList.map((contact: any) => {
          const { id, firstname, lastname } = contact
          return <option key={`contact_${id}`} value={id}>{`${firstname} ${lastname}`}</option>
        })}
      </select>
    </div>
    <div className="input-group">
      <div className="input-group-text">Status</div>
      <select name="status" className="form-control" value={invoice?.status || ""} onChange={(e: any) => {
        onChange({
          ...invoice,
          status: e.target.value,
        })
      }}>
        <option value="" disabled={true}>Choisissez une valeur</option>
        {Object.keys(InvoiceStatusEnum).map((status, index) => {
          return <option key={`status_${status}_${index}`} value={status}>{status}</option>
        })}
      </select>
    </div>
    <div className="input-group">
      <div className="input-group-text">Lignes</div>
      <div className="form-control" style={{ height: "100%" }}>
        <div className="child-container">
          {invoice?.InvoiceLine?.map((invoiceLine, index: number) => {
            return <div key={`invoiceLine_${invoiceLine?.id}_${index}`} className="child-item">
              <div className="input-group">
                <div className="form-control" style={{ height: "100%" }}>
                  <InvoiceLineForm
                    errors={errors && errors.InvoiceLine && errors?.InvoiceLine[index]}
                    invoiceLine={invoiceLine}
                    onChange={(v) => {
                      onChange({
                        ...invoice,
                        InvoiceLine: invoice?.InvoiceLine?.map((il, i) => (i === index) ? v : il)
                      })
                    }}
                  />
                </div>
                <div className="input-group-text">
                  <span onClick={() => {
                    onChange({
                      ...invoice,
                      InvoiceLine: invoice?.InvoiceLine?.filter((il, i) => (i !== index))
                    })
                  }} >
                    <IconTrash />
                  </span>
                </div>
              </div>
            </div>
          })}
          <button className="btn btn-dark btn-sm" type="button" onClick={() => {
            onChange({
              ...invoice,
              InvoiceLine: [...(invoice?.InvoiceLine || []), { vat: 20 }]
            })
          }}>Ajouter</button>
        </div>
      </div>
      {errors && errors["InvoiceLine"] && <div className="input-group-text"><span className="icon-warning-sign" /></div>}
    </div>
    <div className="input-group">
      <div className="input-group-text">Paiements</div>
      <div className="form-control" style={{ height: "100%" }}>
        <div className="child-container">
          {invoice?.Payment?.map((payment, index: number) => {
            const { id } = payment
            return <div key={`paumentLine${id}`} className="child-item">
              <div className="input-group">
                <div className="form-control" style={{ height: "100%" }}>
                  <PaymentEdit
                    payment={payment}
                    onChange={(v) => {
                      onChange({
                        ...invoice,
                        Payment: invoice.Payment?.map((p, i) => i === index ? v : p),
                      })
                    }}
                  />
                </div>
                <div className="input-group-text" onClick={(v: any) => {
                  onChange({
                    ...invoice,
                    Payment: invoice.Payment?.filter((p, i) => i !== index),
                  })
                }}>
                  <IconTrash />
                </div>
              </div>
            </div>
          })}
          <button className="btn btn-dark btn-sm" type="button" onClick={() => {
            onChange({
              ...invoice,
              Payment: [...(invoice?.Payment || []), {}],
            })
          }}>Ajouter</button>
        </div>
      </div>
      {errors && errors["Payment"] && <div className="input-group-text"><span className="icon-warning-sign" /></div>}
    </div>
  </div >
}


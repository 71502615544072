import { useMutation, useQuery } from "@apollo/client"
import customerUpdateGql from "graphql/BILLING/Customer/mutation/customerUpdate.gql"
import customerGetGql from "graphql/BILLING/Customer/query/customerGet.gql"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import React, { useState } from "react"
import { CustomerForm } from "../Form/CustomerForm"
import { useParams } from "react-router"

type CustomerEditProps = {
  id: string,
  onDone: () => void,
}


export const CustomerEdit = ({ id, onDone }: CustomerEditProps) => {

  const [customer, updateCustomer] = useState<any>(null)
  const [errors, updateErrors] = useState<any>(null)

  const params = useParams()

  const { data } = useQuery(customerGetGql, {
    variables: {
      id,
    },
    // skip: !props?.id,
    onCompleted: d => {
      updateCustomer(d.customer)
    }
  })

  const [mutate] = useMutation(customerUpdateGql)

  const onReset = () => {
    updateCustomer(data?.customer)
    onDone && onDone()
  }

  const onSave = (e: any) => {
    e.preventDefault();
    const { id, __typename, Company, Address, PaymentPref, ...input } = customer

    mutate({
      variables: {
        id,
        input: {
          ...input,
          Address: {
            address1: Address?.address1,
            address2: Address?.address2,
            zip: Address?.zip,
            town: Address?.town,
          },
          PaymentPref: PaymentPref.map((pPref: any) => ({
            id: pPref?.id,
            type: pPref?.type,
            ...((pPref?.BankAccount && pPref?.type === "TYPE_DEBIT") ? {
              BankAccount: {
                stripeId: pPref?.BankAccount?.stripeId,
                bankName: pPref?.BankAccount?.bankName,
              }
            } : {})
          })),
          Contact: input?.Contact?.map((contact: any) => {
            const { id, __typename, ...rest } = contact
            return rest
          })
        },
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e: any) => {
        updateErrors(formatErrorResponseForJoi(e))
      })

  }


  return <div>
    <form onSubmit={onSave}>
      <CustomerForm errors={errors} customer={customer} onChange={(customer: any) => updateCustomer(customer)} />
      <div>
        <button className="btn btn-sm btn-dark" type="submit">Enregistrer</button>
        <button className="btn btn-sm btn-danger" type="button" onClick={onReset} style={{ marginLeft: 5 }}>Annuler</button>
      </div>
    </form>
  </div>

}
import { GuestForm } from "../Form/GuestForm"
import { useState } from "react"
import { Guest, GuestInput } from "model"
import { Modal } from "@zipou/front_tools"

import { useMutation, useQuery } from "@apollo/client"
import guestGetGql from "graphql/WEDDING/Guest/query/guestGet.gql"
import { CookieHelper } from "helpers/Cookie"
import guestAddGql from "graphql/WEDDING/Guest/mutation/guestAdd.gql"
import { guestContext } from "context/guestContext"

type GuestWrapperProps = {
  children: any,
}

const cookieHelper = new CookieHelper("WEDDING_CAM_RAF")



export const GuestWrapper = (props: GuestWrapperProps) => {

  const [guest, updateGuest] = useState<Guest | null>()
  const [guestInput, updateGuestInput] = useState<Guest | null>()

  const cookie = cookieHelper.get()
  const [authInfos, updateAuthInfos] = useState<{ id?: string, token?: string }>({
    id: cookie?.id,
    token: cookie?.token,
  })


  const [loading, updateLoading] = useState<boolean>(false)


  // const [mutate] = useMutation<{ guest: Guest }>(guestAddGql)

  useQuery<{ guest: Guest }>(guestGetGql, {
    variables: {
      id: authInfos?.id,
      token: authInfos?.token,
    },
    skip: !authInfos,
    onCompleted: (data) => {
      updateGuest(data?.guest)
    }
  })

  const onLogout = () => {
    cookieHelper.clear()
    updateGuest(null)
    updateGuestInput(null)
  }


  const onSubmit = (e: any) => {
    e.preventDefault()
    updateLoading(true)

    cookieHelper.save({
      id: guestInput?.id,
      token: cookie?.token,
    })
    updateAuthInfos({
      id: guestInput?.id,
      token: cookie?.token,
    })
    updateGuest(guestInput)
    updateLoading(false)
    window.scrollTo(0,0)

  }


  const isLoggedIn = !!guest
  // console.log("guest", guest)

  return <div>
    {!isLoggedIn && <Modal display={true}>
      <h1>Bienvenue !</h1>
      <h2>Merci de chercher votre nom :)</h2>
      <form onSubmit={onSubmit}>
        <GuestForm guest={guestInput || undefined} onChange={v => updateGuestInput(v)} />
        <button disabled={loading} className="btn btn-sm btn-dark">Envoyer</button>
      </form>
    </Modal>}
    <guestContext.Provider value={{ guest, onLogout }}>
      {props?.children}
    </guestContext.Provider>
  </div>


}
import { gql } from "@apollo/client";
import HostingConfigFragmentGql from "../fragment/HostingConfigFragment.gql";

export default gql`

  ${HostingConfigFragmentGql}

  query hostingConfigGet($id: String!) {
    hostingConfig: hostingConfigGet(id: $id) {
      ...HostingConfigFragment
    }
  }
  
`
const flatArrayToDeepObject = (array, value, current) => {
  if (array.length === 1) {
    return {
      ...value,
      [array[0]]: true,
    }
  } else {
    const [first, ...rest] = array
    return {
      ...value,
      ...current,
      [first] : {
        ...flatArrayToDeepObject(rest, value)
      },
    }
  }
}


export const parseGraphqlServerError = err => {
  const {networkError} = err
  const {result} = networkError || {}
  const {errors} = result || {}
  const {message} = errors && errors[0] || {}
  const {details} = message || {}


  // console.log({details})

  /**
   * Flatten an array to deep object ([toto, tutu, tata] => { toto: {tutu: {tata: true}}})
   * @param Array path 
   * @param Object global 
   */
  const parsingFunction = (path, global) => {
    //End of the array, return field: true
    if (path.length == 1) {
      return {
        ...global,
        [path[0]]: true,
      }
    } else {
      const [key, ...rest] = path
      // console.log({key})
      // if (Number.isInteger(key)) {
      //   return {
      //     [key]: [
      //       ...(global && global[key]),
      //       ...parsingFunction(rest, global && global[key])
      //     ]
      //   }
      // }
      // Need to go deeper, recursing with path[n+1]
      return {
        [key]: {
          ...(global && global[key]),
          ...parsingFunction(rest, global && global[key])
        }
      }
    }

  }

  /**
   * Looping on each error, details is an array of Joi Errors
   * Using reduce in order to keep a global acc and populate it with errors
   */
  const parseDetail = details && details.reduce((acc, element) => {
    const {path} = element
    return {...acc, ...parsingFunction(path, acc)}
  }, {})

  return parseDetail
}
import { PhoneConfigModuleDisplay } from "components/backoffice/PHONE/Config/Module/PhoneConfigModuleDisplay"
import { Breadcrumb } from "react-bootstrap";
import { Link, useParams } from "react-router-dom"
import { URL_PHONE_CONFIG } from "static/constant/backoffice/url";

export const PhoneConfigModuleDisplayRoute = () => {

  const { id, token } = useParams<{ id: string; token: string }>()

  const hasCreds = id && token

  return <div>

    <Breadcrumb>
      <Breadcrumb.Item active>Téléphonie</Breadcrumb.Item>
      <Breadcrumb.Item >
        <Link to={"/manager" + URL_PHONE_CONFIG}>
          Liste des Configs
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item active>Config</Breadcrumb.Item>
    </Breadcrumb>


    {hasCreds && <PhoneConfigModuleDisplay id={id} token={token} />}
    {!hasCreds && <div className="alert alert-warning">
      No Creds
    </div>}

  </div>

}
import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, from, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from "graphql-ws";
// import {createBrowserHistory} from 'history'
// import { WebSocketLink } from 'apollo-link-ws';
import { findToken } from './User';
import { getMainDefinition } from '@apollo/client/utilities';

// export const history = createBrowserHistory();

export const getClient = () => {


  const wssURi = process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_ENDPOINT || ""
  // const wsLink = new WebSocketLink({
  //   uri: wssURi,
  //   options: {
  //     reconnect: true,
  //     connectionParams: {
  //       bearer: findToken()
  //     },
  //   }
  // });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: wssURi,
      connectionParams: {
        bearer: findToken()
      }
    }),
  );

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
  });

  const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key: any, value: any) => (key === '__typename' ? undefined : value);
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation).map((data) => {
      return data;
    });
  });

  const AuthLink = new ApolloLink((operation: any, next: any) => {
    operation.setContext((context: any) => {
      const { loginToken } = context
      const token = loginToken || findToken()
      return {
        ...context,
        headers: {
          ...context.headers,
          Authorization: `Bearer ${token}`,
        }
      }
    });

    return next(operation);
  });

  const HttpLinkWithBearer = from([
    // errorLink,
    cleanTypeName,
    AuthLink,
    httpLink,
  ]);

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    //@ts-ignore
    wsLink,
    //@ts-ignore
    HttpLinkWithBearer,
  );

  const cache = new InMemoryCache({
    // addTypename: false,
    dataIdFromObject: (object: any) => {
      return object.id || null
    },
    // @ts-ignore
  }).restore(window.__APOLLO_STATE__);

  const apolloClient = new ApolloClient({
    //@ts-ignore
    // link: HttpLinkWithBearer,
    link,
    cache,
  });
  return apolloClient
}

export const formatErrorResponseForJoi = (error: any) => {
  return error?.graphQLErrors && parseGraphqlServerError(error?.graphQLErrors[0]?.extensions?.exception?.details)
  // return error?.graphQLErrors[0]?.extensions?.exception?.details.map((detail: any) => ({...detail, field: detail.context.key})) || [error];
}


export const isFieldOnError = (errors: any, name: string) => {
  return errors && !!errors[name]
}

// export const isFieldOnError = (error: any[], name: string) => {
//   return error?.find((el: any) => el.field === name)
// }


export const parseGraphqlServerError = (details: any) => {

  /**
   * Flatten an array to deep object ([toto, tutu, tata] => { toto: {tutu: {tata: true}}})
   * @param Array path
   * @param Object global
   */
  const parsingFunction = (path: any[], global: any): any => {
    //End of the array, return field: true
    if (path.length === 1) {
      return {
        ...global,
        [path[0]]: true,
      }
    } else {
      const [key, ...rest] = path
      return {
        [key]: {
          ...(global && global[key]),
          ...parsingFunction(rest, global && global[key])
        }
      }
    }

  }

  /**
   * Looping on each error, details is an array of Joi Errors
   * Using reduce in order to keep a global acc and populate it with errors
   */
  const parseDetail = details && details.reduce((acc: any, element: any) => {
    const { path } = element
    return { ...acc, ...parsingFunction(path, acc) }
  }, {})

  return parseDetail
}

import serverListQuery from 'graphql/HOSTING/Server/query/serverList'

import "./style.scss"
import { useMutation, useQuery } from '@apollo/client'
import serverDelete from 'graphql/HOSTING/Server/mutation/serverDelete'
import { Server } from 'model'
import { IconEdit, IconSearch, Modal } from '@zipou/front_tools'
import { useState } from 'react'
import { ServerEdit } from '../Edit/ServerEdit'


type ServerListProps = {

}

export const ServerList = (props: ServerListProps) => {

  const [focusServer, updateFocusServer] = useState<Server>()

  const [mutate] = useMutation(serverDelete, {
    refetchQueries: [{
      query: serverListQuery
    }]
  })

  const onDelete = (id: string) => {
    mutate({
      variables: {
        id,
      },

    })
  }

  const { data } = useQuery<{ serverList: Server[] }>(serverListQuery)
  const serverList = data?.serverList || []

  return (
    <div className="server-list-container">
      {!!focusServer && <Modal display title='Editer' onClose={() => updateFocusServer(undefined)}>
        <ServerEdit serverId={focusServer?.id} onDone={() => updateFocusServer(undefined)} />
      </Modal>}

      <table className='table table-striped table-bordered'>
        <thead>
          <tr className='bg-dark text-white'>
            <th className='bg-dark text-white'>Nom</th>
            <th className='bg-dark text-white'>Type</th>
            <th className='bg-dark text-white'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {serverList?.map(server => <tr key={`server_${server?.id}`}>
            <td>{server?.name}</td>
            <td>{server?.type}</td>
            <td>
              <button className='btn btn-warning btn-sm' onClick={() => updateFocusServer(server)}>
                <IconEdit />
              </button>
            </td>
          </tr>)}
        </tbody>
      </table>

    </div >
  )
}

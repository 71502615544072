import React, {Component} from 'react'

import {ServerFirewallEdit} from 'components/backoffice/HOSTING/Server/Firewall/ServerFirewallEdit'
import {Breadcrumb} from 'components/common/Navigation/Breadcrumb/Breadcrumb'

export class HostingServerFirewallEditRoute extends Component {
  render() {
    //@ts-ignore
    const {match} = this.props
    const {params, url, path} = match
    const {serverId} = params
    return (
      <div>
        {/* <Breadcrumb match={match} /> */}
        <ServerFirewallEdit
          serverId={serverId}
        />
      </div>
    )
  }
}

import { Modal } from "@zipou/front_tools"

import {Impersonation} from "components/backoffice/APP/User/Profile/Impersonation"

import {URL_PROFILE, URL_PROFILE_IMPERSONATE} from "static/constant/backoffice/url"
import {userContext} from "context/User";
import {ProfileEdit} from "components/backoffice/APP/User/Profile/Edit/ProfileEdit";
import { useNavigate } from "react-router-dom";


type ProfileRouteProps = {
  displayImpersonate?: boolean,
}

export const ProfileRoute = ({displayImpersonate}: ProfileRouteProps) => {

  const navigate = useNavigate()


  return <div>
    <Modal display={displayImpersonate || false} title={"Impersonation"} centered={false} onClose={() => navigate("/manager" + URL_PROFILE)} >
      <Impersonation onDone={() => navigate("/manager" + URL_PROFILE)} />
    </Modal>
    <h1>Bienvenue dans votre espace</h1>
    <userContext.Consumer>
      {(user: any) => {
        return <ProfileEdit user={user} />
      }}
    </userContext.Consumer>
  </div>
}
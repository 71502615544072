import { useMutation, useQuery } from "@apollo/client"
import { TagInput } from "components/common/Form/TagInput/TagInput"
import transactionTagAddGql from "graphql/BILLING/Transaction/Tag/mutation/transactionTagAdd.gql"
import transactionTagDeleteGql from "graphql/BILLING/Transaction/Tag/mutation/transactionTagDelete.gql"
import transactionGetGql from "graphql/BILLING/Transaction/query/transactionGet.gql"
import { Transaction } from "model"

type TransactionListDisplayProps = {
  transactionId: string,
}

export const TransactionListDisplay = (props: TransactionListDisplayProps) => {

  const transactionId = props?.transactionId

  const [mutate] = useMutation(transactionTagAddGql)
  const [mutateDelete] = useMutation(transactionTagDeleteGql)


  const { data } = useQuery<{ transaction: Transaction }>(transactionGetGql, {
    variables: {
      id: transactionId,
    },
    skip: !transactionId,
  })

  const transaction = data?.transaction
  const tagList = transaction?.TransactionTag?.filter(tTag => !tTag.intermediate).map(ttag => ttag?.Tag) || []

  const onDelete = (tagId?: string) => {
    mutateDelete({
      variables: {
        tagId,
        transactionId,
      },
      refetchQueries: [{
        query: transactionGetGql,
        variables: {
          id: transactionId
        }
      }]
    })
  }

  return <>
    <div>
      <span>{transaction?.label}</span>
    </div>
    <div className="mt-1">
      <TagInput
        //@ts-ignore
        tagSelectedList={tagList || []}
        onSelect={(tag) => {
          mutate({
            variables: {
              tagId: tag?.id,
              transactionId: transaction?.id,
            },
            refetchQueries: [{
              query: transactionGetGql,
              variables: {
                id: transactionId,
              }
            }]
          })
        }}
        onDelete={(tag) => {
          onDelete(tag?.id)
        }} />
    </div>
  </>

}
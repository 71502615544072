import gql from "graphql-tag"
import ServerFragment from "../fragment/ServerFragment"

export default gql`

  ${ServerFragment}

  mutation serverAdd($input: ServerInput!){
    serverAdd(input: $input) {
      ...ServerFragment
    }
  }

`
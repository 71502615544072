import {gql} from '@apollo/client'
import NotificationFragment from '../fragment/NotificationFragment'

export default gql`

  ${NotificationFragment}

  query notificationList {
    notificationList {
      ...NotificationFragment
    }
  }
`
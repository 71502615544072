import React from "react"
// import ReactMarkdown from 'react-markdown'
import markdownIt from "markdown-it"

import CGUMD from "static/cgu.md"


export const CGU = () => {

  const markdownHTML = markdownIt().render(CGUMD({
    companyName: "box4b",
    companyWebsite: "https://www.box4b.fr",
    companyContact: "romain@box4b.fr",
    companyAddress: "104 rue nationale, 59000 Lille",
    companySiret: "81750079600013"
  }))

  return <div>
    <h1>Conditions Générales d'utilisation des services box4b</h1>
    <div>
      <div dangerouslySetInnerHTML={{__html: markdownHTML}} />
    </div>
  </div>
}
import { useMutation, useQuery } from "@apollo/client"
import { Modal } from "@zipou/front_tools"

import { guestContext } from "context/guestContext"
import pictureAddGql from "graphql/WEDDING/Picture/mutation/pictureAdd.gql"
import { Picture, PictureCategoryEnum, PictureInput } from "model"
import { useContext, useEffect, useRef, useState } from "react"
import { translateCategory } from "../Category"
import pictureListByGuestIdGql from "graphql/WEDDING/Picture/query/pictureListByGuestId.gql"

type PictureFormProps = {
  page: number,
  onDone: () => void,
}

export const PictureForm = (props: PictureFormProps) => {

  const page = props?.page || 1
  const inputRef = useRef<HTMLInputElement>(null)

  const guestCtx = useContext(guestContext)

  const [input, updateInput] = useState<PictureInput>({
    base64: "",
    // @ts-ignore
    category: null,
    guestId: guestCtx?.guest?.id,
  })


  const [loading, updateLoading] = useState<boolean>(false)
  const [error, updateError] = useState<boolean>(false)

  const { data } = useQuery<{ list: Picture[] }>(pictureListByGuestIdGql, {
    variables: {
      guestId: guestCtx?.guest?.id,
    },
    // fetchPolicy: "cache-and-network",
  })

  const pictureList = data?.list
  const categoryAlreadyFilled = pictureList?.reduce<any>((acc, picture) => {
    acc[`${picture.category}`] = true
    return acc
  }, {}) || {}

  const categoryNotFilled = Object.keys(PictureCategoryEnum).filter(el => !categoryAlreadyFilled[el])

  // console.log("categoryAlreadyFilled", categoryAlreadyFilled)
  // console.log("categoryNotFilled", categoryNotFilled)

  const [mutate] = useMutation(pictureAddGql, {
    refetchQueries: [{
      query: pictureListByGuestIdGql,
      variables: {
        guestId: guestCtx?.guest?.id,
      }
    }]
  })

  const onButtonClick = () => {
    return inputRef.current?.click()
  }

  const handleInputFileChange = (event: any) => {
    updateLoading(true)
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onload = (upload) => {
      // console.log('UPLOAD', upload)
      mutate({
        variables: {
          input: {
            ...input,
            base64: upload?.target?.result,
          }
        }
      })
        .then((response) => {
          // response.data.picture.url
          console.log("ONDONE", response.data.picture.url)
          updateLoading(false)
          props?.onDone()

          // props.onDone(response.data.picture.url)
        })
        .catch((e: any) => {
          console.log("EERROR", e)
          updateLoading(false)

        })
      // uploadStaticImage(upload.target.result)
      // .then((url)=> {

      // });
    }
    if (file) {
      const { size } = file;

      if (size > 10240000) {
        // updateOversizeError(true)
        console.log("OVERSIZE")
        updateLoading(false)
        updateError(true)
        return;
      }

      const type = file.type.split("/")[0];
      if (type === "image") {
        reader.readAsDataURL(file);
      }
    }
  }

  // const categoryAlreadyFilled


  return <>
    <Modal display={error} onClose={() => updateError(false)}>
      <div className="d-flex justify-content-center align-items-center">
        <div className="alert alert-danger">
          <p>Désolé, une erreur est survenue, probablement une photo trop volumineuse</p>
          <p>Pouvez vous réessayer ?</p>
        </div>
      </div>
    </Modal>
    <Modal display={loading}>
      <div className="d-flex justify-content-center align-items-center">
        <span className="icon-loading mr-2" style={{ fontSize: 30 }}></span>
        <span>Envoi en cours...</span>
      </div>
    </Modal>
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="d-flex flex-column align-items-start text-align-left" style={{ width: "100%" }}>
        <span className="pl-1">Selectionner la catégorie de la photo</span>
        <select className="form-control mt-1" value={input.category || ""} onChange={e => {
          updateInput({
            ...input,
            category: e.target.value,
          })
        }}>
          <option value="" disabled>Choisissez une catégorie</option>
          <optgroup label="Categorie non renseignée">
            {categoryNotFilled.map((key: string) => {
              return <option key={`picture_category_${key}`} value={key}>{translateCategory(key)}</option>
            })}
          </optgroup>
          <optgroup label="Categorie déjà renseignée">
            {Object.keys(categoryAlreadyFilled).map((key: string) => {
              return <option key={`picture_category_${key}`} value={key}>{translateCategory(key)}</option>
            })}

          </optgroup>

        </select>

      </div>
      <button className='' style={{ backgroundColor: "white", border: "none" }} onClick={onButtonClick}>
        <img src={"https://wedding-cam-raf.s3.fr-par.scw.cloud/pictures/import2.png"} width={150} className='img-fluid' alt="logo" />
      </button>
      <input type="file" ref={inputRef} accept="image/*" style={{ display: "none" }} onChange={handleInputFileChange} ></input>
    </div>
  </>
}
import React from 'react'

import {ServerDetails} from 'components/backoffice/HOSTING/Server/Details/ServerDetails'
import {Breadcrumb} from 'components/common/Navigation/Breadcrumb/Breadcrumb'

export const HostingServerDetailsRoute = (props: any) => {

  const {match} = props
  const {params} = match
  const {serverId} = params

  return (
    <div>
      {/* <Breadcrumb match={match} /> */}
      <h1>Infos Serveur</h1>
      <ServerDetails
        serverId={serverId}
      />
    </div>
  )
}

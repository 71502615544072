import React from "react"
import yaml from "js-yaml"

import './style.scss'

import codemirror from 'codemirror/lib/codemirror.js';
import 'codemirror/mode/yaml/yaml.js';
import 'codemirror/lib/codemirror.css';

export class TemplateEdit extends React.Component {

  
  constructor(props) {
    super(props)
    const {template} = props
    const {content = ""} = template || {}
    this.state = {
      template,
    }
  }

  componentDidUpdate(prevProps) {
    const {template} = this.props
    if (template && !prevProps.template) {
      const {content} = template
      this.codemirror.getDoc().setValue(content)

      this.setState({
        template,
      })
    }
  }

  _handleCodemirrorChange(e) {
    const value = e.getValue()
    const {template} = this.state
    this.setState({
      template: {
        ...template,
        content: value,
      }
    })
  }

  componentDidMount() {
    if (this.textarea) {
      this.codemirror = codemirror.fromTextArea(this.textarea, {
        mode: 'yaml',
        lineNumbers: true
      })
      const {template} = this.state
      const {content} = template || {}
      content && this.codemirror.getDoc().setValue(content)
      this.codemirror.on('change', this._handleCodemirrorChange.bind(this));
    }
  }

  _checkYaml(content) {
    try {
      const doc = yaml.safeLoad(content);
      return Promise.resolve(doc);
    } catch(e) {
      return Promise.reject(e)
    }
  }

  _handleInputChange(event) {
    const {template} = this.state
    const {name, value} = event.target
    this.setState({
      template: {
        ...template,
        [name]: value,
      }
    })
  }

  _handleContentChange(event) {
    const {template} = this.state
    const {name, value} = event.target
    this.setState({
      template: {
        ...template,
        [name]: value,
      }
    })
  }

  _handleSubmit(e) {
    e.preventDefault()
    const {onTemplateUpdate, onTemplateAdd} = this.props
    const {template} = this.state
    const {content} = template
    return this._checkYaml(content)
    .then(() => {
      return new Promise(resolve => {
        if (template && template.id) {
          //Server Update
          resolve(onTemplateUpdate && onTemplateUpdate({
            ...template,
          }))
        } else {
          resolve(onTemplateAdd && onTemplateAdd({
            ...template,
          }))
        }
      })
    })
    .then(() => {
      this.context.setMessage(<h1>OK</h1>)
    })
    .catch(() => {
      this.context.setError(<h1>Erreur</h1>)
    })
  }

  render() {
    const {template} = this.state
    const {name = "", type = "", action = "", version = ""} = template || {}

    return <div className="template-edit-container">
    <h1>Template</h1>
    <h2>{name}</h2>
    <div className="template-edit-form">
      <form onSubmit={this._handleSubmit.bind(this)}>
        <div className="input-group">
          <div className="input-group-text">Nom</div>
          <input type='text' className="form-control" name="name" value={name} placeholder="Nom" onChange={this._handleInputChange.bind(this)} />
        </div>
        <div className="input-group">
          <div className="input-group-text">Type</div>
          <select className="form-control" name="type" value={type} onChange={this._handleInputChange.bind(this)}>
            <option value="" disabled>Choisissez une valeur</option>
            <option value="TYPE_BACKUP">BACKUP</option>
            <option value="TYPE_PHONE">PHONE</option>
            <option value="TYPE_WEB">WEB</option>
          </select>
        </div>
        <div className="input-group">
          <div className="input-group-text">Action</div>
          <select className="form-control" name="action" value={action} onChange={this._handleInputChange.bind(this)}>
            <option value="" disabled>Choisissez une valeur</option>
            <option value="ACTION_UPDATE">ACTION_UPDATE</option>
            <option value="ACTION_INSTALL">ACTION_INSTALL</option>
          </select>
        </div>
        <div className="input-group">
          <div className="input-group-text">Version</div>
          <input type='text' className="form-control" name="version" value={version} placeholder="Version" onChange={this._handleInputChange.bind(this)} />
        </div>
        <div className="input-group">
          <textarea ref={ref => this.textarea = ref} className="form-control" />
        </div>
        <div className="input-group">
          <button type="submit">Enregistrer</button>
        </div>
      </form>
    </div>
  </div>
  }
}

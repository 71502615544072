import CursorFragment from '../../../Cursor/fragment/CursorFragment'
import {gql} from '@apollo/client'
import InvoiceListFragment from '../fragment/InvoiceListFragment'

export default gql`

	${CursorFragment}
	${InvoiceListFragment}

	query invoiceList($cursor: CursorInput!, $input: InvoiceListInput!) {
		list: invoiceList(
			cursor: $cursor
			input: $input,
		) {
			nodes {
				...InvoiceListFragment
			}
			cursor {
				...CursorFragment
			}
		}
	}
`
import { CameraListModuleDisplay } from "components/backoffice/VIDEO/Camera/Module/CameraListModule"
import { Breadcrumb as BootstrapBreadcrumb } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { URL_VIDEO_CONFIG_LIST } from "static/constant/backoffice/url"


export const CameraListModuleDisplayRoute = () => {

  const params = useParams()
  const { id, token } = params
  const hasParams = !!id && !!token

  return <div className="video-config-list-route">


    <BootstrapBreadcrumb>
      <BootstrapBreadcrumb.Item active>Video</BootstrapBreadcrumb.Item>
      <BootstrapBreadcrumb.Item >
        <Link to={"/manager" + URL_VIDEO_CONFIG_LIST}>
          Configurations
        </Link>
      </BootstrapBreadcrumb.Item>
      <BootstrapBreadcrumb.Item active>Camera</BootstrapBreadcrumb.Item>
    </BootstrapBreadcrumb>


    {hasParams && <CameraListModuleDisplay videoConfigId={id || ""} token={token || ""} />}
  </div>
}
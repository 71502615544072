import { useMutation } from "@apollo/client"
import { HostingConfigInput } from "model"
import { useState } from "react"
import { HostingConfigForm } from "../Form/HostingConfigForm"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import hostingConfigAddGql from "graphql/HOSTING/Config/mutation/hostingConfigAdd.gql"

type HostingConfigAddProps = {

  onDone?: () => void,

}


export const HostingConfigAdd = ({ onDone }: HostingConfigAddProps) => {

  const [hostingConfig, updateHostingConfig] = useState<HostingConfigInput>()
  const [errors, updateErrors] = useState()
  const [mutate] = useMutation(hostingConfigAddGql)


  const onSave = () => {
    mutate({
      variables: {
        input: hostingConfig
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }


  return <>

    <HostingConfigForm hostingConfigInput={hostingConfig} onChange={updateHostingConfig} errors={errors} />

    <div>
      <button className="btn btn-sm btn-dark" onClick={() => onSave()}>
        Enregistrer
      </button>
    </div>

  </>
}
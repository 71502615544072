import {gql} from "@apollo/client";
import ClusterProvisioningTaskFragmentGql from "../fragment/ClusterProvisioningTaskFragment.gql";

export default gql`

  ${ClusterProvisioningTaskFragmentGql}

  mutation clusterProvisioningTaskRun($id: String!) {
    task: clusterProvisioningTaskRun(id: $id) {
      ...ClusterProvisioningTaskFragment
    }
  }


`
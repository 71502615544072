import FirewallFragment from "../fragment/FirewallFragment";
import gql from "graphql-tag";

export default gql`

  ${FirewallFragment}

  mutation firewallUpdate($id: String!, $input: [FirewallRuleInput!]!) {
    firewallUpdate(id: $id, input: $input) {
      ...FirewallFragment
    }
  }
`
import React, {useState, useEffect} from "react";
import BootstrapTable from "react-bootstrap/Table"

type TableProps = {
  list: any[],
  fields: any,
  panelTitle?: string,
  emptyLabel?: string,
  multiSelect?: boolean,
  onRowClick?: (data: any) => void,
  onSelect?: (data: any) => {},
}

export const Table = (props: TableProps) => {

  const [list, updateList] = useState<any[]>(props.list)
  const [sortField, updateSortField] = useState<string>("")
  const [sortDir, updateSortDir] = useState("ASC")
  const [selected, updateSelected] = useState<any>({})

  useEffect(() => {
    updateList(props.list)
  }, [props.list])


  const _getValueFromField = (key: any, value: any) => {
    const {fields} = props
    const {format} = fields[key]
    if (format) {
      const final = (value[key] === undefined) ? value : value[key]
      return format(final)
    } else {
      return value[key]
    }
  }

  const _updateSortField = (field: any) => {
    updateSortField(field)
    updateSortDir((sortDir) ? (sortDir === "ASC") ? "DESC" : "ASC" : "ASC");
  }

  const _handleSorting = () => {
    if (!sortField) return list;
    return list.sort((el1, el2) => {
      let cField = sortField?.split(".");
      if (cField.length === 1) {
        let val = (el1[sortField] > el2[sortField]) ? -1 : 1;
        return (sortDir === "DESC") ? val : -1 * val;
      } else {
        let one = sortField.split('.').reduce((a: any, b: any) => a[b], el1);
        let two = sortField.split('.').reduce((a: any, b: any) => a[b], el2);
        let val = (one > two) ? -1 : 1;
        return (sortDir === "DESC") ? val : -1 * val;
      }
    })
  }

  const _handleSelect = (row: any, event: any) => {
    let checked = event.target.checked;
    if (checked) {
      selected[row.id] = row;
    } else {
      delete selected[row.id];
    }
    updateSelected(selected)
    if (props.onSelect) {
      props.onSelect(selected);
    }
  }

  const {fields, onRowClick, panelTitle, multiSelect, emptyLabel} = props;
  const listFiltered = _handleSorting()

  const hasElements = (listFiltered && listFiltered.length > 0)

  console.log({hasElements, listFiltered, state: list, props: props.list})

  return (
    <div>
      {panelTitle && <h2 style={{textAlign: "left"}}>{panelTitle}</h2>}
      {hasElements && <div className="table-list-container" style={{overflow: "visible"}}>
        <BootstrapTable striped bordered hover>
          <thead>
            <tr className="bg-dark text-white">
              {multiSelect && <td></td>}
              {Object.keys(fields).map((key, index) => {
                const field = fields[key]
                const {label, className} = field
                return <td style={{cursor: "pointer"}} className={className} key={`${label}-${index}`} onClick={() => _updateSortField(key)}>
                  <span style={{fontSize: 16}}>{label}</span>
                </td>
              })}
            </tr>
          </thead>
          <tbody>
            {listFiltered && listFiltered.map((row, rowIndex) => {
              return (
                <tr key={"row" + rowIndex}>
                  {multiSelect && <td key={rowIndex + "_select"}><input type="checkbox" onChange={(event: any) => _handleSelect(row, event)}></input></td>}
                  {Object.keys(fields).map((key, index) => {
                    const field = fields[key]
                    const {className} = field
                    return <td className={className} key={rowIndex + "_" + index} onClick={() => onRowClick && onRowClick(list[rowIndex])}>{_getValueFromField(key, row)}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </BootstrapTable></div>}
      {!hasElements && <div className="alert alert-danger">{emptyLabel || "Nothing to show"}</div>}
    </div>
  )
}

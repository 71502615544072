import { gql } from "@apollo/client";

export default gql`

  fragment OrderLineListFragment on OrderLine {
    id
    itemId
    quantity
    Order {
      id
      publicId
    }
  }

`
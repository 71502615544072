import {useState} from "react"
import {IbanElement, useElements, useStripe} from '@stripe/react-stripe-js';

import "./style.scss"

export type BankAccountFormProps = {
  bankAccount: any,
  onChange: (data: any) => void,
}

export const BankAccountForm = (props: BankAccountFormProps) => {

  // const [success, updateSuccess] = useState(false);
  // const [error, updateError] = useState(false);


  const [stripeSource, updateStripeSource] = useState<any>(props?.bankAccount?.stripeId || "")
  const [bankName, updateBankName] = useState<any>(props?.bankAccount?.bankName || "")

  const stripe = useStripe();
  const elements = useElements();

  const onIbanChange = (e: any) => {
    console.log('e', e)
    if (e.complete) {
      console.log("COMPLETE")
      createStripeSource()
    }
  }

  const onChange = (stripeId: string, bankName: string) => {
    if (bankName && stripeId) {
      props?.onChange({
        stripeId,
        bankName,
      })
    }
  }

  const createStripeSource = () => {
    console.log(`Creating stripe source`)
    if (!stripe || !elements) return;
    //@ts-ignore
    stripe.createSource(elements.getElement(IbanElement), {
      type: 'sepa_debit',
      currency: 'eur',
      owner: {
        name: bankName,
      },
    })
      .then((stripeResponse: any) => {
        console.log(`stripeResponse`, stripeResponse)

        const {source, } = stripeResponse
        // const sepa_debit = source?.sepa_debit
        const sourceId = source.id
        updateStripeSource(sourceId)
        onChange(sourceId, bankName)
      })
      .catch((error: any) => {
        console.log({error}) //eslint-disable-line
      })
  }

  const onBankNameChange = (e: any) => {
    updateBankName(e.target.value)
    onChange(stripeSource, e.target.value)

  }

  const hasStripeSource = !!stripeSource

  return (
    <div className="bank-account-item">
      <div className="account-name col-12">
        <div className="input-group">
          <div className="input-group-text">Nom du compte</div>
          <input type="text" name="bankName" value={bankName} className="form-control" placeholder="Nom du compte" onChange={onBankNameChange} />
        </div>
      </div>
      {!hasStripeSource && <div className="account-number col-12">
        <div className="input-group">
          <div className="input-group-text">IBAN</div>
          <IbanElement className="stripe-iban-element form-control" options={{
            disabled: hasStripeSource,
            supportedCountries: ['SEPA'],
            // Elements can use a placeholder as an example IBAN that reflects
            // the IBAN format of your customer's country. If you know your
            // customer's country, we recommend that you pass it to the Element as the
            // placeholderCountry.
            placeholderCountry: 'FR',
            // style: IBAN_STYLE,
          }}
            onChange={onIbanChange} />
        </div>
      </div>}
      <div className="account-name col-12">
          {hasStripeSource && <div className="alert alert-warning">Vous ne pouvez pas modifier un IBAN déjà saisi</div>}
      </div>

      {/* <span className="icon-check"></span> */}
    </div>
  )
}


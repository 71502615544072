import { gql } from "@apollo/client";
import TagFragment from "../fragment/TagFragment";

export default gql`

  ${TagFragment}

  mutation tagEdit($id: String!, $input: TagInput!) {
    tag: tagEdit(id: $id, input: $input) {
      ...TagFragment
    }
  }

`
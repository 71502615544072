import React from 'react'

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

export type StripeContainerProps = {
  children: any,
  stripePk: string,
}

export const StripeContainer = (props: StripeContainerProps) => {

  const {children} = props

  // const [stripe, updateStripe] = useState(null)
  // // Make sure to call `loadStripe` outside of a component’s render to avoid
  // // recreating the `Stripe` object on every render.
  const stripePromise = props?.stripePk ? loadStripe(props?.stripePk) : null;


  // useEffect(() => {
  //   if (!stripe && document.querySelector('#stripe-js')) {
  //     document.querySelector('#stripe-js').addEventListener('load', () => {
  //       // Create Stripe instance once Stripe.js loads
  //       // console.log("Stripe has been loaded")
  //       updateStripe(window.Stripe(stripe_publishable));
  //     })
  //   };
  // });

  return (
    <Elements stripe={stripePromise} options={{
      fonts: [
        {
          cssSrc: "https://fonts.googleapis.com/css?family=Lato"
        }
      ]
    }}>
      <div>
        {children}
      </div>
    </Elements>
  )


}

// export class StripeContainerClass extends Component {

//   constructor(props) {
//     super(props)
//     this.state = {
//       stripe: null
//     }
//   }

//   componentDidMount() {
//     const {stripe_publishable} = Vars
//     if (window.Stripe) {
//       this.setState({stripe: window.Stripe(stripe_publishable)});
//     }
//     // else {
//     //   document.querySelector('#stripe-js').addEventListener('load', () => {
//     //     // Create Stripe instance once Stripe.js loads
//     //     this.setState({stripe: window.Stripe(stripe_publishable)});
//     //   });
//     // }
//   }


//   render() {
//     const {children} = this.props
//     const {stripe} = this.state

//     // console.log({stripe})

//     return (
//       <StripeProvider stripe={stripe}>
//         <Elements fonts={[
//           {cssSrc: "https://fonts.googleapis.com/css?family=Lato"}
//         ]}>
//           <div>
//           {children}
//           </div>
//         </Elements>
//       </StripeProvider>
//     )
//   }
// }

import {gql} from "@apollo/client"
import OrderSearchFragment from "../fragment/OrderSearchFragment"

export default gql`

  ${OrderSearchFragment}

  mutation orderUpdate($id: String!, $input: OrderInput!) {
    orderUpdate(id: $id, input: $input) {
      ...OrderSearchFragment
    }
  }

`
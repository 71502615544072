import React from "react"
import PropTypes from "prop-types"
import {useQuery} from "@apollo/client"
import {Link} from "react-router-dom"

import {DateFormat} from "components/common/Format/DateFormat"
import {CurrencyFormat} from "components/common/Format/CurrencyFormat"

import {PaymentStatus} from "components/backoffice/BILLING/Payment/PaymentStatus"

import paymentGetByIdQuery from "graphql/BILLING/Payment/query/paymentGetById"
// import paymentConfirmIntentMutation from "graphql/BILLING/Payment/mutation/paymentConfirmIntent"

// import {ProgressBar, ProgressBarStep} from "components/common/Progress/Progress"
// import ProgressBar from "react-bootstrap/ProgressBar"

import {URL_BILLING_INVOICE_DETAILS_TEMPLATE} from "static/constant/backoffice/url"

export const PaymentDetails = (props) => {

  const {paymentId} = props

  const {data: {paymentGetById} = {}} = useQuery(paymentGetByIdQuery, {
    variables: {
      paymentId,
    }
  })

  const {id, status, date, value, Invoice} = paymentGetById || {}

  return <div>
    <table className="table  table-bordered">
      <tr>
        <th>Identifiant</th>
        <td>{id}</td>
      </tr>
      <tr>
        <th>Date</th>
        <td><DateFormat unixTimestamp={date} /></td>
      </tr>
      <tr>
        <th>Montant</th>
        <td><CurrencyFormat value={value} /></td>
      </tr>
      <tr>
        <th>Statut</th>
        <td><PaymentStatus status={status} /></td>
      </tr>
      {Invoice && <tr>
        <th>Facture</th>
        <td><Link to={URL_BILLING_INVOICE_DETAILS_TEMPLATE.replace(":id", Invoice.id)}><button className="btn btn-dark">{Invoice.publicId}</button></Link></td>
      </tr>}
    </table>
    <div>

    </div>
  </div>
}

PaymentDetails.propTypes = {
  paymentId: PropTypes.string,
}


/*


      <ProgressBar>
        <ProgressBar variant="warning" striped animated now={50}/>
        <ProgressBar variant="success" striped animated now={50} />
      </ProgressBar>

*/
import { useState, useContext } from "react"
import { useMutation, useQuery } from "@apollo/client"

import { ClusterForm } from "../Form/ClusterForm"


import clusterAdd from "graphql/HOSTING/Cluster/Cluster/mutation/clusterAdd"

import { Cluster, ClusterInput, HostingConfig } from "model"
import { SelectInput } from "@zipou/front_tools"
import hostingConfigListGql from "graphql/HOSTING/Config/query/hostingConfigList.gql"
import { formatErrorResponseForJoi } from "helpers/Apollo"

type ClusterAddProps = {
  onDone?: () => void,
}


export const ClusterAdd = ({ onDone }: ClusterAddProps) => {

  const [errors, updateErrors] = useState(false)
  const [success, updateSuccess] = useState(false)
  const [isLocked, updateIsLocked] = useState(false)
  const [hostingConfigId, updateHostingConfigId] = useState<string>()
  const [cluster, updateCluster] = useState<ClusterInput>()

  const [mutate] = useMutation(clusterAdd)

  const { data } = useQuery<{ list: HostingConfig[] }>(hostingConfigListGql)


  const onSubmit = () => {
    updateIsLocked(true)
    mutate({
      variables: {
        hostingConfigId,
        input: {
          name: cluster?.name,
          ClusterConfig: cluster?.ClusterConfig?.map((clusterConfig) => {
            const { id, ClusterRoleConfig, serverId, name } = clusterConfig
            return {
              id,
              name,
              serverId,
              // Server: Server?.id,
              ClusterRoleConfig: ClusterRoleConfig?.map((roleConfigItem) => {
                const { clusterRoleId, name, options, version, status, port } = roleConfigItem
                return {
                  clusterRoleId,
                  name,
                  version,
                  status,
                  port,
                  options,
                }
              })
            }
          })
        }
      }
    })
      .then((response) => {

        updateSuccess(true)
        onDone && onDone()
        updateIsLocked(false)
        updateErrors(false)

      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
        updateIsLocked(false)
      })
  }

  const onChange = (cluster: any) => {
    updateCluster(cluster)
  }


  return <div className="cluster-edit-container">
    {success && <div className="alert alert-success">
      OK :)
    </div>}
    <h2>Hosting Config </h2>
    <SelectInput label="Config" value={hostingConfigId || ""} choiceList={data?.list?.map(el => ({ id: el.id, label: el.name || el.id })) || []} id="" errors={errors} onChange={(v) => updateHostingConfigId(v)} />
    <ClusterForm errors={errors} cluster={cluster as ClusterInput} onChange={onChange} />
    <div className="actions pt-5">
      <button className="btn btn-dark" onClick={onSubmit} disabled={isLocked}>Enregistrer</button>
    </div>
  </div >
}
import gql from "graphql-tag";
import GuestFragment from "../fragment/GuestFragment";

export default gql`

  ${GuestFragment}

  query guestGet($id: String!) {
    guest: guestGet(id: $id) {
      ...GuestFragment
    }
  }

`

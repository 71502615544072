import React, { useContext, useState, FormEvent } from "react"

import './style.scss'


import { useQuery, useMutation } from "@apollo/client"

import firewallUpdateMutation from 'graphql/HOSTING/Firewall/mutation/firewallUpdate'
import { parseGraphqlServerError } from "helpers/Error"
import firewallGetByServerIdGql from "graphql/HOSTING/Firewall/query/firewallGetByServerId.gql"
import serverList from "graphql/HOSTING/Server/query/serverList"
import { Firewall } from "model"
import { IconTrash } from "@zipou/front_tools"
import { formatErrorResponseForJoi } from "helpers/Apollo"

type ServerFirewallEditProps = {
  serverId: string,
  onDone?: () => void,
}

export const ServerFirewallEdit = ({ onDone, serverId }: ServerFirewallEditProps) => {

  const [firewall, updateFirewallState] = useState<any>(null)
  const [displayMessage, updateDisplayMessage] = useState<boolean>(false)
  const [errorsInRow, updateErrorsInRow] = useState<any>(null)
  const [errors, updateErrors] = useState<any>(null)

  const [mutate] = useMutation(firewallUpdateMutation, {
    refetchQueries: [{
      query: serverList,
    }]
  })

  const updateFirewall = (firewall: any) => {
    updateDisplayMessage(false)
    updateFirewallState(firewall)
  }

  const { data } = useQuery<{ firewall: Firewall }>(firewallGetByServerIdGql, {
    skip: !serverId,
    variables: {
      id: serverId
    },
    onCompleted: (data) => {
      updateFirewall(data?.firewall)
    }
  })

  const _handleRuleAdd = () => {
    updateFirewall({
      ...firewall,
      Rules: [
        ...(firewall?.Rules || []),
        {
          id: Date.now().toString(),
          position: 500,
          from: "0.0.0.0/0",
          port: null,
          portRangeStart: null,
          portRangeEnd: null,
          type: null,
          action: "REJECT",
        }
      ]
    })
  }

  const _handleChange = (id: string, e: any) => {
    const { name, value } = e.target
    updateErrors(false)
    updateFirewall({
      ...firewall,
      Rules: firewall.Rules.map((rule: any) => (rule.id === id) ? {
        ...rule,
        [name]: value
      } : rule)
    })
  }

  const _handleNumberChange = (id: string, e: any) => {
    const { name, value } = e.target
    updateFirewall({
      ...firewall,
      Rules: firewall.Rules.map((rule: any) => (rule.id === id) ? {
        ...rule,
        [name]: (value && Number(value)) || null
      } : rule)
    })
  }

  const _handleRuleDelete = (id: string) => {
    updateFirewall({
      ...firewall,
      Rules: firewall.Rules.filter((rule: any) => (rule.id !== id))
    })
  }

  const _handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const { id, Rules } = firewall || {}

    mutate({
      variables: {
        id,
        input: Rules.map((el: any) => {
          const { __typename, ...rest } = el;
          return rest;
        })
      }
    })
      .then(() => {
        updateErrorsInRow(null)
        updateDisplayMessage(true)
        onDone && onDone()
      })
      .catch((e: any) => {
        updateDisplayMessage(false)
        updateErrorsInRow(parseGraphqlServerError(e))
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  const hasHerrors = errors && Object.keys(errors).length > 0

  const hasRules = firewall?.Rules?.length > 0
  return <div className="firewall-container">
    {displayMessage && <div>
      <div className="alert alert-success">OK</div>
    </div>}
    {hasHerrors && <div className="alert alert-danger">
      Erreur
    </div>}
    <form onSubmit={_handleSubmit}>
      <div className="card">
        <div className="card-body">
          <div className="firewall-rule-container">
            <table className="table table-bordered">
              <thead>
                <tr style={{ fontSize: 12 }}>
                  <th>Ordre</th>
                  <th>Origine</th>
                  <th>Port</th>
                  <th>Actions</th>
                  <th>Desc</th>
                </tr>
              </thead>
              <tbody>
                {!hasRules && <tr>
                  <td colSpan={5}>
                    <div className="alert alert-info">Aucune règle</div>

                  </td>
                </tr>}
                {hasRules && [...firewall?.Rules].sort((el1: any, el2: any) => el1.position - el2.position).map((rule: any, index: number) => {
                  const isRowOnError = errorsInRow && !!errorsInRow[index]
                  const { id, action, position, from, type, port, portRangeStart, portRangeEnd, desc } = rule

                  return (
                    <tr key={`rule_${id}`} className={`rule-row ${action && action.toLowerCase()}`}>
                      <td className="">
                        <input size={2} className="form-control form-control-sm position" type="text" name="position" value={position} onChange={(e: any) => _handleNumberChange(id, e)} />
                      </td>
                      <td>
                        <input size={18} className="form-control form-control-sm from" type="text" name="from" value={from} onChange={(e: any) => _handleChange(id, e)} />
                      </td>
                      <td style={{ display: "flex" }}>
                        <select className="form-control form-control-sm type" style={{ minWidth: 120 }} value={type || ""} name="type" onChange={(e: any) => _handleChange(id, e)}>
                          <option value="" disabled>PORT</option>
                          <option value="ALL">ALL</option>
                          <option value="TCP">TCP</option>
                          <option value="UDP">UDP</option>
                        </select>
                        {type && type != "ALL" && <>
                          <input className="form-control form-control-sm port" type="text" name="port" value={port || ""} onChange={(e: any) => _handleNumberChange(id, e)} />
                          <div style={{ display: "flex" }}>
                            <input className="form-control form-control-sm port-range" type="text" name="portRangeStart" value={portRangeStart || ""} onChange={(e: any) => _handleNumberChange(id, e)} />
                            to
                            <input className="form-control form-control-sm port-range" type="text" name="portRangeEnd" value={portRangeEnd || ""} onChange={(e: any) => _handleNumberChange(id, e)} />
                          </div>
                        </>}
                      </td>
                      <td>
                        <select className={`form-control form-control-sm ${action && action.toLowerCase()}`} value={action} name="action" onChange={(e: any) => _handleChange(id, e)}>
                          <option value="ACCEPT">ACCEPT</option>
                          <option value="REJECT">REJECT</option>
                          <option value="DROP">DROP</option>
                        </select>
                      </td>
                      <td>
                        <input type="text" className="form-control form-control-sm" value={desc || ""} onChange={(e: any) => {
                          updateFirewall({
                            ...firewall,
                            Rules: firewall.Rules.map((rule: any) => (rule.id === id) ? {
                              ...rule,
                              desc: e.target.value,
                            } : rule)
                          })
                        }} />
                      </td>
                      <td>
                        <button className="btn btn-sm btn-danger" onClick={() => _handleRuleDelete(id)}>
                          <IconTrash />
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer">
          <button type="button" className="btn btn-sm btn-dark" onClick={_handleRuleAdd}>Ajouter une règle</button>&nbsp;
          <button type="submit" className="btn btn-sm btn-info">Enregistrer</button>&nbsp;
        </div>
      </div>
    </form>
  </div>
}

import {gql} from '@apollo/client'
import NotificationFragment from '../fragment/NotificationFragment'

export default gql`

  ${NotificationFragment}

  mutation notificationQuestionAnswer($id: ID!, $value: String!) {
    notificationQuestionAnswer(id: $id, value: $value) {
      ...NotificationFragment
    }
  }
`
import React, {useState} from "react"

import {Menu} from "components/backoffice/BILLING/Menu/Menu"


import {InvoiceWidget} from "components/backoffice/BILLING/Invoice/Widget/InvoiceWidget"
import {QuoteList} from "components/backoffice/BILLING/Quote"
import {OrderList} from "components/backoffice/BILLING/Order/List/OrderList"
import {ItemList} from "components/backoffice/BILLING/Item/List/ItemList"


// import Card from "react-bootstrap/Card"
// import Form from "react-bootstrap/Form"
// import Row from "react-bootstrap/Row"
// import Col from "react-bootstrap/Col"
// import InputGroup from "react-bootstrap/InputGroup"

import searchQuery from "graphql/BILLING/Search/query/search"
import {useQuery} from "@apollo/client"

import './style.scss'

type SearchEngineProps = {

}


export const SearchEngine = (props: SearchEngineProps) => {

  // const [search, updateSearch] = useState("")
  const [searchInput, updateSearchInput] = useState("")
  const [searchResults, updateSearchResults] = useState<any>(null)

  const _handleChange = (event: any) => {
    const {value} = event.target
    updateSearchInput(value)
  }

  const _filterResults = () => {
    // const {searchResults} = this.props
    return searchResults && searchResults?.reduce((acc: any, current: any, index: number) => {
      const {invoiceList, quoteList, contactList, customerList, orderList, itemList} = acc
      return {
        invoiceList: (current.__typename === "Invoice") ? [...invoiceList, current] : invoiceList,
        quoteList: (current.__typename === "Quote") ? [...quoteList, current] : quoteList,
        contactList: (current.__typename === "Contact") ? [...contactList, current] : contactList,
        customerList: (current.__typename === "Customer") ? [...customerList, current] : customerList,
        orderList: (current.__typename === "Order") ? [...orderList, current] : orderList,
        itemList: (current.__typename === "Item") ? [...itemList, current] : itemList,
        // resultList: [...current, resultList],
      }
    }, {
      // resultList: [],
      invoiceList: [],
      quoteList: [],
      contactList: [],
      customerList: [],
      orderList: [],
      itemList: [],
    })
  }

  const {refetch} = useQuery(searchQuery, {
    variables: {
      text: searchInput,
    },
    skip: true,
  })


  const _handleSubmit = async (event: any) => {
    event.preventDefault()
    const response = await refetch({
      text: searchInput,
    })
    updateSearchResults(response?.data?.search)
  }


  const {invoiceList, contactList, quoteList, orderList, itemList} = _filterResults() || {}
  const hasInvoice = invoiceList && invoiceList.length > 0
  const forceDisplay = false
  const hasQuote = forceDisplay || (quoteList && quoteList.length > 0)
  const hasOrder = forceDisplay || (orderList && orderList.length > 0)
  const hasContact = forceDisplay || (contactList && contactList.length > 0)
  const hasItem = forceDisplay || (itemList && itemList.length > 0)




  return (
    <div className="searchEngineContainer">
      {/* <Card>
        <Card.Header>Recherche</Card.Header>
        <Card.Body>
          <form onSubmit={_handleSubmit}>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Recherche</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control value={searchInput} type="text" placeholder="Rechercher" onChange={_handleChange} />
            </InputGroup>
          </form>
          <div className="searchResults">
            <Row>
              {hasInvoice && <Col xs={12} sm={6} md={6}>
                <div className="card">
                  <div className="card-header bg-dark text-white">
                    Factures
                  </div>
                  <div className="card-body">
                    <InvoiceWidget
                      invoiceList={invoiceList}
                    />
                  </div>
                </div>
              </Col>}
              {hasContact && <Col xs={12} sm={6} md={6}>
                <Card border="info">
                  <div className='panel-heading'>Contacts</div>
                  <div className='panel-body'>
                    {contactList && contactList.map((contact: any) => {
                      const {id, firstname, lastname, Customer} = contact
                      const {initials} = Customer
                      return (
                        <div key={id}>{firstname} {lastname} {initials}</div>
                      )
                    })}
                  </div>
                </Card>
              </Col>}
              {hasQuote && <Col xs={12} sm={6} md={6}>
                <Card border="info">
                  <Card.Body>
                    <Card.Title>Devis</Card.Title>
                  </Card.Body>
                  <Card.Text>
                    <QuoteList
                      quoteList={quoteList}
                    />
                  </Card.Text>`
                </Card>
              </Col>}
              {hasOrder && <Col xs={12} sm={6} md={6}>
                <div className="card">
                  <div className="card-header bg-dark text-white">
                    Bons de commande
                  </div>
                  <div className="card-body">
                    <OrderList />
                  </div>
                </div>
              </Col>}
              {hasItem && <Col xs={12} sm={6} md={6}>
                <div className="card">
                  <div className="card-header bg-dark text-white">
                    Item
                  </div>
                  <div className="card-body">
                    <ItemList
                      itemList={itemList}
                    />
                  </div>
                </div>
              </Col>}
            </Row>
          </div>



          <div className="engine-menu">
            <Menu />
          </div>
        </Card.Body>
      </Card> */}
    </div>
  )
}
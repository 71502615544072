import React from "react"

export const CardItem = (props) => {

  const {card} = props
  const {last4, expMonth, expYear} = card
  const cardNumber = (last4) ? `XXXX-XXXX-XXXX-${last4}` : '';
  return (
    <div className="card-item">
      <div className="card-number col-12">
        <div className="input-group">
          <span>{cardNumber}</span>
        </div>
      </div>

      <div className="card-expiration col-12">
        <div className="">
          <div className="input-group">
            <span>Expiration: {expMonth}/{expYear}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

import { gql } from "@apollo/client";
import OrderLineListFragmentGql from "graphql/BILLING/Order/Line/fragment/OrderLineListFragment.gql";

export default gql`

  ${OrderLineListFragmentGql}

  fragment ItemFragment on Item {
    id
    label
    price
    vat
    priceBuy
    OrderLine {
      ...OrderLineListFragment
    }
  }
`
import { gql } from "@apollo/client";
import TransactionFragment from "../fragment/TransactionFragment";

export default gql`

  ${TransactionFragment}

  query transactionGet($id: String!) {
    transaction: transactionGet(id: $id) {
      ...TransactionFragment
    }
  }


`
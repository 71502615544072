import gql from "graphql-tag";

export default gql`
  fragment ClusterListFragment on Cluster {
    id
    name
    updateDate
    HostingConfig {
      id
      name
    }
  }
`
import { gql } from "@apollo/client";
import TransactionTagFragment from "../fragment/TransactionTagFragment";

export default gql`

  ${TransactionTagFragment}

  mutation transactionTagAdd($tagId: String!, $transactionId: String!) {
    transactionTag: transactionTagAdd(tagId: $tagId, transactionId: $transactionId) {
      ...TransactionTagFragment
    }
  }

`
import {gql} from "@apollo/client";

export default gql`
fragment CustomerSearchFragment on Customer {
  id
  initials
  name
  Contract {
    countQuote
    countInvoice
  }
}
`
import { gql } from "@apollo/client";
import HostingConfigFragmentGql from "../fragment/HostingConfigFragment.gql";

export default gql`

  ${HostingConfigFragmentGql}

  query hostingConfigList {
    list: hostingConfigList {
      ...HostingConfigFragment
    }
  }

`
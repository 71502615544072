export const http_protocol  =  "https";
export const root_hostname  =  "www.box4b.fr";
export const port = 443;
export const graphql_endpoint  =  `${http_protocol}://${root_hostname}:${port}/graphql`;
export const api_enpoint  =  `${http_protocol}://${root_hostname}:${port}`;

export const URL_DEPLOYER = `https://deployer.cl2.box4b.net:3003/`

export const URL_FRONT_CANONICAL = `${http_protocol}://${root_hostname}`;

export const stripe_publishable = `pk_live_n8JyOfCtzLBqJSNakm4y2oTE`

export const URL_VHOST_PHONE = "v2.phone.box4b.net"
export const PORT_VHOST_PHONE = 444
export const PORT_VHOST_PHONE_LEGACY = 445
export const URL_ROOT_PHONE = `https://${URL_VHOST_PHONE}:${PORT_VHOST_PHONE}`
export const URL_ROOT_PHONE_LEGACY = `https://${URL_VHOST_PHONE}:${PORT_VHOST_PHONE_LEGACY}`
import { CustomerView } from "components/backoffice/BILLING/Customer/View/CustomerView"
import { useParams } from "react-router-dom"


export const CustomerViewRoute = (props: any) => {

  const { id } = useParams<{ id: string }>()

  return <div>
    <CustomerView customerId={id || ""} />
  </div>
}
import React, { useState } from "react"

import orderGetQuery from "graphql/BILLING/Order/query/orderGet"
import orderUpdateMutation from "graphql/BILLING/Order/mutation/orderUpdate"
import notificationListQuery from "graphql/Notification/query/notificationList"
import { useMutation, useQuery } from "@apollo/client"
import { OrderForm } from "../Form/OrderForm"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import { OrderLineInput } from "model"
import orderDelete from "graphql/BILLING/Order/mutation/orderDelete"
import orderListGql from "graphql/BILLING/Order/query/orderList.gql"

type OrderEditProps = {
  id?: String,
  onDone?: () => void
}

export const OrderEdit = (props: OrderEditProps) => {

  const [order, updateOrder] = useState<any>()
  const [formIsUpdated, updateFormIsUpdated] = useState<boolean>(false)
  const [errors, updateErrors] = useState<any>({})


  const [orderUpdateMutate] = useMutation(orderUpdateMutation, {
    refetchQueries: [
      {
        query: notificationListQuery,
      },
    ]
  })

  const [orderDeleteMutate] = useMutation(orderDelete)


  const { data } = useQuery(orderGetQuery, {
    variables: {
      id: props?.id
    },
    skip: !props?.id,
    onCompleted: (response) => {
      updateOrder(response?.order)
    }
  })

  const onDelete = () => {
    orderDeleteMutate({
      variables: {
        id: props?.id,
      }
    })
      .then(() => {
        props?.onDone && props?.onDone()
      })
  }

  const onReset = () => {
    updateFormIsUpdated(false)
    updateOrder(data?.order)
  }

  const _handleSubmit = (event: any) => {
    event.preventDefault()
    const { __typename, id, publicId, interval, paymentPref, Contract, ...input } = order
    orderUpdateMutate({
      variables: {
        id: props?.id,
        input: {
          ...input,
          dateStart: order?.dateStart,
          dateEnd: order?.dateEnd,
          billingDayOfMonth: order?.billingDayOfMonth,
          OrderLine: order?.OrderLine?.map((orderLine: OrderLineInput) => {
            const { quantity, dateStart, dateEnd, description, itemId } = orderLine
            return {
              quantity: quantity && Number(quantity),
              dateStart: (dateStart !== "") ? dateStart : null,
              dateEnd: (dateEnd !== "") ? dateEnd : null,
              itemId,
              description,
            }
          })
        }
      }
    })
      .then(() => {
        props?.onDone && props?.onDone()
      })
      .catch((e: any) => {
        updateErrors(formatErrorResponseForJoi(e))
        console.log("error", e)
        console.log("errors", errors)
      })
  }

  // const {dateStart, dateEnd, billingDayOfMonth, OrderLine} = order || {}
  return (
    <div className="order-edit-container">
      <form onSubmit={_handleSubmit}>
        <div className="card">
          <div className="card-body">
            <OrderForm order={order} errors={errors} onChange={(order: any) => {
              updateFormIsUpdated(true)
              updateOrder(order)
            }} />
          </div>
          <div className="card-footer" style={{ position: "sticky", bottom: 4 }}>
            {formIsUpdated && <button className="btn btn-sm btn-info" type="submit">Enregistrer</button>}
            {formIsUpdated && <button className="btn btn-sm btn-warning" style={{ marginLeft: 5 }} type="button" onClick={onReset}>Annuler</button>}
            <button className="btn btn-sm btn-danger" style={{ marginLeft: 5 }} type="button" onClick={onDelete}>Supprimer</button>
          </div>
        </div>
      </form>
    </div>
  )

}
import { gql } from "@apollo/client";
import PictureFragment from "../fragment/PictureFragment";

export default gql`

  ${PictureFragment}

  query pictureListByGuestId($guestId: String!) {
    list: pictureListByGuestId(guestId: $guestId) {
      ...PictureFragment
    }
  }

`
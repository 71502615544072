import { guestContext } from "context/guestContext"
import { useContext } from "react"

type FooterProps = {
}

export const Footer = (props: FooterProps) => {

  const guestCtx = useContext(guestContext)

  return <div className="mt-2 mb-2">
    <span className="" style={{ fontSize: 10 }}>Vous êtes connecté en tant que <b>{guestCtx?.guest?.fullName}</b>.</span>
    <p className="" style={{ fontSize: 10 }}>
      <a href="#" onClick={() => guestCtx?.onLogout()}>Cliquez ici pour vous déconnecter</a>
    </p>
  </div>

}
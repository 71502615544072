import { useMutation, useQuery } from "@apollo/client"
import { HostingConfig, HostingConfigInput } from "model"
import { useState } from "react"
import { HostingConfigForm } from "../Form/HostingConfigForm"
import hostingConfigGetGql from "graphql/HOSTING/Config/query/hostingConfigGet.gql"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import hostingConfigUpdateGql from "graphql/HOSTING/Config/mutation/hostingConfigUpdate.gql"


type HostingConfigEditProps = {
  id: string,
  onDone?: () => void,
}

export const HostingConfigEdit = ({ id, onDone }: HostingConfigEditProps) => {

  const [hostingConfig, updateHostingConfig] = useState<HostingConfigInput>()
  const [errors, updateErrors] = useState()

  const [mutate] = useMutation(hostingConfigUpdateGql)

  const { data } = useQuery<{ hostingConfig: HostingConfig }>(hostingConfigGetGql, {
    variables: {
      id,
    },
    onCompleted: (data) => {
      const { id, Server, ...rest } = data?.hostingConfig
      updateHostingConfig({
        ...rest,
      })
    }
  })

  const onSave = () => {
    mutate({
      variables: {
        id,
        input: hostingConfig,
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }



  return <>

    <HostingConfigForm hostingConfigInput={hostingConfig} onChange={updateHostingConfig} errors={errors} />
    <div>
      <button className="btn btn-sm btn-dark" onClick={() => onSave()}>
        Enregistrer
      </button>
    </div>
  </>

}
import { PictureCategoryEnum } from "model";

export const translateCategory = (category?: PictureCategoryEnum | string) => {
  switch(category) {
    case PictureCategoryEnum.ANIMATED: {
      return "Une animation"
    }
    case PictureCategoryEnum.BRIDESMAIDS: {
      return "Les demoiselles d'honneur"
    }
    case PictureCategoryEnum.BRIDE_BOUQUET: {
      return "Le bouquet de la mariée"
    }
    case PictureCategoryEnum.BRIDE_PARENTS: {
      return "La mariée avec ses parents"
    }
    case PictureCategoryEnum.CHEESE: {
      return "Le fromage"
    }
    case PictureCategoryEnum.CRAVATE: {
      return "Une cravate"
    }
    case PictureCategoryEnum.EMOUVANT: {
      return "Quelque chose qui vous émeut"
    }
    case PictureCategoryEnum.FUNNY_SELFY: {
      return "Un selfie rigolo"
    }
    case PictureCategoryEnum.GOLDEN_BOOK: {
      return "Le coin du livre d'or"
    }
    case PictureCategoryEnum.GROOM_WITH_PARENTS: {
      return "Le marié avec ses parents"
    }
    case PictureCategoryEnum.HEXAGONAL: {
      return "Quelque chose d'hexagonal"
    }
    case PictureCategoryEnum.INSOLITE: {
      return "Une photo insolite"
    }
    case PictureCategoryEnum.JUMP: {
      return "Un saut"
    }
    case PictureCategoryEnum.LANDSCAPE: {
      return "Une photo d'ensemble"
    }
    case PictureCategoryEnum.LAUGHTER: {
      return "Un éclat de rire"
    }
    case PictureCategoryEnum.LOVER_PHOTO: {
      return "Une photo d'amoureux (pas les mariés)"
    }
    case PictureCategoryEnum.MEAL_EXPRESSION: {
      return "Une expression face au repas"
    }
    case PictureCategoryEnum.NOEUD_PAPILLON: {
      return "Un homme avec un noeud papillon"
    }
    case PictureCategoryEnum.RIGHT_NEIGHBOR: {
      return "Votre voisin(e) de droite"
    }
    case PictureCategoryEnum.SHINY: {
      return "Quelque chose qui brille"
    }
    case PictureCategoryEnum.SHORT_DRESS: {
      return "Une femme en robe courte"
    }
    case PictureCategoryEnum.SOMEBODY_DANCING: {
      return "Quelqu'un qui danse"
    }
    case PictureCategoryEnum.SOMETHING_BLUE: {
      return "Quelque chose de bleu"
    }
    case PictureCategoryEnum.SOMETHING_EATABLE: {
      return "Quelque chose qui se mange"
    }
    case PictureCategoryEnum.SOMETHING_FLYING: {
      return "Quelque chose qui vole"
    }
    case PictureCategoryEnum.SURPRENEZ_VOUS: {
      return "Surprenez vous !"
    }
    case PictureCategoryEnum.WEDS: {
      return "Une photo des mariés"
    }
    case PictureCategoryEnum.WEDS_CUTE: {
      return "Une photo mignonne des mariés"
    }
    case PictureCategoryEnum.WEDS_SELFY: {
      return "Un selfie avec les mariés"
    }
    case PictureCategoryEnum.YOUR_TABLE: {
      return "Une photo de votre table"
    }

  }
} 
import { gql } from "@apollo/client";
import VideoConfigFragmentGql from "../fragment/VideoConfigFragment.gql";

export default gql`

  ${VideoConfigFragmentGql}

  mutation videoConfigAdd($input: VideoConfigInput!) {
    videoConfig: videoConfigAdd(input: $input) {
      ...VideoConfigFragment
    } 
  }

`
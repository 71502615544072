import React from "react"

type EnvVarFormProps = {
  onChange: (el: any) => void,
  envVar: any,
}

export const EnvVarForm = (props: EnvVarFormProps) => {

  const envVar = props?.envVar

  const onChange = (value: any) => {
    props?.onChange(value)
  }

  return <div className="row">
    <div className="col-6">
      <div className="input-group">
        <span className="input-group-text">Nom</span>
        <input type="text" placeholder="Name" className="form-control" value={envVar?.name} onChange={(e) => {
          onChange({
            ...envVar,
            name: e.target.value,
          })
        }} />
      </div>
    </div>
    <div className="col-6">
      <div className="input-group">
        <span className="input-group-text">Value</span>
        <input type="text" className="form-control" placeholder="Value" value={envVar?.value} onChange={(e) => {
          onChange({
            ...envVar,
            value: e.target.value,
          })
        }} />
      </div>
    </div>
  </div>
}
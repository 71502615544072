import {gql} from '@apollo/client'
import InvoiceFragment from '../fragment/InvoiceFragment'

export default gql`

  ${InvoiceFragment}

  query invoiceGet($id: String!) {
    invoice: invoiceGet(id: $id) {
      ...InvoiceFragment
    }
  }
`
import { gql } from "@apollo/client";

export default gql`

  fragment HostingConfigFragment on HostingConfig {
    id
    name
    Server {
      id
      name
    }
  }

`
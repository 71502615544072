import {gql} from "@apollo/client";

export default gql`

fragment CompanyStripeFragment on Company {
  id
  name
  stripePk
}

`
import {URL_FRONT} from "static/constant/url";

export const URL_ROOT = URL_FRONT
export const URL_WEIT = `${URL_ROOT}/weit/`
export const URL_CGU = `${URL_ROOT}cgu/`
export const URL_HOSTING = `${URL_ROOT}hebergement/`
export const URL_PHONE = `${URL_ROOT}telephonie/`
export const URL_NETWORK = `${URL_ROOT}reseaux/`
export const URL_DEV = `${URL_ROOT}developpement/`
export const URL_WEDDING = `${URL_ROOT}wedding/`
export const URL_WEDDING_PAGE = `${URL_ROOT}wedding/:page`
export const URL_WEDDING_GALERY = `${URL_ROOT}wedding/galery`
export const URL_WEDDING_IMPORT = `${URL_ROOT}wedding/import`

import React from "react"
import { ClusterList } from "components/backoffice/HOSTING/Cluster/Cluster/List/ClusterList"
import BootstrapBreadcrumb from "react-bootstrap/Breadcrumb"
import { URL_HOSTING_ROOT } from "static/constant/backoffice/url"
import { Link } from "react-router-dom"

export const ClusterListRoute = () => {

  return <div>
    <BootstrapBreadcrumb>
      <BootstrapBreadcrumb.Item active>Hébergement</BootstrapBreadcrumb.Item>
      <BootstrapBreadcrumb.Item active>Liste des Applications</BootstrapBreadcrumb.Item>
    </BootstrapBreadcrumb>

    <h1>Liste des Applications</h1>
    <ClusterList />
  </div>
}
import React from "react"
type DnsFormProps = {
  onChange: (datas: any) => void,
  options: any,
}


export const DnsForm = (props: DnsFormProps) => {

  const {options, onChange} = props
  const {targetList} = options || {}

  return <div className="dns-form-container">
    <div className="alert alert-secondary">
      Saisissez les FQDN qui seront résolus avec l'ip locale du serveur
    </div>
    {targetList?.map((target: any) => {
      const {name, id} = target
      return <div key={`target_${id}`} className="input-group">
        <span className="input-group-text">FQDN</span>
        <input type="text" className="form-control" value={name} onChange={(e: any) => {
          onChange({
            ...options,
            targetList: targetList.map((el: any) => (target?.id === el.id) ? {
              ...el,
              name: e.target.value
            } : el),
          })
        }} />
        <span className="input-group-text"><span className="icon-trash" onClick={() => {
          onChange({
            ...options,
            targetList: targetList.filter((el: any) => (id != el.id)),
          })
        }}></span></span>
      </div>
    })}
    <div>
      <button onClick={() => {
        onChange({
          ...options,
          targetList: [...(targetList && targetList || []), {
            id: Date.now(),
            name: ""
          }]
        })
      }}>Ajouter</button>
    </div>
  </div>
}
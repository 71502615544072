import React, { Component } from 'react'

import {TemplateEdit} from 'components/backoffice/HOSTING/Provisioning/Template/TemplateEdit'
import {Breadcrumb} from 'components/common/Navigation/Breadcrumb/Breadcrumb'

export class HostingTemplateEditRoute extends Component {
  render() {
    const {match} = this.props
    const {params} = match
    const {templateId} = params
    return (
      <div>
        {/* <Breadcrumb match={match} /> */}
        <TemplateEdit
          templateId={templateId}
        />
      </div>
    )
  }
}

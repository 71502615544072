import {gql} from "@apollo/client"
import ContractFragment from "../fragment/ContractFragment"
// import ContractFragment from "../fragment/ContractFragment"

export default gql`

  ${ContractFragment}

  query contractList {
    contractList {
      ...ContractFragment
    }
  }
`
import {gql} from '@apollo/client'
import ItemFragment from '../fragment/ItemFragment'

export default gql`

  ${ItemFragment}

  query itemGet($id: ID!) {
    item: itemGet(id: $id) {
      ...ItemFragment
    }
  }
`
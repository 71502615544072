import { gql } from "@apollo/client";
import HostingConfigFragmentGql from "../fragment/HostingConfigFragment.gql";

export default gql`

  ${HostingConfigFragmentGql}

  mutation hostingConfigUpdate($id: String!, $input: HostingConfigInput!) {
    hostingConfig: hostingConfigUpdate(id: $id, input: $input) {
      ...HostingConfigFragment
    }
  }

`
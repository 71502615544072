import {gql} from '@apollo/client'
import UserLoginTokenFragment from 'graphql/APP/User/fragment/userLoginTokenFragment'

export default gql`

  ${UserLoginTokenFragment}

  mutation userLogin($email: String!, $password: String!) {
    userLogin(input: {
      email: $email
      password: $password
    }) {
      ...UserLoginTokenFragment
    }
  }
`